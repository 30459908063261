import { Model } from "../model";
import { Cache } from "../cache";

export class LukSymetryczny extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const sectors_count = 100;
        const vertices = [
            // sheets 0 - 7
            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', 'dim_E', '0'],
            ['0', 'dim_E', '0'],

            ['0', '3', 'dim_B'],
            ['dim_A', '3', 'dim_B'],
            ['dim_A', 'dim_E', 'dim_B'],
            ['0', 'dim_E', 'dim_B']
        ];

        const planes = [
            {materials: [0, 1], vertices: [0, 1, 2, 3]},
            {materials: [0, 1], vertices: [7, 6, 5, 4]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [0, 3, 7, 4]},
        ];

        const functions = [
            {name: '$A', params: ['x', 'y', 'z'], expression: 'x * Math.cos((y + 1) * Math.PI / 180 * dim_C / ' + sectors_count + ') + z'},
            {name: '$B', params: ['x', 'y', 'z'], expression: 'x * Math.sin((y + 1) * Math.PI / 180 * dim_C / ' + sectors_count + ') + z'},
            {name: '$C', params: ['x', 'y', 'z'], expression: 'x * Math.cos(Math.PI / 180 * dim_C) + y - z * Math.sin(Math.PI / 180 * dim_C)'},
            {name: '$D', params: ['x', 'y', 'z'], expression: 'x * Math.sin(Math.PI / 180 * dim_C) + y + z * Math.cos(Math.PI / 180 * dim_C)'}
        ];


        //small arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(dim_F, ' + i + ', -dim_F)', '$B(dim_F, ' + i + ', dim_E)', '0']);
            vertices.push(['$A(dim_F, ' + i + ', -dim_F)', '$B(dim_F, ' + i + ', dim_E)', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [8 + 2 * i, 9 + 2 * i, 8 + 2 * i - 1, 7 + 2 * i - 1]});
            }
        }
        planes.push({materials: [0, 1], vertices: [8, 9, 7, 3]});
        //big arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(dim_F + dim_A, ' + i + ', -dim_F)', '$B(dim_F + dim_A, ' + i + ', dim_E)', '0']);
            vertices.push(['$A(dim_F + dim_A, ' + i + ', -dim_F)', '$B(dim_F + dim_A, ' + i + ', dim_E)', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [2 * sectors_count + 7 + 2 * i - 1, 2 * sectors_count + 8 + 2 * i - 1, 2 * sectors_count + 9 + 2 * i, 2 * sectors_count + 8 + 2 * i]});
            }
        }
        planes.push({materials: [0, 1], vertices: [2, 6, 9 + 2 * sectors_count, 8 + 2 * sectors_count]});

        //side A:
        const side_A = [3, 2];
        for(let i = 0; i < sectors_count; i++) {
            side_A.push(8 + 2 * sectors_count + 2 * i);
        }
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_A.push(8 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_A});

        //side B:
        const side_B = [];
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_B.push(9 + 2 * sectors_count + 2 * i);
        }
        side_B.push(6);
        side_B.push(7);
        for(let i = 0; i < sectors_count; i++) {
            side_B.push(9 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_B});

        // finishing part 1
        vertices.push(['0', '0', '0']);
        vertices.push(['dim_A', '0', '0']);
        vertices.push(['0', '0', 'dim_B']);
        vertices.push(['dim_A', '0', 'dim_B']);
        vertices.push(['-3', '0', '-3']);
        vertices.push(['dim_A + 3', '0', '-3']);
        vertices.push(['dim_A + 3', '0', 'dim_B + 3']);
        vertices.push(['-3', '0', 'dim_B + 3']);

        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 9 + 4 * sectors_count, 1, 0]});
        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 11 + 4 * sectors_count, 5, 4]});
        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 10 + 4 * sectors_count, 4, 0]});
        planes.push({materials: [2], vertices: [9 + 4 * sectors_count, 11 + 4 * sectors_count, 5, 1]});

        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 12 + 4 * sectors_count, 13 + 4 * sectors_count, 9 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [9 + 4 * sectors_count, 13 + 4 * sectors_count, 14 + 4 * sectors_count, 11 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [11 + 4 * sectors_count, 14 + 4 * sectors_count, 15 + 4 * sectors_count, 10 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 15 + 4 * sectors_count, 12 + 4 * sectors_count, 8 + 4 * sectors_count]});

        // second end
        vertices.push(['$C(dim_F, -dim_F, dim_D - 3)', '$D(dim_F, dim_E, dim_D - 3)', '0']);
        vertices.push(['$C(dim_F, -dim_F, dim_D - 3)', '$D(dim_F, dim_E, dim_D - 3)', 'dim_B']);
        vertices.push(['$C(dim_F + dim_A, -dim_F, dim_D - 3)', '$D(dim_F + dim_A, dim_E, dim_D - 3)', '0']);
        vertices.push(['$C(dim_F + dim_A, -dim_F, dim_D - 3)', '$D(dim_F + dim_A, dim_E, dim_D - 3)', 'dim_B']);
        planes.push({materials: [0, 1], vertices: [16 + 4 * sectors_count, 17 + 4 * sectors_count, 7 + 2 * sectors_count, 6 + 2 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [6 + 4 * sectors_count, 7 + 4 * sectors_count, 19 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [6 + 2 * sectors_count, 6 + 4 * sectors_count, 18 + 4 * sectors_count, 16 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [7 + 2 * sectors_count, 17 + 4 * sectors_count, 19 + 4 * sectors_count, 7 + 4 * sectors_count]});

        // finishing part 2
        vertices.push(['$C(dim_F, -dim_F, dim_D)', '$D(dim_F, dim_E, dim_D)', '0']);
        vertices.push(['$C(dim_F, -dim_F, dim_D)', '$D(dim_F, dim_E, dim_D)', 'dim_B']);
        vertices.push(['$C(dim_F + dim_A, -dim_F, dim_D)', '$D(dim_F + dim_A, dim_E, dim_D)', '0']);
        vertices.push(['$C(dim_F + dim_A, -dim_F, dim_D)', '$D(dim_F + dim_A, dim_E, dim_D)', 'dim_B']);
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 21 + 4 * sectors_count, 17 + 4 * sectors_count, 16 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 23 + 4 * sectors_count, 19 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 16 + 4 * sectors_count, 18 + 4 * sectors_count, 22 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [21 + 4 * sectors_count, 17 + 4 * sectors_count, 19 + 4 * sectors_count, 23 + 4 * sectors_count]});

        vertices.push(['$C(dim_F - 3, -dim_F, dim_D)', '$D(dim_F - 3, dim_E, dim_D)', '-3']);
        vertices.push(['$C(dim_F - 3, -dim_F, dim_D)', '$D(dim_F - 3, dim_E, dim_D)', 'dim_B + 3']);
        vertices.push(['$C(dim_F + dim_A + 3, -dim_F, dim_D)', '$D(dim_F + dim_A + 3, dim_E, dim_D)', '-3']);
        vertices.push(['$C(dim_F + dim_A + 3, -dim_F, dim_D)', '$D(dim_F + dim_A + 3, dim_E, dim_D)', 'dim_B + 3']);
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 24 + 4 * sectors_count, 26 + 4 * sectors_count, 22 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 26 + 4 * sectors_count, 27 + 4 * sectors_count, 23 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [23 + 4 * sectors_count, 27 + 4 * sectors_count, 25 + 4 * sectors_count, 21 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [21 + 4 * sectors_count, 25 + 4 * sectors_count, 24 + 4 * sectors_count, 20 + 4 * sectors_count]});

        // arrows
        // A 28+4*sectors_count-29+4*sectors_count + 0-1
        vertices.push(['0', '3', 'dim_B + 10']);
        vertices.push(['dim_A', '3', 'dim_B + 10']);

        // B 30+4*sectors_count-31+4*sectors_count + 1-5
        vertices.push(['dim_A + 10', '3', '0']);
        vertices.push(['dim_A + 10', '3', 'dim_B']);

        // D 32+4*sectors_count-34+4*sectors_count + 7+4*sectors_count
        vertices.push(['$C(dim_F + dim_A + 3, -dim_F, dim_D)', '$D(dim_F + dim_A + 3, dim_E, dim_D)', 'dim_B']);
        vertices.push(['$C(dim_F + dim_A + 10, -dim_F, dim_D)', '$D(dim_F + dim_A + 10, dim_E, dim_D)', 'dim_B']);
        vertices.push(['$C(dim_F + dim_A + 10, -dim_F, 0)', '$D(dim_F + dim_A + 10, dim_E, 0)', 'dim_B']);

        // E 35+4*sectors_count-37+4*sectors_count + 6
        vertices.push(['dim_A + 10', 'dim_E', 'dim_B']);
        vertices.push(['dim_A + 10', '0', 'dim_B']);
        vertices.push(['dim_A + 3', '0', 'dim_B']);

        // F 38+4*sectors_count-40+4*sectors_count + 9+sectors_count
        vertices.push(['-dim_F', 'dim_E', 'dim_B']);
        vertices.push(['-dim_F', 'dim_E', 'dim_B + 10']);
        vertices.push(['dim_F * (Math.cos(Math.PI / 180 * dim_C / 2) - 1)', 'dim_F * Math.sin(Math.PI / 180 * dim_C / 2) + dim_E', 'dim_B + 10']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'],
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10
        };

        const arrows = {
            'A': [4, 28 + 4 * sectors_count, 29 + 4 * sectors_count, 5],
            'B': [1, 30 + 4 * sectors_count, 31 + 4 * sectors_count, 5],
            'D': [32 + 4 * sectors_count, 33 + 4 * sectors_count, 34 + 4 * sectors_count, 7 + 4 * sectors_count],
            'E': [37 + 4 * sectors_count, 36 + 4 * sectors_count, 35 + 4 * sectors_count, 6],
            'F': [38 + 4 * sectors_count, 39 + 4 * sectors_count, 40 + 4 * sectors_count, 9 + sectors_count],
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        // this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Łuk symetryczny';
        // this.cache.print();
    }

    setDimensionValue(name, value) {
        if(name == 'C') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'C') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }
}