import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainButtonComponent } from './components/main-button/main-button.component';
import { BoxButtonComponent } from './components/box-button/box-button.component';
import { BaseButtonComponent } from './components/base-button/base-button.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';



@NgModule({
    declarations: [
        ActionButtonComponent,
        MainButtonComponent,
        BoxButtonComponent,
        BaseButtonComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ActionButtonComponent,
        MainButtonComponent,
        BoxButtonComponent,
        BaseButtonComponent
    ]
})
export class ButtonsModule { }
