import { Model } from "../model";
import { Cache } from "../cache";

export class SkrzynkaRozprezna extends Model {
    //czy zawsze otwory centralnie?
    //czy dobrze że przesuwam centru otworu gdy wchodzi na wywinięcie?
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_C', '0'],
            ['dim_A', 'dim_C', '0'],
            ['dim_A', 'dim_C', 'dim_B'],
            ['0', 'dim_C', 'dim_B'],

            // arrows
            // A 16 - 17
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],

            // B 18 - 19
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],

            // C 20 - 22
            ['dim_A + 10', 'dim_C', 'dim_B'],
            ['dim_A + 10', '0', 'dim_B'],
            ['dim_A + 3', '0', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [12, 13, 14, 15]}
        ];

        const sectors_count = 100;
        const cylinder_height = 7.5;

        const functions = [
            {name: '$A', params: [], expression: 'dim_C > dim_D + 6 ? dim_C / 2 : dim_D / 2 + 3'},
            {name: '$B', params: ['x', 'y', 'z'], expression: 'x * Math.cos(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + z'},
            {name: '$C', params: ['x', 'y', 'z'], expression: 'x * Math.sin(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + z'},
            {name: '$D', params: ['x', 'y', 'z'], expression: 'dim_E == 0 ? x : (dim_E1 == 0 ? y : z)'},
        ];

        // cylinder:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$D($B(-dim_D, ' + i + ', dim_A / 2), dim_A + ' + cylinder_height + ', $B(dim_D, ' + i + ', dim_A / 2))',
                            '$D(dim_C + ' + cylinder_height + ', $B(dim_D, ' + i + ', $A()), $C(dim_D, ' + i + ', $A()))',
                            '$D($C(dim_D, ' + i + ', dim_B / 2), $C(dim_D, ' + i + ', dim_B / 2), dim_B + ' + cylinder_height + ')']);
            vertices.push(['$D($B(-dim_D, ' + i + ', dim_A / 2), dim_A, $B(dim_D, ' + i + ', dim_A / 2))',
                            '$D(dim_C, $B(dim_D, ' + i + ', $A()), $C(dim_D, ' + i + ', $A()))',
                            '$D($C(dim_D, ' + i + ', dim_B / 2), $C(dim_D, ' + i + ', dim_B / 2), dim_B)']);
            planes.push({materials: [0, 1], vertices: [23 + 2 * ((i + 1) % sectors_count), 24 + 2 * ((i + 1) % sectors_count), 24 + 2 * i, 23 + 2 * i]});
        }

        // arrow D
        vertices.push(['$D(-dim_D / 2 + dim_A / 2, dim_A + ' + cylinder_height + ' + 10, dim_D / 2 + dim_A / 2)',
                        '$D(dim_C + ' + cylinder_height + ' + 10, dim_D / 2 + $A(), $A())',
                        '$D(dim_B / 2, dim_B / 2, dim_B + ' + cylinder_height + ' + 10)']);
        vertices.push(['$D(dim_D / 2 + dim_A / 2, dim_A + ' + cylinder_height + ' + 10, -dim_D / 2 + dim_A / 2)',
                        '$D(dim_C + ' + cylinder_height + ' + 10, -dim_D / 2 + $A(), $A())',
                        '$D(dim_B / 2, dim_B / 2, dim_B + ' + cylinder_height + ' + 10)']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': default_vals['E'],
            'E1': default_vals['E1']
        };

        const arrows = {
            'A': [11, 16, 17, 10],
            'B': [9, 18, 19, 10],
            'C': [14, 20, 21, 22],
            'D': [23, 23 + 2 * sectors_count, 24 + 2 * sectors_count, 23 + sectors_count]
        };

        const circle = [];
        for(let i = 0; i < sectors_count; i++) {
            circle.push(23 + 2 * i);
        }

        const holes = [
            {plane_index: '$D(12, 9, 10)', vertices: circle}
        ];

        super(vertices, planes, dimensions, arrows, holes, options);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Skrzynka rozprężna';
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        //this.cache.print();
    }

    setDimensionValue(name, value) {
        if(name == 'E' || name == 'E1') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'E' || name == 'E1') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'E') {
            return this.options[name][this.last_correct_dimensions[name]];
        } else if(name == 'E1') {
            if(this.last_correct_dimensions['E'] == '0') {
                return '';
            } else {
                return this.options[name][this.last_correct_dimensions[name]];
            }
        }
        return this.last_correct_dimensions[name] * 10;
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let b = this.dimensions['B'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];
        let e = this.dimensions['E'];
        let e1 = this.dimensions['E1'];
        if(e == '0') {
            if(d > a) {
                return [false, 1];
            } else if(d > b) {
                return [false, 2];
            }
        } else {
            if(e1 == 0) {
                if(d > b) {
                    return [false, 2];
                } else if(d > c - 3) {
                    return [false, 3];
                }
            } else {
                if(d > a) {
                    return [false, 1];
                } else if(d > c - 3) {
                    return [false, 3];
                }
            }
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Średnica D większa od wymiaru A. Zmniejsz średnicę D, lub powiększ wymiar A.";
        } else if(error_code == 2) {
            return "Średnica D większa od wymiaru B. Zmniejsz średnicę D, lub powiększ wymiar B.";
        } else if(error_code == 3) {
            return "Średnica D większa od wymiaru C po odliczeniu zapasu na wykończenie. Zmniejsz średnicę D, lub powiększ wymiar C.";
        }
        return "";
    }
}