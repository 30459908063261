import { Model } from "../model";
import { Cache } from "../cache";

export class NakladkaProstokatna extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],
            ['0', '0', 'dim_C - 3'],
            ['dim_A', '0', 'dim_C - 3'],
            ['dim_A', 'dim_B', 'dim_C - 3'],
            ['0', 'dim_B', 'dim_C - 3'],
            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],
            ['0', '0', 'dim_C'],
            ['dim_A', '0', 'dim_C'],
            ['dim_A', 'dim_B', 'dim_C'],
            ['0', 'dim_B', 'dim_C'],
            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],
            ['-3', '-3', 'dim_C'],
            ['dim_A + 3', '-3', 'dim_C'],
            ['dim_A + 3', 'dim_B + 3', 'dim_C'],
            ['-3', 'dim_B + 3', 'dim_C'],
            //arrow A
            ['0', 'dim_B / 2', 'dim_C'],
            ['0', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C'],
            //arrow B
            ['dim_A / 2', 'dim_B', 'dim_C'],
            ['dim_A / 2', 'dim_B', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C'],
            //arrow C
            ['dim_A / 2', 'dim_B + 3', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', 'dim_C'],
            ['dim_A / 2', 'dim_B + 3', 'dim_C']
        ];

        const planes = [
            {materials: [0, 1], vertices: [4, 5, 1, 0]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [6, 7, 3, 2]},
            {materials: [0, 1], vertices: [7, 4, 0, 3]},
            {materials: [2], vertices: [0, 1, 9, 8]},
            {materials: [2], vertices: [1, 2, 10, 9]},
            {materials: [2], vertices: [2, 3, 11, 10]},
            {materials: [2], vertices: [3, 0, 8, 11]},
            {materials: [2], vertices: [12, 13, 5, 4]},
            {materials: [2], vertices: [13, 14, 6, 5]},
            {materials: [2], vertices: [14, 15, 7, 6]},
            {materials: [2], vertices: [15, 12, 4, 7]},
            {materials: [2], vertices: [8, 16, 17, 9]},
            {materials: [2], vertices: [9, 17, 18, 10]},
            {materials: [2], vertices: [10, 18, 19, 11]},
            {materials: [2], vertices: [11, 19, 16, 8]},
            {materials: [2], vertices: [12, 20, 21, 13]},
            {materials: [2], vertices: [13, 21, 22, 14]},
            {materials: [2], vertices: [14, 22, 23, 15]},
            {materials: [2], vertices: [15, 23, 20, 12]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31],
            'C': [32, 33, 34, 35]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Nakładka prostokątna';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }
}