<header class="app-header">
    <div style="max-width: 1200px;" class="container mx-auto pl-4 flex items-center justify-between">
        <a [routerLink]="['/']">
            <!--
            <svg xmlns="http://www.w3.org/2000/svg" class="icon-medium text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
            </svg>
            -->
            <img style="height: 2rem;" src="/assets/img/emv_logo.png" alt="EMV">
        </a> 
        <nav class="flex">
            <a [routerLink]="['/catalog']" class="link menu-link">Katalog kształtów</a>
            <a [routerLink]="['/inquiries/working']" class="link menu-link">Mój zestaw</a>
        </nav>
    </div>
</header>

<div class="mt-16 container mx-auto px-4 py-16" style="min-height: calc(100% - 4rem); max-width: 1200px;">
    <router-outlet></router-outlet>
</div>

<div>
    <div class="text-gray-400 text-xs fixed right-0 bottom-0 p-4">v. {{version}} | <span class="bg-gray-400 text-white p-1 text-uppercase">{{env}}</span></div>
</div>

<!-- Kontener okien modalnych -->
<template #modalContainer></template>