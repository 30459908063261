import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ParamComponent } from '../param/param.component';

@Component({
    selector: 'app-count-param',
    templateUrl: './count-param.component.html',
    styleUrls: ['./count-param.component.scss']
})
export class CountParamComponent extends ParamComponent implements OnInit {

    @Input()
    countFC: UntypedFormControl;

    @Input()
    formSubmitted: boolean;

    ngOnInit(): void { };

    public showError(): boolean {
        return (this.countFC.dirty || this.formSubmitted) && this.countFC.invalid;
    }

    public getError(): string {
        return "Wprowadź liczbę sztuk.";
    }

    onFocus() {
        this.focus.emit(null);
    }

}
