import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pricing-item',
    templateUrl: './pricing-item.component.html',
    styleUrls: ['./pricing-item.component.scss']
})
export class PricingItemComponent {

    @Input()
    label: any;
    
    @Input()
    value: any;

    @Input()
    unit: string
}
