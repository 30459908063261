import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonsModule, ModalsModule } from 'projects/base-lib/src/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InquiriesService } from './services/inquiries.service';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CustomersService } from './services/customers.service';
import { customerInitializer } from './providers/customer-initializer';
import { inquiryInitializer } from './providers/inquiry-initializer';
import { HttpErrorInterceptor } from './providers/http-error.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './providers/reuse-strategy';

import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';

registerLocaleData(localePL);
@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ButtonsModule,
        ModalsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: inquiryInitializer, deps: [ InquiriesService ], multi: true },
        { provide: APP_INITIALIZER, useFactory: customerInitializer, deps: [ CustomersService ], multi: true },
        { provide: LOCALE_ID, useValue: 'pl-PL' },
    
        // Strategia reużywania tras
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
