import { Model } from "../model";
import { Cache } from "../cache";

export class TrojnikSymetryczny extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const sectors_count = 100;
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_H', '0'],
            ['dim_A', 'dim_G', '0'],
            ['dim_A', 'dim_G', 'dim_B'],
            ['0', 'dim_H', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]}
        ];

        const functions = [
            {name: '$A', params: ['x'], expression: 'dim_F * (Math.cos((x + 1) * Math.PI / 180 * 90 / ' + sectors_count + ') - 1)'},
            {name: '$B', params: ['x'], expression: 'dim_F * Math.sin((x + 1) * Math.PI / 180 * 90 / ' + sectors_count + ') + dim_H'},
            {name: '$C', params: ['x'], expression: 'dim_A + dim_E * (1 - Math.cos((x + 1) * Math.PI / 180 * 90 / ' + sectors_count + '))'},
            {name: '$D', params: ['x'], expression: 'dim_G + dim_E * Math.sin((x + 1) * Math.PI / 180 * 90 / ' + sectors_count + ')'},

        ];

        //left arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(' + i + ')', '$B(' + i + ')', '0']);
            vertices.push(['$A(' + i + ')', '$B(' + i + ')', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [16 + 2 * i, 17 + 2 * i, 15 + 2 * i, 14 + 2 * i]});
            }
        }
        planes.push({materials: [0, 1], vertices: [16, 17, 15, 12]});
        //right arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$C(' + i + ')', '$D(' + i + ')', '0']);
            vertices.push(['$C(' + i + ')', '$D(' + i + ')', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [2 * sectors_count + 15 + 2 * i, 2 * sectors_count + 17 + 2 * i, 2 * sectors_count + 16 + 2 * i, 2 * sectors_count + 14 + 2 * i]});
            }
        }
        planes.push({materials: [0, 1], vertices: [13, 14, 2 * sectors_count + 17, 2 * sectors_count + 16]});

        // 16+4*sectors_count -
        vertices.push(['-dim_F', 'dim_H + dim_F + dim_C', '0']);
        vertices.push(['-dim_F', 'dim_H + dim_F + dim_C', 'dim_B']);

        // 18+4*sectors_count
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F', '0']);
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F', 'dim_B']);
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F + dim_C', '0']);
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F + dim_C', 'dim_B']);

        planes.push({materials: [0, 1], vertices: [18 + 4 * sectors_count, 14 + 2 * sectors_count, 16 + 4 * sectors_count, 20 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [20 + 4 * sectors_count, 16 + 4 * sectors_count, 17 + 4 * sectors_count, 21 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [21 + 4 * sectors_count, 17 + 4 * sectors_count, 15 + 2 * sectors_count, 19 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [19 + 4 * sectors_count, 15 + 2 * sectors_count, 14 + 2 * sectors_count, 18 + 4 * sectors_count]});

        // 22+4*sectors_count
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F', '0']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F', 'dim_B']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F + dim_C', '0']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F + dim_C', 'dim_B']);

        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 18 + 4 * sectors_count, 19 + 4 * sectors_count, 23 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [24 + 4 * sectors_count, 20 + 4 * sectors_count, 21 + 4 * sectors_count, 25 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [18 + 4 * sectors_count, 22 + 4 * sectors_count, 24 + 4 * sectors_count, 20 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [19 + 4 * sectors_count, 23 + 4 * sectors_count, 25 + 4 * sectors_count, 21 + 4 * sectors_count]});

        // 26+4*sectors_count
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F - 3', '-3']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F - 3', 'dim_B + 3']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F + dim_C + 3', '-3']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F + dim_C + 3', 'dim_B + 3']);

        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 23 + 4 * sectors_count, 27 + 4 * sectors_count, 26 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [24 + 4 * sectors_count, 25 + 4 * sectors_count, 29 + 4 * sectors_count, 28 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 24 + 4 * sectors_count, 28 + 4 * sectors_count, 26 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [23 + 4 * sectors_count, 25 + 4 * sectors_count, 29 + 4 * sectors_count, 27 + 4 * sectors_count]});




        // 30+4*sectors_count -
        vertices.push(['dim_A + dim_E', 'dim_G + dim_E + dim_D', '0']);
        vertices.push(['dim_A + dim_E', 'dim_G + dim_E + dim_D', 'dim_B']);

        planes.push({materials: [0, 1], vertices: [30 + 4 * sectors_count, 31 + 4 * sectors_count, 17 + 4 * sectors_count, 16 + 4 * sectors_count]});

        // 32+4*sectors_count
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E', '0']);
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E', 'dim_B']);
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E + dim_D', '0']);
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E + dim_D', 'dim_B']);

        planes.push({materials: [0, 1], vertices: [34 + 4 * sectors_count, 30 + 4 * sectors_count, 14 + 4 * sectors_count, 32 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [35 + 4 * sectors_count, 31 + 4 * sectors_count, 30 + 4 * sectors_count, 34 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [33 + 4 * sectors_count, 15 + 4 * sectors_count, 31 + 4 * sectors_count, 35 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [32 + 4 * sectors_count, 14 + 4 * sectors_count, 15 + 4 * sectors_count, 33 + 4 * sectors_count]});

        // 36+4*sectors_count
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E', '0']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E', 'dim_B']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E + dim_D', '0']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E + dim_D', 'dim_B']);

        planes.push({materials: [2], vertices: [32 + 4 * sectors_count, 36 + 4 * sectors_count, 38 + 4 * sectors_count, 34 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [34 + 4 * sectors_count, 38 + 4 * sectors_count, 39 + 4 * sectors_count, 35 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [35 + 4 * sectors_count, 39 + 4 * sectors_count, 37 + 4 * sectors_count, 33 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [33 + 4 * sectors_count, 37 + 4 * sectors_count, 36 + 4 * sectors_count, 32 + 4 * sectors_count]});

        // 40+4*sectors_count
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E - 3', '-3']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E - 3', 'dim_B + 3']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E + dim_D + 3', '-3']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E + dim_D + 3', 'dim_B + 3']);

        planes.push({materials: [2], vertices: [36 + 4 * sectors_count, 37 + 4 * sectors_count, 41 + 4 * sectors_count, 40 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [37 + 4 * sectors_count, 39 + 4 * sectors_count, 43 + 4 * sectors_count, 41 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [39 + 4 * sectors_count, 43 + 4 * sectors_count, 42 + 4 * sectors_count, 38 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [38 + 4 * sectors_count, 42 + 4 * sectors_count, 40 + 4 * sectors_count, 36 + 4 * sectors_count]});

        //side A:
        const side_A = [12, 13];
        for(let i = 0; i < sectors_count; i++) {
            side_A.push(16 + 2 * sectors_count + 2 * i);
        }
        side_A.push(30 + 4 * sectors_count);
        side_A.push(16 + 4 * sectors_count);
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_A.push(16 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_A});

        //side B:
        const side_B = [14, 15];
        for(let i = 0; i < sectors_count; i++) {
            side_B.push(17 + 2 * i);
        }
        side_B.push(17 + 4 * sectors_count);
        side_B.push(31 + 4 * sectors_count);
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_B.push(17 + 2 * sectors_count + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_B});

        // arrows
        // A 44+4*sectors_count
        vertices.push(['0', '3', 'dim_B + 10']);
        vertices.push(['dim_A', '3', 'dim_B + 10']);

        // B 46+4*sectors_count
        vertices.push(['dim_A + 10', '3', '0']);
        vertices.push(['dim_A + 10', '3', 'dim_B']);

        // C 48+4*sectors_count
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F', 'dim_B + 10']);
        vertices.push(['3 - dim_F - dim_J', 'dim_H + dim_F + dim_C', 'dim_B + 10']);

        // D 50+4*sectors_count
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E + dim_I - 3', 'dim_G + dim_E + dim_D', 'dim_B + 10']);

        // E 52+4*sectors_count
        vertices.push(['dim_A + dim_E', 'dim_G', 'dim_B']);
        vertices.push(['dim_A + dim_E', 'dim_G', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E * (1 - Math.cos(Math.PI / 180 * 45))', 'dim_G + dim_E * Math.sin(Math.PI / 180 * 45)', 'dim_B + 10']);

        // F 55+4*sectors_count
        vertices.push(['-dim_F', 'dim_H', 'dim_B']);
        vertices.push(['-dim_F', 'dim_H', 'dim_B + 10']);
        vertices.push(['dim_F * (Math.cos(Math.PI / 180 * 45) - 1)', 'dim_F * Math.sin(Math.PI / 180 * 45) + dim_H', 'dim_B + 10']);

        // G 58+4*sectors_count
        vertices.push(['dim_A + 3', '0', 'dim_B']);
        vertices.push(['dim_A + 10', '0', 'dim_B']);
        vertices.push(['dim_A + 10', 'dim_G', 'dim_B']);

        // H 61+4*sectors_count
        vertices.push(['-3', '0', 'dim_B']);
        vertices.push(['-10', '0', 'dim_B']);
        vertices.push(['-10', 'dim_H', 'dim_B']);

        // I 64+4*sectors_count
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E', 'dim_B + 3']);
        vertices.push(['dim_A + dim_E + dim_I', 'dim_G + dim_E', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E', 'dim_G + dim_E', 'dim_B + 10']);

        // J 67+4*sectors_count
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F', 'dim_B + 3']);
        vertices.push(['-dim_F - dim_J', 'dim_H + dim_F', 'dim_B + 10']);
        vertices.push(['-dim_F', 'dim_H + dim_F', 'dim_B + 10']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'G': empty_vals['G'] / 10,
            'H': empty_vals['H'] / 10,
            'I': empty_vals['I'] / 10,
            'J': empty_vals['J'] / 10
        };

        const arrows = {
            'A': [11, 44 + 4 * sectors_count, 45 + 4 * sectors_count, 10],
            'B': [9, 46 + 4 * sectors_count, 47 + 4 * sectors_count, 10],
            'C': [19 + 4 * sectors_count, 48 + 4 * sectors_count, 49 + 4 * sectors_count, 21 + 4 * sectors_count],
            'D': [33 + 4 * sectors_count, 50 + 4 * sectors_count, 51 + 4 * sectors_count, 35 + 4 * sectors_count],
            'E': [52 + 4 * sectors_count, 53 + 4 * sectors_count, 54 + 4 * sectors_count, 3 * sectors_count + 15],
            'F': [55 + 4 * sectors_count, 56 + 4 * sectors_count, 57 + 4 * sectors_count, sectors_count + 15],
            'G': [58 + 4 * sectors_count, 59 + 4 * sectors_count, 60 + 4 * sectors_count, 14],
            'H': [61 + 4 * sectors_count, 62 + 4 * sectors_count, 63 + 4 * sectors_count, 15],
            'I': [64 + 4 * sectors_count, 65 + 4 * sectors_count, 66 + 4 * sectors_count, 15 + 4 * sectors_count],
            'J': [67 + 4 * sectors_count, 68 + 4 * sectors_count, 69 + 4 * sectors_count, 15 + 2 * sectors_count]
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Trójnik symetryczny';
        //this.cache.print();
    }
}