import { Model } from "../model";
import { Cache } from "../cache";

export class Odsadzka extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_G < 0 ? 3 : dim_E', '0'],
            ['dim_A', 'dim_G < 0 ? dim_E : 3', '0'],
            ['dim_A', 'dim_G < 0 ? dim_E : 3', 'dim_B'],
            ['0', 'dim_G < 0 ? 3 : dim_E', 'dim_B'],

            ['dim_G', 'dim_G > 0 ? dim_D - 3 : dim_D - dim_F', '0'],
            ['dim_C + dim_G', 'dim_G > 0 ? dim_D - dim_F : dim_D - 3', '0'],
            ['dim_C + dim_G', 'dim_G > 0 ? dim_D - dim_F : dim_D - 3', 'dim_B'],
            ['dim_G', 'dim_G > 0 ? dim_D - 3 : dim_D - dim_F', 'dim_B'],

            ['dim_G', 'dim_D - 3', '0'],
            ['dim_C + dim_G', 'dim_D - 3', '0'],
            ['dim_C + dim_G', 'dim_D - 3', 'dim_B'],
            ['dim_G', 'dim_D - 3', 'dim_B'],

            ['dim_G', 'dim_D', '0'],
            ['dim_C + dim_G', 'dim_D', '0'],
            ['dim_C + dim_G', 'dim_D', 'dim_B'],
            ['dim_G', 'dim_D', 'dim_B'],

            ['dim_G - 3', 'dim_D', '-3'],
            ['dim_G + dim_C + 3', 'dim_D', '-3'],
            ['dim_G + dim_C + 3', 'dim_D', 'dim_B + 3'],
            ['dim_G - 3', 'dim_D', 'dim_B + 3'],

            // arrows
            // A 32-33
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],

            // B 34-35
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],

            // C 36-37
            ['dim_G', 'dim_D - 3', 'dim_B + 10'],
            ['dim_G + dim_C', 'dim_D - 3', 'dim_B + 10'],

            // D 38-41
            ['dim_G > 0 ? 0 : dim_A', 'dim_D', 'dim_B + 3'],
            ['dim_G > 0 ? 0 : dim_A', 'dim_D', 'dim_B + 10'],
            ['dim_G > 0 ? 0 : dim_A', '0', 'dim_B + 10'],
            ['dim_G > 0 ? 0 : dim_A', '0', 'dim_B + 3'],

            // E 42-45
            ['dim_G > 0 ? 0 : dim_A', 'dim_E', 'dim_B'],
            ['dim_G > 0 ? 0 : dim_A', 'dim_E', 'dim_B + 10'],
            ['dim_G > 0 ? 0 : dim_A', '0', 'dim_B + 10'],
            ['dim_G > 0 ? 0 : dim_A', '0', 'dim_B + 3'],

            // F 46-49
            ['dim_G < 0 ? dim_G : dim_G + dim_C', 'dim_D - dim_F', 'dim_B'],
            ['dim_G < 0 ? dim_G : dim_G + dim_C', 'dim_D - dim_F', 'dim_B + 10'],
            ['dim_G < 0 ? dim_G : dim_G + dim_C', 'dim_D', 'dim_B + 10'],
            ['dim_G < 0 ? dim_G : dim_G + dim_C', 'dim_D', 'dim_B + 3'],

            // G 50-53
            ['dim_G < 0 ? dim_G : 0', 'dim_G < 0 ? 3 : dim_D - 3', 'dim_B'],
            ['dim_G < 0 ? dim_G : 0', 'dim_G < 0 ? 3 : dim_D - 3', 'dim_B + 10'],
            ['dim_G < 0 ? 0 : dim_G', 'dim_G < 0 ? 3 : dim_D - 3', 'dim_B + 10'],
            ['dim_G < 0 ? 0 : dim_G', 'dim_G < 0 ? 3 : dim_D - 3', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [12, 13, 17, 16]},
            {materials: [0, 1], vertices: [13, 14, 18, 17]},
            {materials: [0, 1], vertices: [14, 15, 19, 18]},
            {materials: [0, 1], vertices: [15, 12, 16, 19]},

            {materials: [0, 1], vertices: [16, 17, 21, 20]},
            {materials: [0, 1], vertices: [17, 18, 22, 21]},
            {materials: [0, 1], vertices: [18, 19, 23, 22]},
            {materials: [0, 1], vertices: [19, 16, 20, 23]},

            {materials: [2], vertices: [20, 21, 25, 24]},
            {materials: [2], vertices: [21, 22, 26, 25]},
            {materials: [2], vertices: [22, 23, 27, 26]},
            {materials: [2], vertices: [23, 20, 24, 27]},

            {materials: [2], vertices: [24, 25, 29, 28]},
            {materials: [2], vertices: [25, 26, 30, 29]},
            {materials: [2], vertices: [26, 27, 31, 30]},
            {materials: [2], vertices: [27, 24, 28, 31]},
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'G': empty_vals['G'] / 10
        };

        const arrows = {
            'A': [11, 32, 33, 10],
            'B': [9, 34, 35, 10],
            'C': [23, 36, 37, 22],
            'D': [38, 39, 40, 41],
            'E': [42, 43, 44, 45],
            'F': [46, 47, 48, 49],
            'G': [50, 51, 52, 53]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Odsadzka';
    }

    isCorrect() {
        let d = this.dimensions['D'];
        let e = this.dimensions['E'];
        let f = this.dimensions['F'];

        if(d < e + f) {
            return [false, 1];
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Suma rozszerzeń musi być mniejsza od wysokości. Zmniejsz jednen z wymiarów E i F, lub zwiększ wymiar D.";
        }
        return "";
    }
}