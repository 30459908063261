import { AfterViewInit } from '@angular/core';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalsService } from 'projects/base-lib/src/lib/modules/modals/services/modal.service';
import { environment } from '../environments/environment';

@Component({
    selector: '.app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    @ViewChild("modalContainer", { read: ViewContainerRef }) modalContainer: ViewContainerRef;
    
    public version: string = environment.version;
    public env: string = environment.env;

    constructor(
        protected modalsService: ModalsService
    ) { };

    ngAfterViewInit():void {
        this.modalsService.modalsContainer = this.modalContainer;
    }
}
