import { Model } from "../model";
import { Cache } from "../cache";

export class DyfuzorSymetryczny extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_E', '0'],
            ['dim_A', 'dim_E', '0'],
            ['dim_A', 'dim_E', 'dim_B'],
            ['0', 'dim_E', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]}
        ];


        const sectors_count = 100;
        const functions = [
            {name: '$A', params: ['x'], expression: '-dim_C * Math.cos(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_A / 2'},
            {name: '$B', params: ['x'], expression: 'dim_C * Math.sin(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_B / 2'},
        ];

        // circle:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(' + i + ')', 'dim_D', '$B(' + i + ')']);
            planes.push({materials: [0, 1], vertices: [15 - Math.floor(i / (sectors_count / 4)), 16 + i, 16 + ((i + 1) % sectors_count)]});
        }

        planes.push({materials: [0, 1], vertices: [12, 13, 16 + sectors_count / 4 * 3]});
        planes.push({materials: [0, 1], vertices: [13, 14, 16 + sectors_count / 4 * 2]});
        planes.push({materials: [0, 1], vertices: [14, 15, 16 + sectors_count / 4 * 1]});
        planes.push({materials: [0, 1], vertices: [15, 12, 16]});


        // arrows
        // A 16+sectors_count - 17+sectors_count
        vertices.push(['0', '3', 'dim_B + 10']);
        vertices.push(['dim_A', '3', 'dim_B + 10']);

        // B 18+sectors_count - 19+sectors_count
        vertices.push(['dim_A + 10', '3', '0']);
        vertices.push(['dim_A + 10', '3', 'dim_B']);

        // C 20+sectors_count - 21+sectors_count
        vertices.push(['(dim_A - dim_C) / 2', 'dim_D + 10', 'dim_B / 2']);
        vertices.push(['(dim_A + dim_C) / 2', 'dim_D + 10', 'dim_B / 2']);

        // D 22 + sectors_count, 23 + sectors_count, 24 + sectors_count
        vertices.push(['dim_A + 10', 'dim_D', 'dim_B / 2']);
        vertices.push(['dim_A + 10', '0', 'dim_B / 2']);
        vertices.push(['dim_A + 3', '0', 'dim_B / 2']);

        // E 25-27
        vertices.push(['dim_A + 10', 'dim_E', 'dim_B']);
        vertices.push(['dim_A + 10', '0', 'dim_B']);
        vertices.push(['dim_A + 3', '0', 'dim_B']);

        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(' + i + ')', 'dim_D + 5', '$B(' + i + ')']);
            planes.push({materials: [0, 1], vertices: [28 + sectors_count + i, 28 + sectors_count + ((i + 1) % sectors_count), 16 + ((i + 1) % sectors_count), 16 + i]});
        }


        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10
        };

        const arrows = {
            'A': [11, 16 + sectors_count, 17 + sectors_count, 10],
            'B': [9, 18 + sectors_count, 19 + sectors_count, 10],
            'C': [16, 20 + sectors_count, 21 + sectors_count, 16 + sectors_count / 2],
            'D': [16 + sectors_count / 2, 22 + sectors_count, 23 + sectors_count, 24 + sectors_count],
            'E': [14, 25 + sectors_count, 26 + sectors_count, 27 + sectors_count]
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Dyfuzor symetryczny';
        // this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.cache.print();
    }
}