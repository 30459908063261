<div class="flex flex-wrap mb-4 items-center param-wide">
    <div class="param-left-col text-right p-2 justify-end">
        <label class="flex items-center justify-end">
            {{label}}:
        </label>
    </div>
    <div class="param-right-col items-center">
        {{value}} {{unit}}
    </div>
    <div class="param-left-col"></div>
</div>