import { Model } from "../model";
import { Cache } from "../cache";

export class CzerpniaWyrzutniaOkragla extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const sectors_count = 100;
        const net_height = 35;
        const angle = 15;
        const diameter_height_ratio = 0.5;
        const arc_angle = (Math.PI / 180 * 360).toString();
        const delta = 5;
        
        const width = 0.3;
        const tan_angle = (Math.tan(Math.PI / 180 * angle)).toString();

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': default_vals['B']
        };

        const arrows = {};

        super([], [], dimensions, arrows, [], options);

        const functions = [
            {name: '$A', params: [], expression: 'Math.floor(Math.PI * dim_A / 4 - 4 * Math.PI * dim_A / ' + sectors_count + ')'},
            {name: '$B', params: ['x', 'y'], expression: 'x * Math.cos(y * ' + arc_angle + ' / ' + sectors_count + ') / 2'},
            {name: '$C', params: ['x', 'y'], expression: 'x * Math.sin(y * ' + arc_angle + ' / ' + sectors_count + ') / 2'},
            {name: '$D', params: ['x', 'y', 'z', 'v', 'u'], expression: 'x * Math.cos(y + z * v / u * 2) / 2'},
            {name: '$E', params: ['x', 'y', 'z', 'v', 'u'], expression: 'x * Math.sin(y + z * v / u * 2) / 2'},
            {name: '$F', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A)'},
            {name: '$G', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + 2 * Math.asin(' + width + ' / dim_A)'},
            {name: '$H', params: [], expression: delta + ' * (dim_A - 2 * ' + width + ') / dim_A'},
            {name: '$I', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + Math.PI / 2'},
            {name: '$J', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + Math.PI / 2 + 2 * Math.asin(' + width + ' / dim_A)'},
            {name: '$K', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + Math.PI'},
            {name: '$L', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + Math.PI + 2 * Math.asin(' + width + ' / dim_A)'},
            {name: '$M', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + 3 * Math.PI / 2'},
            {name: '$N', params: [], expression: (2 * Math.PI / sectors_count * 4).toString() + ' + 2 * Math.asin(' + delta + ' / dim_A) + 3 * Math.PI / 2 + 2 * Math.asin(' + width + ' / dim_A)'},
            {name: '$O', params: [], expression: 'dim_A / ' + diameter_height_ratio + ' - dim_A / 2 * Math.tan(Math.PI / 180 * ' + angle + ')'},
            {name: '$P', params: ['x'], expression: '$O() + x'},
            {name: '$Q', params: [], expression: '$P(-3)'},
            {name: '$R', params: [], expression: '$P(-3 - ' + net_height + ')'},
            {name: '$S1', params: [], expression: 'dim_A / ' + diameter_height_ratio + ' - (20 + dim_A / 2) * ' + tan_angle},
            {name: '$S2', params: [], expression: 'dim_A / ' + diameter_height_ratio + ' - dim_A / 2 * ' + tan_angle + ' - 3'},
            {name: '$S3', params: ['x'], expression: '$S2() - (x + 1) * ' + delta},
            {name: '$S4', params: ['x'], expression: '$S3(x) - ' + width},
            {name: '$S5', params: [], expression: '$S2() - ' + net_height},

        ];

        this.addVerticesCircle('dim_A + 6', '0', sectors_count);
        this.addVerticesCircle('dim_A', '0', sectors_count);
        this.connectCircles(0, 1, [2], sectors_count, 0, sectors_count);
        this.addVerticesCircle('dim_A', '3', sectors_count);
        this.connectCircles(1, 2, [2], sectors_count, 0, sectors_count);
        this.addVerticesCircle('dim_A', '$R()', sectors_count);

        this.connectCircles(2, 3, [0, 1], sectors_count, 0, sectors_count);
        this.addVerticesCircle('dim_A', '$Q()', sectors_count);
        this.addVerticesCircle('dim_A', '$O()', sectors_count);

        this.connectCircles(3, 4, [0, 1], sectors_count, 0, 4);
        this.connectCircles(3, 4, [0, 1], sectors_count, sectors_count / 4, sectors_count / 4 + 4);
        this.connectCircles(3, 4, [0, 1], sectors_count, sectors_count / 4 * 2, sectors_count / 4 * 2 + 4);
        this.connectCircles(3, 4, [0, 1], sectors_count, sectors_count / 4 * 3, sectors_count / 4 * 3 + 4);

        this.connectCircles(4, 5, [0, 1], sectors_count, 0, sectors_count);
        this.addVerticesCircle('dim_A + 40', '$S1()', sectors_count);

        let v = this.vertices.length / sectors_count;

        for(let i = 0; i < net_height / delta - 1; i++) {
            this.addVerticesCircle('dim_A', '$S3(' + i + ')', sectors_count);
            this.addVerticesCircle('dim_A', '$S4(' + i + ')', sectors_count);
            this.addVerticesCircle('dim_A - ' + (2 * width), '$S4(' + i + ')', sectors_count);
            this.addVerticesCircle('dim_A - ' + (2 * width), '$S3(' + i + ')', sectors_count);

            this.connectCircles(v + 4 * i, v + 1 + 4 * i, [3], sectors_count, 4, sectors_count / 4);
            this.connectCircles(v + 4 * i, v + 1 + 4 * i, [3], sectors_count, sectors_count / 4 + 4, sectors_count / 4 * 2);
            this.connectCircles(v + 4 * i, v + 1 + 4 * i, [3], sectors_count, sectors_count / 4 * 2 + 4, sectors_count / 4 * 3);
            this.connectCircles(v + 4 * i, v + 1 + 4 * i, [3], sectors_count, sectors_count / 4 * 3 + 4, sectors_count);
            this.connectCircles(v + 1 + 4 * i, v + 2 + 4 * i, [3], sectors_count, 0, sectors_count);
            this.connectCircles(v  +2 + 4 * i, v + 3 + 4 * i, [3], sectors_count, 0, sectors_count);
            this.connectCircles(v + 3 + 4 * i, v + 4 * i, [3], sectors_count, 0, sectors_count);
        }
        // first quarter
        v = this.vertices.length;
        let vertical_count = Math.floor(Math.PI * 350 / delta / 4) - 4;
        let start = 0;
        let end = vertical_count;
        let start_angle_1 = '$F()';
        let start_angle_2 = '$G()';
        let small_delta = '$H()';

        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - 2 * ' + width, '$S2()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - 2 * ' + width, '$S2()', start, end, small_delta, start_angle_2);

        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_2);
        let p = this.planes.length;

        for(let i = 0; i < vertical_count; i++) {
            this.planes.push({materials: [3], vertices: [v + i, v + end - start + i, v + 5 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + i, v + 2 * (end - start) + i, v + 6 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + 2 * (end - start) + i, v + 3 *(end - start) + i, v + 7 * (end - start) + i, v + 6 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + end - start + i, v + 3 * (end - start) + i, v + 7 * (end - start) + i, v + 5 * (end - start) + i]});
            this.holes.push({plane_index: (p + 4 * i).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
        }

        // second quarter
        start_angle_1 = '$I()';
        start_angle_2 = '$J()';
        v = this.vertices.length;

        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_2);

        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_2);

        p = this.planes.length;

        for(let i = 0; i < vertical_count; i++) {
            this.planes.push({materials: [3], vertices: [v + i, v + end - start + i, v + 5 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + i, v + 2 * (end - start) + i, v + 6 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + 2 * (end - start) + i, v + 3 *(end - start) + i, v + 7 * (end - start) + i, v + 6 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + end - start + i, v + 3 * (end - start) + i, v + 7 * (end - start) + i, v + 5 * (end - start) + i]});
            this.holes.push({plane_index: (p + 4 * i).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
        }

        // third quarter
        start_angle_1 = '$K()';
        start_angle_2 = '$L()';
        v = this.vertices.length;

        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_2);

        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_2);

        p = this.planes.length;

        for(let i = 0; i < vertical_count; i++) {
            this.planes.push({materials: [3], vertices: [v + i, v + end - start + i, v + 5 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + i, v + 2 * (end - start) + i, v + 6 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + 2 * (end - start) + i, v + 3 *(end - start) + i, v + 7 * (end - start) + i, v + 6 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + end - start + i, v + 3 * (end - start) + i, v + 7 * (end - start) + i, v + 5 * (end - start) + i]});
            this.holes.push({plane_index: (p + 4 * i).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
        }

        // fourth quarter
        start_angle_1 = '$M()';
        start_angle_2 = '$N()';
        v = this.vertices.length;

        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S2()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S2()', start, end, small_delta, start_angle_2);

        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_1);
        this.addVerticesArc('dim_A', '$S5()', start, end, String(delta), start_angle_2);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_1);
        this.addVerticesArc('dim_A - ' + (2 * width), '$S5()', start, end, small_delta, start_angle_2);

        p = this.planes.length;

        for(let i = 0; i < vertical_count; i++) {
            this.planes.push({materials: [3], vertices: [v + i, v + end - start + i, v + 5 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + i, v + 2 * (end - start) + i, v + 6 * (end - start) + i, v + 4 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + 2 * (end - start) + i, v + 3 *(end - start) + i, v + 7 * (end - start) + i, v + 6 * (end - start) + i]});
            this.planes.push({materials: [3], vertices: [v + end - start + i, v + 3 * (end - start) + i, v + 7 * (end - start) + i, v + 5 * (end - start) + i]});
            this.holes.push({plane_index: (p + 4 * i).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
            this.holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$A() > ' + ((i + 1) * delta).toString()});
        }

        let tip_index = this.vertices.length;
        this.vertices.push(['0', 'dim_A / ' + diameter_height_ratio, '0']);
        for(let i = 0; i < sectors_count; i++) {
            this.planes.push({materials: [0, 1], vertices: [6 * sectors_count + (i % sectors_count), 6 * sectors_count + ((i + 1) % sectors_count), tip_index]});
        }

        v = this.vertices.length;
        this.vertices.push(['-dim_A / 2', '0', '0']);
        this.vertices.push(['-dim_A / 2', '-10', '0']);
        this.vertices.push(['dim_A / 2', '-10', '0']);
        this.vertices.push(['dim_A / 2', '0', '0']);
        this.arrows.A = [v, v + 1, v + 2, v + 3];

        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.name = 'Czerpnia wyrzutnia okrągła';
    }

    addVerticesCircle(diameter, z, sectors_count) {
        const arc_angle = (Math.PI / 180 * 360).toString();
        for(let i = 0; i < sectors_count; i++) {
            this.vertices.push(['$B(' + diameter + ', ' + i + ')', z, '$C(' + diameter + ', ' + i + ')']);
        }
    }

    addVerticesArc(diameter, z, start, end, space_distance = '1', start_angle = '0') {
        for(let i = start; i < end; i++) {
            this.vertices.push(['$D(' + diameter + ', ' + start_angle + ', + ' + i + ', ' + space_distance + ', ' + diameter + ')', z, '$E(' + diameter + ', ' + start_angle + ', ' + i + ', ' + space_distance + ', ' + diameter + ')']);
        }
    }

    connectCircles(circle_1, circle_2, materials, sectors_count, start, end) {
        for(let i = start; i < end; i++) {
            this.planes.push({materials: materials, vertices: [circle_1 * sectors_count + (i % sectors_count), circle_1 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + (i % sectors_count)]});
        }
    }

    setDimensionValue(name, value) {
        if(name == 'B') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'B') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'B') {
            return this.options[name][this.last_correct_dimensions[name]];
        }
        return this.last_correct_dimensions[name] * 10;
    }
}