import { Model } from "../model";
import { Cache } from "../cache";

export class LukSymetryczny10_45stopni extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            // finishing part 1 0-11
            ['-3', '0', '-3'],
            ['dim_C + 3', '0', '-3'],
            ['dim_C + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_C', '0', '0'],
            ['dim_C', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_C', '3', '0'],
            ['dim_C', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            // sheets 12 - 19
            ['0', 'dim_F', '0'],
            ['dim_C', 'dim_F + (dim_A - dim_C * Math.cos(Math.PI / 180 * dim_D)) / Math.sin(Math.PI / 180 * dim_D)', '0'],
            ['dim_C', 'dim_F + (dim_A - dim_C * Math.cos(Math.PI / 180 * dim_D)) / Math.sin(Math.PI / 180 * dim_D)', 'dim_B'],
            ['0', 'dim_F', 'dim_B'],

            ['(3 - dim_E) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', '0'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - (dim_E - 3) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', '0'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - (dim_E - 3) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', 'dim_B'],
            ['(3 - dim_E) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', 'dim_B'],

            // finishing part 2 20-27
            ['-dim_E * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D)', '0'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - dim_E * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + dim_E * Math.cos(Math.PI / 180 * dim_D)', '0'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - dim_E * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + dim_E * Math.cos(Math.PI / 180 * dim_D)', 'dim_B'],
            ['-dim_E * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D)', 'dim_B'],

            ['-dim_E * Math.sin(Math.PI / 180 * dim_D) - 3 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D) - 3 * Math.sin(Math.PI / 180 * dim_D)', '-3'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - dim_E * Math.sin(Math.PI / 180 * dim_D) + 3 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + dim_E * Math.cos(Math.PI / 180 * dim_D) + 3 * Math.sin(Math.PI / 180 * dim_D)', '-3'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - dim_E * Math.sin(Math.PI / 180 * dim_D) + 3 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + dim_E * Math.cos(Math.PI / 180 * dim_D) + 3 * Math.sin(Math.PI / 180 * dim_D)', 'dim_B + 3'],
            ['-dim_E * Math.sin(Math.PI / 180 * dim_D) - 3 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D) - 3 * Math.sin(Math.PI / 180 * dim_D)', 'dim_B + 3'],

            // arrows
            // A 28-29
            ['(3 - dim_E) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', 'dim_B + 10'],
            ['dim_A * Math.cos(Math.PI / 180 * dim_D) - (dim_E - 3) * Math.sin(Math.PI / 180 * dim_D)', 'dim_F + dim_A * Math.sin(Math.PI / 180 * dim_D) + (dim_E - 3) * Math.cos(Math.PI / 180 * dim_D)', 'dim_B + 10'],

            // B 30-31
            ['dim_C + 10', '3', '0'],
            ['dim_C + 10', '3', 'dim_B'],

            // C 32-33
            ['0', '3', 'dim_B + 10'],
            ['dim_C', '3', 'dim_B + 10'],

            // E 34-36
            ['-dim_E * Math.sin(Math.PI / 180 * dim_D) - 3 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D) - 3 * Math.sin(Math.PI / 180 * dim_D)', 'dim_B'],
            ['-dim_E * Math.sin(Math.PI / 180 * dim_D) - 10 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F + dim_E * Math.cos(Math.PI / 180 * dim_D) - 10 * Math.sin(Math.PI / 180 * dim_D)', 'dim_B'],
            ['-10 * Math.cos(Math.PI / 180 * dim_D)', 'dim_F - 10 * Math.sin(Math.PI / 180 * dim_D)', 'dim_B'],

            // F 37-39
            ['-3', '0', 'dim_B'],
            ['-10', '0', 'dim_B'],
            ['-10', 'dim_F', 'dim_B'],

        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [12, 15, 11, 8]},
            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},

            {materials: [0, 1], vertices: [16, 12, 13, 17]},
            {materials: [0, 1], vertices: [13, 14, 18, 17]},
            {materials: [0, 1], vertices: [14, 15, 19, 18]},
            {materials: [0, 1], vertices: [16, 19, 15, 12]},

            {materials: [2], vertices: [20, 16, 17, 21]},
            {materials: [2], vertices: [21, 17, 18, 22]},
            {materials: [2], vertices: [22, 18, 19, 23]},
            {materials: [2], vertices: [23, 19, 16, 20]},

            {materials: [2], vertices: [20, 24, 25, 21]},
            {materials: [2], vertices: [21, 25, 26, 22]},
            {materials: [2], vertices: [22, 26, 27, 23]},
            {materials: [2], vertices: [23, 27, 24, 20]},
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'],
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10
        };

        const arrows = {
            'A': [18, 29, 28, 19],
            'B': [9, 30, 31, 10],
            'C': [11, 32, 33, 10],
            'E': [34, 35, 36, 15],
            'F': [37, 38, 39, 15]
        };

        const holes = [];
        let functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Łuk symetryczny 10-45 stopni';
    }

    setDimensionValue(name, value) {
        if(name == 'D') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'D') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'D') {
            return this.last_correct_dimensions[name];
        } else {
            return this.last_correct_dimensions[name] * 10;
        }
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let c = this.dimensions['C'];
        let e = this.dimensions['E'];
        let f = this.dimensions['F'];
        let alpha = Math.PI / 180 * this.dimensions['D'];
          let correct = (a * Math.cos(alpha) - (e - 3) * Math.sin(alpha) - c < 0 &&
                       a * Math.sin(alpha) + (e - 3) * Math.cos(alpha) - (a - c * Math.cos(alpha)) / Math.sin(alpha) >= 0) &&
                      (c * Math.cos(alpha) - (f - 3) * Math.sin(alpha) - a < 0 &&
                          c * Math.sin(alpha) + (f - 3) * Math.cos(alpha) - (c - a * Math.cos(alpha)) / Math.sin(alpha) >= 0);
        return [correct, 1];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Różnica między wymiarami A i C jest zbyt duża. Zmniejsz różnicę, albo zwiększ wymiary E lub F. Może też pomóc zwiększenie kąta.";
        }
        return "";
    }

}