export class Customer {
    id?: number;
    // number?: string;
    key?: string;
    name: string;
    email: string;
    phone: string;
    city: string;
    zip_code: string;
    street: string;
    nip: string;
}
