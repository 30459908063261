import { Injectable } from '@angular/core';
import { 
    HttpEvent, HttpRequest, HttpHandler, 
    HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalsService } from 'projects/base-lib/src/lib/modules/modals/services/modal.service';
import { ToastModalComponent } from 'projects/base-lib/src/lib/modules/modals/components/toast-modal/toast-modal.component';
import { MessageType } from 'projects/base-lib/src/lib/modules/modals/models/message-type';
import { ModalConfig } from 'projects/base-lib/src/lib/modules/modals/models/modal-config';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        protected router: Router,
        protected modalsService: ModalsService
    ) { }
        
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    if (error.status === 0) {
                        this.showError(new HttpErrorResponse(
                            {
                                error: {
                                    message: "Błąd komunikacji z serwerem."
                                }
                            }
                        ));
                        return EMPTY;
                    } else if (error.status === 401) {
                        this.showError(error);

                        return throwError(error);
                    } else if (error.status === 422) { 
                        // Błąd walidacji danych
                        this.showError(error);

                        return throwError(error);
                    } else if (error.status === 404) { 
                        // Brak wskazanych danych
                        this.showError(error);

                        return throwError(error);
                    } else {
                        this.showError(error);
                        
                        return EMPTY;
                    }
                }
            )
        );    
    }

    protected showError(error: HttpErrorResponse) {
        const modalConfig: ModalConfig = {
            type: ToastModalComponent,
            data: {
                message: {
                    message: error.error.message,
                    type: MessageType.error,
                    errors: error.error.errors ? error.error.errors : null
                }
            },
            time: 0
        };

        this.modalsService.showModal(modalConfig); 
    }
}