import { Component, Input, OnInit } from '@angular/core';
import { OptionsParamConf } from '../../../models/options-param-conf';
import { ShapeEditorComponent } from '../../shape-editor/shape-editor.component';
import { ParamComponent } from '../param/param.component';

@Component({
    selector: 'app-options-param',
    templateUrl: './options-param.component.html',
    styleUrls: ['./options-param.component.scss']
})
export class OptionsParamComponent extends ParamComponent implements OnInit {

    @Input()
    paramConfig: OptionsParamConf;

    public getError(): string {
        return "Wybierz jedną z opcji";
    }
}
