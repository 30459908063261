<div class="w-full">
    <div class="bg-white" style="z-index: 100; position: sticky; top: 4rem; ">
        <div class="text-2xl pt-8 h-16 flex justify-center items-center font-bold text-gray-700 mb-8" >
            <h1 class="mr-2">Przyjmujemy zamówienia online</h1>
            <div class="text-gray-700"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
            </div>
        </div>
        
        <div class="my-24 flex justify-center">
            <div class="w-2/3 text-2xl leading-10">
                Wybierz kształty z naszego obszernego katalogu.
                Podaj ich wymiary i parametry.
                Jeśli nie znajdziesz kształtu w naszym katalogu, dołącz do zamówienia rysunek i opis - wycenimy i przygotujemy elementy specjalnie dla Ciebie.<br/>
                Oferujemy też transport.
            </div>
        </div>

        <div class="flex justify-center mt-16">
            <bl-main-button [routerLink]="['/catalog']" >
                <div class="mr-2">
                    Wybierz kształty
                </div> 
                <svg xmlns="http://www.w3.org/2000/svg" class="icon-small" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
            </bl-main-button>
        </div>
    </div> 
</div>

