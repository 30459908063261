import { Component, Input, OnInit, forwardRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// https://medium.com/joolsoftware/creating-a-custom-formcontrol-in-angular-da92f2f47733

@Component({
    selector: 'decimal-input',
    templateUrl: './decimal-input.component.html',
    styleUrls: ['./decimal-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DecimalInputComponent),
            multi: true
        }
    ]
})
export class DecimalInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('input') input: ElementRef; 
    
    disabled = false;
    value = null;

    private oldValue: string; 
    private oldSelectionStart: number | null;
    private oldSelectionEnd: number | null;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor() { }

    ngOnInit(): void { }

    ngAfterViewInit() {
        this.input.nativeElement.addEventListener(
            'input', this.onElInput.bind(this));

        this.input.nativeElement.addEventListener(
            'change', this.onElChange.bind(this));
    }

    // https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input
    private onElInput(event: any) {
        let el: HTMLInputElement = event.target as HTMLInputElement;
        
        if (event.target.value == "" || /^\d+\,?\d{0,2}$/.test(event.target.value)) {
            this.oldValue = this.value = event.target.value;
            this.oldSelectionStart = el.selectionStart;
            this.oldSelectionEnd = el.selectionEnd;
        } else if (this.oldValue) {
            this.value = event.target.value = this.oldValue;
            if (this.oldSelectionStart !== null &&
                this.oldSelectionEnd !== null) {
                el.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        } else {
            this.value = event.target.value = "";
        }
    };

    private onElChange(event: any) {
        let value = event.target.value ? String(Number(String(event.target.value).replace(',', '.')).toFixed(2)).replace('.', ',') : null;

        if(value) {
            this.value = event.target.value = value;
        }

        this.onChange(this.value ? Number(String(event.target.value).replace(',', '.')).toFixed(2) : null);
        this.onTouched(this.value ? Number(String(event.target.value).replace(',', '.')).toFixed(2) : null);
    }

    writeValue(value: number): void {
        this.value = value || value === 0 ? String(Number(value).toFixed(2)).replace('.', ',') : null;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

}