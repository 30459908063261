import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';

const routes: Routes = [
    {
        path: '',
        component: WelcomeComponent,
    },
    {
        path: 'catalog', 
        loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule),
    },
    {
        path: 'creator', 
        loadChildren: () => import('./modules/creator/creator.module').then(m => m.CreatorModule),
    },
    {
        path: 'inquiries', 
        loadChildren: () => import('./modules/inquiries/inquiries.module').then(m => m.InquiriesModule),
    },
    {
        path: 'offers', 
        loadChildren: () => import('./modules/offers/offers.module').then(m => m.OffersModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
