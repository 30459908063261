import { Model } from "../model";
import { Cache } from "../cache";

export class TrojnikRedukcyjnyZOdejsciemProstokatnym extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_B + 3', '0', '-3'],
            ['dim_B + 3', '0', 'dim_A + 3'],
            ['-3', '0', 'dim_A + 3'],

            ['0', '0', '0'],
            ['dim_B', '0', '0'],
            ['dim_B', '0', 'dim_A'],
            ['0', '0', 'dim_A'],

            ['0', '3', '0'],
            ['dim_B', '3', '0'],
            ['dim_B', '3', 'dim_A'],
            ['0', '3', 'dim_A'],

            ['0', '3', '0'],
            ['dim_B', '3', '0'],
            ['dim_B', '3', 'dim_A'],
            ['0', '3', 'dim_A'],

            ['0', 'dim_L - 3', '0'],
            ['dim_C', 'dim_L - 3', '0'],
            ['dim_C', 'dim_L - 3', 'dim_A'],
            ['0', 'dim_L - 3', 'dim_A'],

            ['0', 'dim_L - 3', '0'],
            ['dim_C', 'dim_L - 3', '0'],
            ['dim_C', 'dim_L - 3', 'dim_A'],
            ['0', 'dim_L - 3', 'dim_A'],

            ['0', 'dim_L', '0'],
            ['dim_C', 'dim_L', '0'],
            ['dim_C', 'dim_L', 'dim_A'],
            ['0', 'dim_L', 'dim_A'],

            ['-3', 'dim_L', '-3'],
            ['dim_C + 3', 'dim_L', '-3'],
            ['dim_C + 3', 'dim_L', 'dim_A + 3'],
            ['-3', 'dim_L', 'dim_A + 3'],

            // arrows
            // B 32-33
            ['0', '3', 'dim_A + 10'],
            ['dim_B', '3', 'dim_A + 10'],

            // A 34-35
            ['dim_B + 10', '3', '0'],
            ['dim_B + 10', '3', 'dim_A'],

            // C 36-37
            ['0', 'dim_L - 3', 'dim_A + 10'],
            ['dim_C', 'dim_L - 3', 'dim_A + 10'],

            // L 38-41
            ['dim_B', 'dim_L', 'dim_A + 3'],
            ['dim_B', 'dim_L', 'dim_A + 10'],
            ['dim_B', '0', 'dim_A + 10'],
            ['dim_B', '0', 'dim_A + 3'],

            // otwór 42 - 49
            ['0', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f - dim_F / 2'],
            ['0', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['0', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['0', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f - dim_F / 2'],

            ['3 - dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f - dim_F / 2'],
            ['3 - dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['3 - dim_h', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['3 - dim_h', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f - dim_F / 2'],

            // ramka otworu 50 - 57
            ['-dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f - dim_F / 2'],
            ['-dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['-dim_h', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['-dim_h', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f - dim_F / 2'],

            ['-dim_h', 'dim_L - dim_e - dim_E / 2 - 3', 'dim_A - dim_f - dim_F / 2 - 3'],
            ['-dim_h', 'dim_L - dim_e - dim_E / 2 - 3', 'dim_A - dim_f + dim_F / 2 + 3'],
            ['-dim_h', 'dim_L - dim_e + dim_E / 2 + 3', 'dim_A - dim_f + dim_F / 2 + 3'],
            ['-dim_h', 'dim_L - dim_e + dim_E / 2 + 3', 'dim_A - dim_f - dim_F / 2 - 3'],

            // arrows
            // E 58-59
            ['-10 - dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f + dim_F / 2'],
            ['-10 - dim_h', 'dim_L - dim_e + dim_E / 2', 'dim_A - dim_f + dim_F / 2'],

            // F 60-61
            ['-10 - dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f - dim_F / 2'],
            ['-10 - dim_h', 'dim_L - dim_e - dim_E / 2', 'dim_A - dim_f + dim_F / 2'],

            // e 62-65
            ['0', 'dim_L - dim_e', 'dim_A - dim_f'],
            ['-10 - dim_h', 'dim_L - dim_e', 'dim_A - dim_f'],
            ['-10 - dim_h', 'dim_L', 'dim_A - dim_f'],
            ['-3', 'dim_L', 'dim_A - dim_f'],

            // f 66-67
            ['-10 - dim_h', 'dim_L - dim_e', 'dim_A'],
            ['0', 'dim_L - dim_e', 'dim_A'],

            // h 68-70
            ['0', 'dim_L - dim_e', 'dim_A + 10'],
            ['-dim_h', 'dim_L - dim_e', 'dim_A + 10'],
            ['-dim_h', 'dim_L - dim_e', 'dim_A - dim_f + dim_F / 2 + 3']

        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [12, 13, 17, 16]},
            {materials: [0, 1], vertices: [13, 14, 18, 17]},
            {materials: [0, 1], vertices: [14, 15, 19, 18]},
            {materials: [0, 1], vertices: [15, 12, 16, 19]},

            {materials: [0, 1], vertices: [16, 17, 21, 20]},
            {materials: [0, 1], vertices: [17, 18, 22, 21]},
            {materials: [0, 1], vertices: [18, 19, 23, 22]},
            {materials: [0, 1], vertices: [19, 16, 20, 23]},

            {materials: [2], vertices: [20, 21, 25, 24]},
            {materials: [2], vertices: [21, 22, 26, 25]},
            {materials: [2], vertices: [22, 23, 27, 26]},
            {materials: [2], vertices: [23, 20, 24, 27]},

            {materials: [2], vertices: [24, 25, 29, 28]},
            {materials: [2], vertices: [25, 26, 30, 29]},
            {materials: [2], vertices: [26, 27, 31, 30]},
            {materials: [2], vertices: [27, 24, 28, 31]},

            {materials: [0, 1], vertices: [43, 42, 46, 47]},
            {materials: [0, 1], vertices: [44, 43, 47, 48]},
            {materials: [0, 1], vertices: [45, 44, 48, 49]},
            {materials: [0, 1], vertices: [42, 45, 49, 46]},

            {materials: [2], vertices: [47, 46, 50, 51]},
            {materials: [2], vertices: [48, 47, 51, 52]},
            {materials: [2], vertices: [49, 48, 52, 53]},
            {materials: [2], vertices: [46, 49, 53, 50]},

            {materials: [2], vertices: [51, 50, 54, 55]},
            {materials: [2], vertices: [52, 51, 55, 56]},
            {materials: [2], vertices: [53, 52, 56, 57]},
            {materials: [2], vertices: [50, 53, 57, 54]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'L': empty_vals['L'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'e': empty_vals['e'] / 10,
            'f': empty_vals['f'] / 10,
            'h': empty_vals['h'] / 10
        };

        const arrows = {
            'A': [9, 34, 35, 10],
            'B': [11, 32, 33, 10],
            'C': [23, 36, 37, 22],
            'L': [38, 39, 40, 41],
            'E': [51, 58, 59, 52],
            'F': [50, 60, 61, 51],
            'e': [62, 63, 64, 65],
            'f': [62, 63, 66, 67],
            'h': [67, 68, 69, 70]
        };

        const holes = [
            {plane_index: '19', vertices: [42, 43, 44, 45]}
        ];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache.print();
        this.name = 'Trójnik redukcyjny z odejściem prostokątnym';

        
    }

    isCorrect() {
        let A = this.dimensions['A'];
        let L = this.dimensions['L'];
        let E = this.dimensions['E'];
        let e = this.dimensions['e'];
        let F = this.dimensions['F'];
        let f = this.dimensions['f'];

        if(E > L - 6) {
            return [false, 1];
        }
        if(F > A) {
            return [false, 2];
        }
        if(e > L) {
            return [false, 3];
        }
        if(f > A) {
            return [false, 4];
        }
        if(e - 3 < E / 2) {
            return [false, 5];
        }
        if(f < F / 2) {
            return [false, 6];
        }
        if(L - e - 3 < E / 2) {
            return [false, 7];
        }
        if(A - f < F / 2) {
            return [false, 8];
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Szerokość odejścia nie może być większa od długości trójnika pomniejszonej o wykończenia. Zmniejsz wymiar E, lub zwiększ wymiar L.";
        }
        if(error_code == 2) {
            return "Głębokość odejścia nie może być większa od głębokości trójnika. Zmniejsz wymiar F, lub zwiększ wymiar A.";
        }
        if(error_code == 3) {
            return "Pozycja X odejścia nie może być większa od długości trójnika. Zmniejsz wymiar e, lub zwiększ wymiar L.";
        }
        if(error_code == 4) {
            return "Pozycja Y odejścia nie może być większa od głębokości trójnika. Zmniejsz wymiar f, lub zwiększ wymiar A.";
        }
        if(error_code == 5) {
            return "Pozycja X odejścia pomniejszona o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zmniejsz wymiar E, lub zwiększ wymiar e.";
        }
        if(error_code == 6) {
            return "Pozycja Y odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz wymiar F, lub zwiększ wymiar f.";
        }
        if(error_code == 7) {
            return "Długość trójnika pomniejszona o pozycję X odejścia i o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zwiększ jeden z wymiarów L i E, lub zmniejsz wymiar e.";
        }
        if(error_code == 8) {
            return "Głębokość trójnika pomniejszona o pozycję Y odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz jeden z wymiarów A i F, lub zmniejsz wymiar f.";
        }

        return "";
    }
}