import { Model } from "../model";
import { Cache } from "../cache";

export class CzerpniaScienna extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['0', '0', '7'],
            ['dim_A', '0', '7'],
            ['dim_A', 'dim_B', '7'],
            ['0', 'dim_B', '7'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],

            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],

            //arrow A
            ['0', 'dim_B / 2', '7'],
            ['0', 'dim_B / 2', '7 + 10'],
            ['dim_A', 'dim_B / 2', '7 + 10'],
            ['dim_A', 'dim_B / 2', '7'],
            //arrow B
            ['dim_A / 2', 'dim_B', '7'],
            ['dim_A / 2', 'dim_B', '7 + 10'],
            ['dim_A / 2', '0', '7 + 10'],
            ['dim_A / 2', '0', '7'],

            ['0', '0', '1'],
            ['dim_A', '0', '1'],

            ['0', '2', '1'],
            ['dim_A', '2', '1'],
            ['dim_A', '7', '6'],
            ['0', '7', '6'],

            ['0', '9', '6'],
            ['dim_A', '9', '6'],


            ['0', 'dim_B - 9', '1'],
            ['dim_A', 'dim_B - 9', '1'],

            ['0', 'dim_B - 7', '1'],
            ['dim_A', 'dim_B - 7', '1'],
            ['dim_A', 'dim_B - 2', '6'],
            ['0', 'dim_B - 2', '6'],

            ['0', 'dim_B', '6'],
            ['dim_A', 'dim_B', '6']
        ];

        const planes = [
            {materials: [4], vertices: [4, 5, 1, 0]},
            {materials: [4], vertices: [5, 6, 2, 1]},
            {materials: [4], vertices: [6, 7, 3, 2]},
            {materials: [4], vertices: [7, 4, 0, 3]},

            {materials: [4], vertices: [4, 5, 9, 8]},
            {materials: [4], vertices: [5, 6, 10, 9]},
            {materials: [4], vertices: [6, 7, 11, 10]},
            {materials: [4], vertices: [7, 4, 8, 11]},

            {materials: [4], vertices: [20, 21, 23, 22]},
            {materials: [4], vertices: [22, 23, 24, 25]},
            {materials: [4], vertices: [24, 25, 26, 27]},

            {materials: [4], vertices: [28, 29, 31, 30]},
            {materials: [4], vertices: [30, 31, 32, 33]},
            {materials: [4], vertices: [32, 33, 34, 35]},
        ];

        const delta = 5;
        const holes = [];
        const functions = [
            {name: '$A', params: ['x'], expression: 'x * ((dim_B - 11 - 2 * Math.ceil((dim_B - 18) / 9)) / (Math.ceil((dim_B - 18) / 9) + 1) + 2)'},
            {name: '$B', params: ['x'], expression: 'dim_B - 18 > x * 9'},
            {name: '$C', params: ['x'], expression: 'dim_A - ' + delta + ' > x * ' + delta},
            {name: '$D', params: ['x'], expression: 'dim_B - ' + delta + ' > x * ' + delta},
        ];

        let v = vertices.length;
        let p = planes.length;
        for(let i = 0; i < Math.ceil((3500 - 180) / 90); i++) {
            vertices.push(['dim_A', '$A(' + (i + 1) + ')', '1']);
            vertices.push(['0', '$A(' + (i + 1) + ')', '1']);
            vertices.push(['0', '$A(' + (i + 1) + ') + 2', '1']);
            vertices.push(['dim_A', '$A(' + (i + 1) + ') + 2', '1']);

            vertices.push(['dim_A', '$A(' + (i + 1) + ') + 7', '6']);
            vertices.push(['0', '$A(' + (i + 1) + ') + 7', '6']);
            vertices.push(['0', '$A(' + (i + 1) + ') + 9', '6']);
            vertices.push(['dim_A', '$A(' + (i + 1) + ') + 9', '6']);

            planes.push({materials: [4], vertices: [v + 8 * i, v + 8 * i + 1, v + 8 * i + 2, v + 8 * i + 3]});
            planes.push({materials: [4], vertices: [v + 8 * i + 2, v + 8 * i + 3, v + 8 * i + 4, v + 8 * i + 5]});
            planes.push({materials: [4], vertices: [v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 7]});

            holes.push({plane_index: (p + 3 * i).toString(), visible: '$B(' + i + ')'});
            holes.push({plane_index: (p + 3 * i + 1).toString(), visible: '$B(' + i + ')'});
            holes.push({plane_index: (p + 3 * i + 2).toString(), visible: '$B(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;

        const width = 0.3;

        for(let i = 0; i < 350 / delta; i++) {
            vertices.push([(delta - width + i * delta).toString(), '0', '7 - ' + width.toString()]);
            vertices.push([((delta - width + i * delta) + width).toString(), '0', '7 - ' + width.toString()]);
            vertices.push([((delta - width + i * delta) + width).toString(), '0', '7']);
            vertices.push([(delta - width + i * delta).toString(), '0', '7']);

            vertices.push([(delta - width + i * delta).toString(), 'dim_B', '7 - ' + width.toString()]);
            vertices.push([((delta - width + i * delta) + width).toString(), 'dim_B', '7 - ' + width.toString()]);
            vertices.push([((delta - width + i * delta) + width).toString(), 'dim_B', '7']);
            vertices.push([(delta - width + i * delta).toString(), 'dim_B', '7']);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$C(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$C(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$C(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$C(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;
        for(let i = 0; i < 350 / delta; i++) {
            vertices.push(['0', (delta - width + i * delta).toString(), '7 - ' + width.toString()]);
            vertices.push(['0', ((delta - width + i * delta) + width).toString(), '7 - ' + width.toString()]);
            vertices.push(['0', ((delta - width + i * delta) + width).toString(), '7']);
            vertices.push(['0', (delta - width + i * delta).toString(), '7']);

            vertices.push(['dim_A', (delta - width + i * delta).toString(), '7 - ' + width.toString()]);
            vertices.push(['dim_A', ((delta - width + i * delta) + width).toString(), '7 - ' + width.toString()]);
            vertices.push(['dim_A', ((delta - width + i * delta) + width).toString(), '7']);
            vertices.push(['dim_A', (delta - width + i * delta).toString(), '7']);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$D(' + i + ')'});
        }

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10
        };

        const arrows = {
            'A': [12, 13, 14, 15],
            'B': [16, 17, 18, 19]
        };

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Czerpnia ścienna';
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
    }
}