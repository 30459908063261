<button 
    (click)="onClick()" 
    class="flex p-4 rounded-full focus:outline-none focus:ring focus:border-blue-300" 
    [ngClass]="getClasses()"
    type="button"
>
    <div class="flex items-center font-bold text-white">
        <ng-content></ng-content>
        {{locks}}
    </div>
</button>
