import { CachedBase } from "./cached-based";

export class CachedFunction extends CachedBase {
    params: any;
    name: any;
    dimensions: any;
    expression: any;

    static fromExpression(name, params, expression, functions, all_dim_values) {
        let new_cached_function = new CachedFunction();
        new_cached_function.params = params;
        new_cached_function.name = name;
        new_cached_function.dimensions = new Set();
        let parsed_expression = expression;
        let needle = 'dim_';
        let index = parsed_expression.indexOf(needle);
        while(index != -1) {
            let letter = parsed_expression[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < parsed_expression.length) {
                let digit_candidate = parsed_expression[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let dim_index = letter + digit;
            new_cached_function.dimensions.add(dim_index);
            let dim_value = all_dim_values[dim_index];
            parsed_expression = parsed_expression.replace(needle + dim_index, String(dim_value));
            index = parsed_expression.indexOf(needle, index);
        }
        new_cached_function.expression = new_cached_function.simplifyExpression(expression);

        CachedFunction.registerFunction(name, params, parsed_expression);
        let extra_dimensions = CachedFunction.getDimensionsOfFunctionsInExpression(parsed_expression, functions);
        for(let extra_dimension of extra_dimensions) {
            new_cached_function.dimensions.add(extra_dimension);
        }
        return new_cached_function;
    }

    static createEmpty() {
        let new_cached_function = new CachedFunction();
        new_cached_function.params = [];
        new_cached_function.name = "";
        new_cached_function.dimensions = new Set();
        new_cached_function.expression = "";
        return new_cached_function;
    }

    static registerFunction(name, params, parsed_expression) {
        let params_list = "";
        for(let i = 0; i < params.length; i++) {
            params_list += "'" + params[i] + "',";
        }
        eval("window." + name + " = new Function(" + params_list + " 'return " + parsed_expression + "');");
    }

    update(changed_dim_names, all_dim_values) {
        let found = false;
        for(let i = 0; !found && i < changed_dim_names.length; i++) {
            found = this.dimensions.has(changed_dim_names[i]);
        }
        if(found) {
            CachedFunction.registerFunction(this.name, this.params, this.assignValuesToDims(this.expression, all_dim_values));
        }
    }

}