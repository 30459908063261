import { Model } from "../model";
import { Cache } from "../cache";

export class TrojnikOrlowy extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            // sheets 0 - 7
            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', 'dim_G', '0'],
            ['0', 'dim_G', '0'],

            ['0', '3', 'dim_B'],
            ['dim_A', '3', 'dim_B'],
            ['dim_A', 'dim_G', 'dim_B'],
            ['0', 'dim_G', 'dim_B']
        ];

        const planes = [
            {materials: [0, 1], vertices: [0, 1, 2, 3]},
            {materials: [0, 1], vertices: [7, 6, 5, 4]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [0, 3, 7, 4]},
        ];

        const sectors_count = 100;
        const functions = [
            {name: '$A', params: [], expression: '((dim_A + dim_E) * (dim_A + dim_E) + (dim_D + dim_F) * (dim_D + dim_F) - (dim_C + dim_E) * (dim_C + dim_E) - dim_F * dim_F) / 2 / (dim_A + dim_F + dim_E)'},
            {name: '$B', params: [], expression: 'Math.sqrt((dim_D + dim_F) * (dim_D + dim_F) - ($A() + dim_F) * ($A() + dim_F))'},
            {name: '$C', params: [], expression: 'Math.atan2($B(), dim_F + $A())'},
            {name: '$D', params: [], expression: 'Math.atan2($B(), dim_E + dim_A - $A())'},
            {name: '$E', params: ['x', 'y', 'z', 'a'], expression: 'x * Math.cos(a + y * (Math.PI / 180 * 90 - a) / ' + sectors_count + ') + z'},
            {name: '$F', params: ['x', 'y', 'z', 'a'], expression: 'x * Math.sin(a + y * (Math.PI / 180 * 90 - a) / ' + sectors_count + ') + z'},
            {name: '$G', params: ['x', 'y', 'z'], expression: '$E(x, y, z, 0)'},
            {name: '$H', params: ['x', 'y', 'z'], expression: '$F(x, y, z, 0)'}
        ];


        //small left arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$G(dim_F, ' + i + ' + 1, -dim_F)', '$H(dim_F, ' + i + ' + 1, dim_G)', '0']);
            vertices.push(['$G(dim_F, ' + i + ' + 1, -dim_F)', '$H(dim_F, ' + i + ' + 1, dim_G)', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [8 + 2 * i, 9 + 2 * i, 8 + 2 * i - 1, 7 + 2 * i - 1]});
            }
        }
        planes.push({materials: [0, 1], vertices: [8, 9, 7, 3]});

        //big left arc:
        for(let i = 0; i <= sectors_count; i++) {
            vertices.push(['$E(dim_F + dim_D, ' + i + ', -dim_F, $C())', '$F(dim_F + dim_D, ' + i + ', dim_G, $C())', '0']);
            vertices.push(['$E(dim_F + dim_D, ' + i + ', -dim_F, $C())', '$F(dim_F + dim_D, ' + i + ', dim_G, $C())', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [2 * sectors_count + 7 + 2 * i - 1, 2 * sectors_count + 8 + 2 * i - 1, 2 * sectors_count + 9 + 2 * i, 2 * sectors_count + 8 + 2 * i]});
            }
        }

        //left side A:
        const left_side_A = [3];
        for(let i = 0; i <= sectors_count; i++) {
            left_side_A.push(8 + 2 * sectors_count + 2 * i);
        }
        for(let i = sectors_count - 1; i >= 0; i--) {
            left_side_A.push(8 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: left_side_A});

        //side B:
        const left_side_B = [];
        for(let i = sectors_count; i >= 0; i--) {
            left_side_B.push(9 + 2 * sectors_count + 2 * i);
        }
        left_side_B.push(7);
        for(let i = 0; i < sectors_count; i++) {
            left_side_B.push(9 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: left_side_B});

        // finishing part 1
        vertices.push(['0', '0', '0']);
        vertices.push(['dim_A', '0', '0']);
        vertices.push(['0', '0', 'dim_B']);
        vertices.push(['dim_A', '0', 'dim_B']);
        vertices.push(['-3', '0', '-3']);
        vertices.push(['dim_A + 3', '0', '-3']);
        vertices.push(['dim_A + 3', '0', 'dim_B + 3']);
        vertices.push(['-3', '0', 'dim_B + 3']);

        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 11 + 4 * sectors_count, 1, 0]});
        planes.push({materials: [2], vertices: [12 + 4 * sectors_count, 13 + 4 * sectors_count, 5, 4]});
        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 12 + 4 * sectors_count, 4, 0]});
        planes.push({materials: [2], vertices: [11 + 4 * sectors_count, 13 + 4 * sectors_count, 5, 1]});

        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 14 + 4 * sectors_count, 15 + 4 * sectors_count, 11 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [11 + 4 * sectors_count, 15 + 4 * sectors_count, 16 + 4 * sectors_count, 13 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [13 + 4 * sectors_count, 16 + 4 * sectors_count, 17 + 4 * sectors_count, 12 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [12 + 4 * sectors_count, 17 + 4 * sectors_count, 14 + 4 * sectors_count, 10 + 4 * sectors_count]});

        // second end
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G', '0']);
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G', 'dim_B']);
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G + dim_D', '0']);
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G + dim_D', 'dim_B']);
        planes.push({materials: [0, 1], vertices: [18 + 4 * sectors_count, 19 + 4 * sectors_count, 7 + 2 * sectors_count, 6 + 2 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [8 + 4 * sectors_count, 9 + 4 * sectors_count, 21 + 4 * sectors_count, 20 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [6 + 2 * sectors_count, 8 + 4 * sectors_count, 20 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [7 + 2 * sectors_count, 19 + 4 * sectors_count, 21 + 4 * sectors_count, 9 + 4 * sectors_count]});

        // finishing part 2
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G', '0']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G', 'dim_B']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G + dim_D', '0']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G + dim_D', 'dim_B']);
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 23 + 4 * sectors_count, 19 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [24 + 4 * sectors_count, 25 + 4 * sectors_count, 21 + 4 * sectors_count, 20 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 18 + 4 * sectors_count, 20 + 4 * sectors_count, 24 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [23 + 4 * sectors_count, 19 + 4 * sectors_count, 21 + 4 * sectors_count, 25 + 4 * sectors_count]});

        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G - 3', '-3']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G - 3', 'dim_B + 3']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G + dim_D + 3', '-3']);
        vertices.push(['-dim_F - dim_I', 'dim_F + dim_G + dim_D + 3', 'dim_B + 3']);
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 26 + 4 * sectors_count, 28 + 4 * sectors_count, 24 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [24 + 4 * sectors_count, 28 + 4 * sectors_count, 29 + 4 * sectors_count, 25 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [25 + 4 * sectors_count, 29 + 4 * sectors_count, 27 + 4 * sectors_count, 23 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [23 + 4 * sectors_count, 27 + 4 * sectors_count, 26 + 4 * sectors_count, 22 + 4 * sectors_count]});


        //small right arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$G(-dim_E, ' + i + ' + 1, dim_A + dim_E)', '$H(dim_E, ' + i + ' + 1, dim_G)', '0']);
            vertices.push(['$G(-dim_E, ' + i + ' + 1, dim_A + dim_E)', '$H(dim_E, ' + i + ' + 1, dim_G)', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [29 + 4 * sectors_count + 2 * i - 1, 30 + 4 * sectors_count + 2 * i - 1, 31 + 4 * sectors_count + 2 * i, 30 + 4 * sectors_count + 2 * i]});
            }
        }
        planes.push({materials: [0, 1], vertices: [2, 6, 31 + 4 * sectors_count, 30 + 4 * sectors_count]});

        //big right arc:
        let triangle_node = vertices.length;
        for(let i = 0; i <= sectors_count; i++) {
            vertices.push(['$E(-dim_E - dim_C, ' + i + ', dim_A + dim_E, $D())', '$F(dim_E + dim_C, ' + i + ', dim_G, $D())', '0']);
            vertices.push(['$E(-dim_E - dim_C, ' + i + ', dim_A + dim_E, $D())', '$F(dim_E + dim_C, ' + i + ', dim_G, $D())', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [6 * sectors_count + 30 + 2 * i, 6 * sectors_count + 31 + 2 * i, 6 * sectors_count + 30 + 2 * i - 1, 6 * sectors_count + 29 + 2 * i - 1]});
            }
        }

        //right side A:
        const right_side_A = [];
        for(let i = sectors_count; i >= 0; i--) {
            right_side_A.push(30 + 6 * sectors_count + 2 * i);
        }
        right_side_A.push(6);
        for(let i = 0; i < sectors_count; i++) {
            right_side_A.push(30 + 4 * sectors_count + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: right_side_A});

        //right side B:
        const right_side_B = [6];
        for(let i = 0; i < sectors_count; i++) {
            right_side_B.push(31 + 4 * sectors_count + 2 * i);
        }
        for(let i = sectors_count; i >= 0; i--) {
            right_side_B.push(31 + 6 * sectors_count + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: right_side_B});

        // third end
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G', '0']);
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G', 'dim_B']);
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G + dim_C', '0']);
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G + dim_C', 'dim_B']);
        planes.push({materials: [0, 1], vertices: [28 + 6 * sectors_count, 29 + 6 * sectors_count, 33 + 8 * sectors_count, 32 + 8 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [34 + 8 * sectors_count, 35 + 8 * sectors_count, 31 + 8 * sectors_count, 30 + 8 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [32 + 8 * sectors_count, 34 + 8 * sectors_count, 30 + 8 * sectors_count, 28 + 6 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [29 + 6 * sectors_count, 31 + 8 * sectors_count, 35 + 8 * sectors_count, 33 + 8 * sectors_count]});

        // finishing part 3
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G', '0']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G', 'dim_B']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G + dim_C', '0']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G + dim_C', 'dim_B']);
        planes.push({materials: [2], vertices: [32 + 8 * sectors_count, 33 + 8 * sectors_count, 37 + 8 * sectors_count, 36 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [34 + 8 * sectors_count, 35 + 8 * sectors_count, 39 + 8 * sectors_count, 38 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [32 + 8 * sectors_count, 36 + 8 * sectors_count, 38 + 8 * sectors_count, 34 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [33 + 8 * sectors_count, 37 + 8 * sectors_count, 39 + 8 * sectors_count, 35 + 8 * sectors_count]});

        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G - 3', '-3']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G - 3', 'dim_B + 3']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G + dim_C + 3', '-3']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_E + dim_G + dim_C + 3', 'dim_B + 3']);
        planes.push({materials: [2], vertices: [36 + 8 * sectors_count, 40 + 8 * sectors_count, 42 + 8 * sectors_count, 38 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [38 + 8 * sectors_count, 42 + 8 * sectors_count, 43 + 8 * sectors_count, 39 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [39 + 8 * sectors_count, 43 + 8 * sectors_count, 41 + 8 * sectors_count, 37 + 8 * sectors_count]});
        planes.push({materials: [2], vertices: [37 + 8 * sectors_count, 41 + 8 * sectors_count, 40 + 8 * sectors_count, 36 + 8 * sectors_count]});


        // arrows
        // A 44+8*sectors_count-45+8*sectors_count + 4-5
        vertices.push(['0', '3', 'dim_B + 10']);
        vertices.push(['dim_A', '3', 'dim_B + 10']);

        // B 46+8*sectors_count-47+8*sectors_count + 1-5
        vertices.push(['dim_A + 10', '3', '0']);
        vertices.push(['dim_A + 10', '3', 'dim_B']);

        // C 48+8*sectors_count-49+8*sectors_count + 33+8*sectors_count - 35+8*sectors_count
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E + dim_H - 3', 'dim_E + dim_G + dim_C', 'dim_B + 10']);

        // C 50+8*sectors_count-51+8*sectors_count + 20+8*sectors_count - 22+8*sectors_count
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G', 'dim_B + 10']);
        vertices.push(['-dim_F - dim_I + 3', 'dim_F + dim_G + dim_D', 'dim_B + 10']);

        // E 52+8*sectors_count-54+8*sectors_count + 29+5*sectors_count
        vertices.push(['dim_A + dim_E', 'dim_G', 'dim_B']);
        vertices.push(['dim_A + dim_E', 'dim_G', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E - dim_E * Math.cos(Math.PI / 180 * 45)', 'dim_G + dim_E * Math.sin(Math.PI / 180 * 45)', 'dim_B + 10']);

        // F 55+8*sectors_count-57+8*sectors_count + 7+sectors_count
        vertices.push(['-dim_F', 'dim_G', 'dim_B']);
        vertices.push(['-dim_F', 'dim_G', 'dim_B + 10']);
        vertices.push(['-dim_F + dim_F * Math.cos(Math.PI / 180 * 45)', 'dim_G + dim_F * Math.sin(Math.PI / 180 * 45)', 'dim_B + 10']);

        // G 58+8*sectors_count-60+8*sectors_count + 7
        vertices.push(['0', '0', 'dim_B + 3']);
        vertices.push(['0', '0', 'dim_B + 10']);
        vertices.push(['0', 'dim_G', 'dim_B + 10']);

        // H 61+8*sectors_count-63+8*sectors_count + 29+6*sectors_count
        vertices.push(['dim_A + dim_E + dim_H', 'dim_G + dim_E', 'dim_B + 3']);
        vertices.push(['dim_A + dim_E + dim_H', 'dim_G + dim_E', 'dim_B + 10']);
        vertices.push(['dim_A + dim_E', 'dim_G + dim_E', 'dim_B + 10']);

        // I 64+8*sectors_count-66+8*sectors_count + 7+2*sectors_count
        vertices.push(['-dim_F - dim_I', 'dim_G + dim_F', 'dim_B + 3']);
        vertices.push(['-dim_F - dim_I', 'dim_G + dim_F', 'dim_B + 10']);
        vertices.push(['-dim_F', 'dim_G + dim_F', 'dim_B + 10']);

        //traingles:
        //for some reason this does not work: planes.push({materials: [0, 1], vertices: [2, triangle_node, 3]});
        // the triangle mus be divided into 2 smaller triangles, so add an extra vertex (to be precise: 2 vertices, for each side one)
        vertices.push(['$A()', 'dim_G', '0']);
        vertices.push(['$A()', 'dim_G', 'dim_B']);
        planes.push({materials: [0, 1], vertices: [triangle_node, vertices.length - 2, 2]});
        planes.push({materials: [0, 1], vertices: [3, vertices.length - 2, triangle_node]});
        planes.push({materials: [0, 1], vertices: [triangle_node + 1, vertices.length - 1, 7]});
        planes.push({materials: [0, 1], vertices: [6, vertices.length - 1, triangle_node + 1]});

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'G': empty_vals['G'] / 10,
            'H': empty_vals['H'] / 10,
            'I': empty_vals['I'] / 10
        };

        const arrows = {
            'A': [4, 44 + 8 * sectors_count, 45 + 8 * sectors_count, 5],
            'B': [1, 46 + 8 * sectors_count, 47 + 8 * sectors_count, 5],
            'C': [33 + 8 * sectors_count, 48 + 8 * sectors_count, 49 + 8 * sectors_count, 35 + 8 * sectors_count],
            'D': [19 + 4 * sectors_count, 50 + 8 * sectors_count, 51 + 8 * sectors_count, 21 + 4 * sectors_count],
            'E': [52 + 8 * sectors_count, 53 + 8 * sectors_count, 54 + 8 * sectors_count, 29 + 5 * sectors_count],
            'F': [55 + 8 * sectors_count, 56 + 8 * sectors_count, 57 + 8 * sectors_count, 7 + sectors_count],
            'G': [58 + 8 * sectors_count, 59 + 8 * sectors_count, 60 + 8 * sectors_count, 7],
            'H': [61 + 8 * sectors_count, 62 + 8 * sectors_count, 63 + 8 * sectors_count, 29 + 6 * sectors_count],
            'I': [64 + 8 * sectors_count, 65 + 8 * sectors_count, 66 + 8 * sectors_count, 7 + 2 * sectors_count],
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Trójnik orłowy';
        //this.cache.print();
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];

        if(c + d < a) {
            return [false, 1];
        } else if(c > a) {
            return [false, 2];
        } else if(d > a) {
            return [false, 3];
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Suma wymiarów C i D powinna być większa lub równa wymiarowi A. Zwiększ wymiary C i D lub zmniejsz wymiar A.";
        } else if(error_code == 2) {
            return "Wymiar C powinien być mniejszy lub równy od wymiaru A. Zmniejsz wymiar C lub powiększ wymiar A.";
        } else if(error_code == 3) {
            return "Wymiar D powinien być mniejszy lub równy od wymiaru A. Zmniejsz wymiar C lub powiększ wymiar A.";
        }
        return "";
    }
}