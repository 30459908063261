import { Model } from "../model";
import { Cache } from "../cache";

export class CzwornikZOdejsciemProstokatnym extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            // kanał 0 - 31
            // kanał ramka dolna
            ['-3', '0', '-3'],
            ['dim_B + 3', '0', '-3'],
            ['dim_B + 3', '0', 'dim_A + 3'],
            ['-3', '0', 'dim_A + 3'],

            ['0', '0', '0'],
            ['dim_B', '0', '0'],
            ['dim_B', '0', 'dim_A'],
            ['0', '0', 'dim_A'],

            ['0', '3', '0'],
            ['dim_B', '3', '0'],
            ['dim_B', '3', 'dim_A'],
            ['0', '3', 'dim_A'],

            // kanał ramka górna
            ['0', 'dim_L - 3', '0'],
            ['dim_B', 'dim_L - 3', '0'],
            ['dim_B', 'dim_L - 3', 'dim_A'],
            ['0', 'dim_L - 3', 'dim_A'],

            ['0', 'dim_L', '0'],
            ['dim_B', 'dim_L', '0'],
            ['dim_B', 'dim_L', 'dim_A'],
            ['0', 'dim_L', 'dim_A'],

            ['-3', 'dim_L', '-3'],
            ['dim_B + 3', 'dim_L', '-3'],
            ['dim_B + 3', 'dim_L', 'dim_A + 3'],
            ['-3', 'dim_L', 'dim_A + 3'],

            // otwór #1 CxD 24 - 31
            ['dim_B', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],
            ['dim_B', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],

            ['dim_B + dim_h - 3', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h - 3', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h - 3', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],
            ['dim_B + dim_h - 3', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],

            // ramka otworu #1 CxD 32 - 39
            ['dim_B + dim_h', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],
            ['dim_B + dim_h', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1 - dim_C / 2'],

            ['dim_B + dim_h', 'dim_f1 - dim_D / 2 - 3', 'dim_A - dim_e1 + dim_C / 2 + 3'],
            ['dim_B + dim_h', 'dim_f1 + dim_D / 2 + 3', 'dim_A - dim_e1 + dim_C / 2 + 3'],
            ['dim_B + dim_h', 'dim_f1 + dim_D / 2 + 3', 'dim_A - dim_e1 - dim_C / 2 - 3'],
            ['dim_B + dim_h', 'dim_f1 - dim_D / 2 - 3', 'dim_A - dim_e1 - dim_C / 2 - 3'],

            // otwór #2 ExF 40 - 47
            ['0', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['0', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['0', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],
            ['0', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],

            ['3 - dim_h', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['3 - dim_h', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['3 - dim_h', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],
            ['3 - dim_h', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],

            // ramka otworu #2 ExF 48 - 55
            ['-dim_h', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['-dim_h', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 + dim_E / 2'],
            ['-dim_h', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],
            ['-dim_h', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2 - dim_E / 2'],

            ['-dim_h', 'dim_f2 - dim_F / 2 - 3', 'dim_A - dim_e2 + dim_E / 2 + 3'],
            ['-dim_h', 'dim_f2 + dim_F / 2 + 3', 'dim_A - dim_e2 + dim_E / 2 + 3'],
            ['-dim_h', 'dim_f2 + dim_F / 2 + 3', 'dim_A - dim_e2 - dim_E / 2 - 3'],
            ['-dim_h', 'dim_f2 - dim_F / 2 - 3', 'dim_A - dim_e2 - dim_E / 2 - 3'],

            // arrows
            // A 56-57
            ['dim_B + 10', '3', '0'],
            ['dim_B + 10', '3', 'dim_A'],

            // B 58-59
            ['0', '3', 'dim_A + 10'],
            ['dim_B', '3', 'dim_A + 10'],

            // C 60-63
            ['dim_B + dim_h', 'dim_f1', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h + 10', 'dim_f1', 'dim_A - dim_e1 + dim_C / 2'],
            ['dim_B + dim_h + 10', 'dim_f1', 'dim_A - dim_e1 - dim_C / 2'],
            ['dim_B + dim_h', 'dim_f1', 'dim_A - dim_e1 - dim_C / 2'],

            // D 64-67
            ['dim_B + dim_h', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1'],
            ['dim_B + dim_h + 10', 'dim_f1 - dim_D / 2', 'dim_A - dim_e1'],
            ['dim_B + dim_h + 10', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1'],
            ['dim_B + dim_h', 'dim_f1 + dim_D / 2', 'dim_A - dim_e1'],

            // E 68-71
            ['-dim_h', 'dim_f2', 'dim_A - dim_e2 + dim_E / 2'],
            ['-dim_h - 10', 'dim_f2', 'dim_A - dim_e2 + dim_E / 2'],
            ['-dim_h - 10', 'dim_f2', 'dim_A - dim_e2 - dim_E / 2'],
            ['-dim_h', 'dim_f2', 'dim_A - dim_e2 - dim_E / 2'],

            // F 72-75
            ['-dim_h', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2'],
            ['-dim_h - 10', 'dim_f2 - dim_F / 2', 'dim_A - dim_e2'],
            ['-dim_h - 10', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2'],
            ['-dim_h', 'dim_f2 + dim_F / 2', 'dim_A - dim_e2'],

            // L 76-79
            ['dim_B', 'dim_L', 'dim_A + 3'],
            ['dim_B', 'dim_L', 'dim_A + 10'],
            ['dim_B', '0', 'dim_A + 10'],
            ['dim_B', '0', 'dim_A + 3'],

            // common e1 & f1 80-81
            ['dim_B + dim_h', 'dim_f1', 'dim_A - dim_e1'],
            ['dim_B + dim_h + 10', 'dim_f1', 'dim_A - dim_e1'],

            // e1 82-83
            ['dim_B + dim_h + 10', 'dim_f1', 'dim_A'],
            ['dim_B', 'dim_f1', 'dim_A'],

            // f1 84-85
            ['dim_B + dim_h + 10', '0', 'dim_A - dim_e1'],
            ['dim_B + 3', '0', 'dim_A - dim_e1'],

            // common e2 & f2 86-87
            ['-dim_h', 'dim_f2', 'dim_A - dim_e2'],
            ['-dim_h - 10', 'dim_f2', 'dim_A - dim_e2'],

            // e2 88-89
            ['-dim_h - 10', 'dim_f2', 'dim_A'],
            ['0', 'dim_f2', 'dim_A'],

            // f2 90-91
            ['-dim_h - 10', '0', 'dim_A - dim_e2'],
            ['-3', '0', 'dim_A - dim_e2'],

            // h 92-94
            ['dim_B + dim_h', 'dim_f1', 'dim_A - dim_e1 + dim_C / 2 + 3'],
            ['dim_B + dim_h', 'dim_f1', 'dim_A + 10'],
            ['dim_B', 'dim_f1', 'dim_A + 10']
        ];

        const planes = [
            // kanał - ramka dół
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            // kanał - ramka góra
            {materials: [2], vertices: [12, 13, 17, 16]},
            {materials: [2], vertices: [13, 14, 18, 17]},
            {materials: [2], vertices: [14, 15, 19, 18]},
            {materials: [2], vertices: [15, 12, 16, 19]},

            {materials: [2], vertices: [16, 17, 21, 20]},
            {materials: [2], vertices: [17, 18, 22, 21]},
            {materials: [2], vertices: [18, 19, 23, 22]},
            {materials: [2], vertices: [19, 16, 20, 23]},

            // kanał - rura
            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            // otwór CxD
            {materials: [0, 1], vertices: [29, 28, 24, 25]},
            {materials: [0, 1], vertices: [30, 29, 25, 26]},
            {materials: [0, 1], vertices: [31, 30, 26, 27]},
            {materials: [0, 1], vertices: [28, 31, 27, 24]},

            // otwór CxD ramka
            {materials: [2], vertices: [29, 28, 32, 33]},
            {materials: [2], vertices: [30, 29, 33, 34]},
            {materials: [2], vertices: [31, 30, 34, 35]},
            {materials: [2], vertices: [28, 31, 35, 32]},

            {materials: [2], vertices: [33, 32, 36, 37]},
            {materials: [2], vertices: [34, 33, 37, 38]},
            {materials: [2], vertices: [35, 34, 38, 39]},
            {materials: [2], vertices: [32, 35, 39, 36]},

            // otwór ExF
            {materials: [0, 1], vertices: [41, 40, 44, 45]},
            {materials: [0, 1], vertices: [42, 41, 45, 46]},
            {materials: [0, 1], vertices: [43, 42, 46, 47]},
            {materials: [0, 1], vertices: [40, 43, 47, 44]},

            // otwór ExF ramka
            {materials: [2], vertices: [45, 44, 48, 49]},
            {materials: [2], vertices: [46, 45, 49, 50]},
            {materials: [2], vertices: [47, 46, 50, 51]},
            {materials: [2], vertices: [44, 47, 51, 48]},

            {materials: [2], vertices: [49, 48, 52, 53]},
            {materials: [2], vertices: [50, 49, 53, 54]},
            {materials: [2], vertices: [51, 50, 54, 55]},
            {materials: [2], vertices: [48, 51, 55, 52]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'L': empty_vals['L'] / 10,
            'e1': empty_vals['e1'] / 10,
            'f1': empty_vals['f1'] / 10,
            'e2': empty_vals['e2'] / 10,
            'f2': empty_vals['f2'] / 10,
            'h': empty_vals['h'] / 10
        };

        const arrows = {
            'A': [9, 56, 57, 10],
            'B': [11, 58, 59, 10],
            'C': [60, 61, 62, 63],
            'D': [64, 65, 66, 67],
            'E': [68, 69, 70, 71],
            'F': [72, 73, 74, 75],
            'L': [76, 77, 78, 79],
            'e1': [80, 81, 82, 83],
            'f1': [80, 81, 84, 85],
            'e2': [86, 87, 88, 89],
            'f2': [86, 87, 90, 91],
            'h': [92, 93, 94, 83]
        };

        const holes = [
            {plane_index: '17', vertices: [24, 25, 26, 27]},
            {plane_index: '19', vertices: [40, 41, 42, 43]}
        ];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Czwórnik z odejściem prostokątnym';

        
    }

    isCorrect() {
        let A = this.dimensions['A'];
        let L = this.dimensions['L'];
        let C = this.dimensions['C'];
        let D = this.dimensions['D'];
        let E = this.dimensions['E'];
        let F = this.dimensions['F'];
        let e1 = this.dimensions['e1'];
        let f1 = this.dimensions['f1'];
        let e2 = this.dimensions['e2'];
        let f2 = this.dimensions['f2'];

        if(C > L - 6) {
            return [false, 1];
        }
        if(E > L - 6) {
            return [false, 2];
        }
        if(D > A) {
            return [false, 3];
        }
        if(F > A) {
            return [false, 4];
        }
        if(e1 > L - 3) {
            return [false, 5];
        }
        if(e1 < 3) {
            return [false, 6];
        }
        if(e2 > L - 3) {
            return [false, 7];
        }
        if(e2 < 3) {
            return [false, 8];
        }
        if(f1 > A) {
            return [false, 9];
        }
        if(f2 > A) {
            return [false, 10];
        }
        if(e1 - 3 < C / 2) {
            return [false, 11];
        }
        if(L - e1 - 3 < C / 2) {
            return [false, 12];
        }
        if(f1 < D / 2) {
            return [false, 13];
        }
        if(A - f1 < D / 2) {
            return [false, 14];
        }
        if(e2 - 3 < E / 2) {
            return [false, 15];
        }
        if(L - e2 - 3 < E / 2) {
            return [false, 16];
        }
        if(f2 < F / 2) {
            return [false, 17];
        }
        if(A - f2 < F / 2) {
            return [false, 18];
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Szerokość odejścia nie może być większa od długości czwórnika pomniejszonej o wykończenia. Zmniejsz wymiar C, lub zwiększ wymiar L.";
        }
        if(error_code == 2) {
            return "Szerokość odejścia nie może być większa od długości czwórnika pomniejszonej o wykończenia. Zmniejsz wymiar E, lub zwiększ wymiar L.";
        }
        if(error_code == 3) {
            return "Głębokość odejścia nie może być większa od głębokości czwórnika. Zmniejsz wymiar D, lub zwiększ wymiar A.";
        }
        if(error_code == 4) {
            return "Głębokość odejścia nie może być większa od głębokości czwórnika. Zmniejsz wymiar F, lub zwiększ wymiar A.";
        }
        if(error_code == 5) {
            return "Pozycja e1 odejścia nie może być większa od długości czwórnika pomniejszonej o wykończenie. Zmniejsz wymiar e1, lub zwiększ wymiar L.";
        }
        if(error_code == 6) {
            return "Pozycja e1 odejścia nie może być mniejsza od wykończenia. Zwiększ wymiar e1.";
        }
        if(error_code == 7) {
            return "Pozycja e2 odejścia nie może być większa od długości czwórnika pomniejszonej o wykończenie. Zmniejsz wymiar e2, lub zwiększ wymiar L.";
        }
        if(error_code == 8) {
            return "Pozycja e2 odejścia nie może być mniejsza od wykończenia. Zwiększ wymiar e2.";
        }
        if(error_code == 9) {
            return "Pozycja f1 odejścia nie może być większa od głębokości czwórnika. Zmniejsz wymiar f1, lub zwiększ wymiar A.";
        }
        if(error_code == 10) {
            return "Pozycja f2 odejścia nie może być większa od głębokości czwórnika. Zmniejsz wymiar f2, lub zwiększ wymiar A.";
        }
        if(error_code == 11) {
            return "Pozycja e1 odejścia pomniejszona o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zmniejsz wymiar C, lub zwiększ wymiar e1.";
        }
        if(error_code == 12) {
            return "Długość czwórnika pomniejszona o pozycję e1 odejścia i o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zwiększ wymiar L, lub zmniejsz jeden z wymiarów e1 i C.";
        }
        if(error_code == 13) {
            return "Pozycja f1 odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz wymiar D, lub zwiększ wymiar f1.";
        }
        if(error_code == 14) {
            return "Głębokość czwórnika pomniejszona o pozycję f1 odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz jeden z wymiarów A i D, lub zmniejsz wymiar f1.";
        }
        if(error_code == 15) {
            return "Pozycja e2 odejścia pomniejszona o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zmniejsz wymiar E, lub zwiększ wymiar e2.";
        }
        if(error_code == 16) {
            return "Długość czwórnika pomniejszona o pozycję e2 odejścia i o wykończenie nie może być mniejsza od połowy szerokości odejścia. Zwiększ wymiar L, lub zmniejsz jeden z wymiarów e2 i E.";
        }
        if(error_code == 17) {
            return "Pozycja f2 odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz wymiar F, lub zwiększ wymiar f2.";
        }
        if(error_code == 18) {
            return "Głębokość czwórnika pomniejszona o pozycję f2 odejścia nie może być mniejsza od połowy głębokości odejścia. Zmniejsz jeden z wymiarów A i F, lub zmniejsz wymiar f2.";
        }

        return "";
    }
}