import { CustomShape } from "../../shapes/models/custom-shape";
import { Row } from "./row";
import { Shape } from "../../shapes/models/shape";
import { Customer } from "./customer";

export class Inquiry {
    id: number;
    number: string;
    key: string;
    user_id?: number;
    status: {
        id: number;
        name: string;
        logic_name: string;
    };
    print_parts?: number;
    rows: Row[];
    shapes: Shape[];
    custom_shapes: CustomShape[];
    customer?: Customer;
    expected_delivery: string;
    is_transport_expected: boolean;
    is_transport_included: boolean;
    registered: string;
    
    delivery_city: string;
    delivery_street: string;
    delivery_zip_code: string;
    
    transport_distance: number;
    transport_price: number;
    transport_price_per_km: number;

    validity_date?: string;
    delivery_date?: string;

    static getShape(shapeId: number, inquiry: Inquiry): Shape {
        const shape: Shape = inquiry.shapes.find(
            (shape: Shape) => shape.id == shapeId
        );

        return shape;
    }

    static getCustomShape(customShapeId: number, inquiry: Inquiry): CustomShape {
        const customShape: CustomShape = inquiry.custom_shapes.find(
            (customShape: CustomShape) => customShape.id == customShapeId
        );

        return customShape;
    }
}