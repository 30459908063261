import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ParamComponent } from '../param/param.component';

@Component({
    selector: 'app-comment-param',
    templateUrl: './comment-param.component.html',
    styleUrls: ['./comment-param.component.scss']
})
export class CommentParamComponent extends ParamComponent implements OnInit {

    @Input()
    commentFC: UntypedFormControl;

    @Input()
    formSubmitted: boolean;

    ngOnInit(): void { };

    public showError(): boolean {
        return (this.commentFC.dirty || this.formSubmitted) && this.commentFC.invalid;
    }

    public getError(): string {
        return "Sprawdź wprowadzone dane.";
    }

    onFocus() {
        this.focus.emit(null);
    }

}
