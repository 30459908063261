<div [ngClass]="getClasses()" class="flex flex-wrap mb-4 items-center">
    <div class="param-left-col text-right p-2 justify-end">
        <label>
            Liczba sztuk:
        </label>
    </div>
    <div class="param-right-col">
        <!--
        <input 
            style="width: 6rem;"
            class="param-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300" 
            type="number" 
            [formControl]="countFC"
            [ngClass]="{'border-red-600': showError()}"
        >
        -->
        <int-input (focus)="onFocus()" [error]="showError()" [formControl]="countFC"></int-input>
        szt.
    </div>
    <div class="param-left-col"></div>
    <div *ngIf="showError()" class="param-right-col mt-1 text-sm text-red-600">
        {{getError()}}
    </div>
</div>