import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'bl-base-button',
    templateUrl: './base-button.component.html'
})
export class BaseButtonComponent implements OnInit {

    @Output()
    btnClick: EventEmitter<any> = new EventEmitter<any>();
    
    @Input()
    locks: string[] = [];

    constructor() { }

    ngOnInit(): void { }

    getClasses(): string {
        if(this.locks.includes('pending')) {
            return "bl-button-pending bg-gray-400 cursor-default";
        } else {
            return "bg-blue-800 hover:bg-blue-900 cursor-pointer";
        }
    }

    onClick() {
        this.btnClick.emit();
    }

}
