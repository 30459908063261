import { Model } from "../model";
import { Cache } from "../cache";

export class WyrzutniaDachowaProstokatnaTypA extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const net_height = 35;
        const angle = 15;

        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B'],

            ['3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '0'],
            ['dim_A - 3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '3'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B - 3'],
            ['dim_A - 3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B'],
            ['3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), 'dim_B - 3'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3 - ' + net_height.toString(), '3'],

            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B'],

            ['3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '0'],
            ['dim_A - 3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '3'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B - 3'],
            ['dim_A - 3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B'],
            ['3', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', 'dim_B - 3'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3', '3'],

            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ')', '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ')', '0'],
            ['dim_A', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B'],
            ['0', 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B'],

            ['dim_A / 2', 'dim_C', 'dim_B / 2'],

            ['dim_A > dim_B ? -10 : -10 * dim_A / dim_B', 'dim_C - (10 + Math.max(dim_A, dim_B) / 2) * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B > dim_A ? -10 : -10 * dim_B / dim_A'],
            ['dim_A + (dim_A > dim_B ? 10 : 10 * dim_A / dim_B)', 'dim_C - (10 + Math.max(dim_A, dim_B) / 2) * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B > dim_A ? -10 : -10 * dim_B / dim_A'],
            ['dim_A + (dim_A > dim_B ? 10 : 10 * dim_A / dim_B)', 'dim_C - (10 + Math.max(dim_A, dim_B) / 2) * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B + (dim_B > dim_A ? 10 : 10 * dim_B / dim_A)'],
            ['dim_A > dim_B ? -10 : -10 * dim_A / dim_B', 'dim_C - (10 + Math.max(dim_A, dim_B) / 2) * Math.tan(Math.PI / 180 * ' + angle + ')', 'dim_B + (dim_B > dim_A ? 10 : 10 * dim_B / dim_A)'],

            // arrows
            // A 45-48
            ['0', '3', 'dim_B'],
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B'],

            // B 49-52
            ['dim_A', '3', '0'],
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],
            ['dim_A', '3', 'dim_B'],

            // C 53-56
            ['dim_A + 3', '0', 'dim_B / 2'],
            ['dim_A + 7 + 10', '0', 'dim_B / 2'],
            ['dim_A + 7 + 10', 'dim_C', 'dim_B / 2'],
            ['dim_A / 2', 'dim_C', 'dim_B / 2']


        ];


        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [16, 28, 24, 12]},
            {materials: [0, 1], vertices: [13, 25, 29, 17]},
            {materials: [0, 1], vertices: [18, 30, 25, 13]},
            {materials: [0, 1], vertices: [14, 26, 31, 19]},
            {materials: [0, 1], vertices: [20, 32, 26, 14]},
            {materials: [0, 1], vertices: [15, 27, 33, 21]},
            {materials: [0, 1], vertices: [22, 34, 27, 15]},
            {materials: [0, 1], vertices: [12, 24, 35, 23]},

            {materials: [0, 1], vertices: [24, 25, 37, 36]},
            {materials: [0, 1], vertices: [25, 26, 38, 37]},
            {materials: [0, 1], vertices: [26, 27, 39, 38]},
            {materials: [0, 1], vertices: [27, 24, 36, 39]},

            {materials: [0, 1], vertices: [41, 42, 40]},
            {materials: [0, 1], vertices: [42, 43, 40]},
            {materials: [0, 1], vertices: [43, 44, 40]},
            {materials: [0, 1], vertices: [44, 41, 40]}
        ];


        const delta = 3;
        const width = 0.3;
        let v = vertices.length;
        const holes = [];
        const functions = [
            {name: '$A', params: ['x'], expression: 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - ' + (3 + delta - width) + ' - x * ' + delta},
            {name: '$B', params: [], expression: 'dim_C - Math.max(dim_A, dim_B) / 2 * Math.tan(Math.PI / 180 * ' + angle + ') - 3'},
            {name: '$C', params: [], expression: '$B() - ' + net_height},
            {name: '$D', params: ['x'], expression: 'dim_A - 6 - ' + delta + ' > x * ' + delta},
            {name: '$E', params: ['x'], expression: 'dim_B - 6 - ' + delta + ' > x * ' + delta},
        ];
        for(let i = 0; i < net_height / delta - 1; i++) {
            vertices.push(['3', '$A(' + i + ')', '0']);
            vertices.push(['3', '$A(' + i + ') + ' + width, '0']);
            vertices.push(['3', '$A(' + i + ') + ' + width, width.toString()]);
            vertices.push(['3', '$A(' + i + ')', width.toString()]);

            vertices.push(['dim_A - 3', '$A(' + i + ')', '0']);
            vertices.push(['dim_A - 3', '$A(' + i + ') + ' + width, '0']);
            vertices.push(['dim_A - 3', '$A(' + i + ') + ' + width, width.toString()]);
            vertices.push(['dim_A - 3', '$A(' + i + ')', width.toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});
        }

        v = vertices.length;
        for(let i = 0; i < net_height / delta - 1; i++) {
            vertices.push(['3', '$A(' + i + ')', 'dim_B - ' + width.toString()]);
            vertices.push(['3', '$A(' + i + ') + ' + width, 'dim_B - ' + width.toString()]);
            vertices.push(['3', '$A(' + i + ') + ' + width, 'dim_B']);
            vertices.push(['3', '$A(' + i + ')', 'dim_B']);

            vertices.push(['dim_A - 3', '$A(' + i + ')', 'dim_B - ' + width.toString()]);
            vertices.push(['dim_A - 3', '$A(' + i + ') + ' + width, 'dim_B - ' + width.toString()]);
            vertices.push(['dim_A - 3', '$A(' + i + ') + ' + width, 'dim_B']);
            vertices.push(['dim_A - 3', '$A(' + i + ')', 'dim_B']);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});
        }

        v = vertices.length;
        for(let i = 0; i < net_height / delta - 1; i++) {
            vertices.push(['0', '$A(' + i + ')', '3']);
            vertices.push(['0', '$A(' + i + ') + ' + width, '3']);
            vertices.push([width.toString(), '$A(' + i + ') + ' + width, '3']);
            vertices.push([width.toString(), '$A(' + i + ')', '3']);

            vertices.push(['0', '$A(' + i + ')', 'dim_B - 3']);
            vertices.push(['0', '$A(' + i + ') + ' + width, 'dim_B - 3']);
            vertices.push([width.toString(), '$A(' + i + ') + ' + width, 'dim_B - 3']);
            vertices.push([width.toString(), '$A(' + i + ')', 'dim_B - 3']);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});
        }

        v = vertices.length;
        for(let i = 0; i < net_height / delta - 1; i++) {
            vertices.push(['dim_A - ' + width.toString(), '$A(' + i + ')', '3']);
            vertices.push(['dim_A - ' + width.toString(), '$A(' + i + ') + ' + width, '3']);
            vertices.push(['dim_A', '$A(' + i + ') + ' + width, '3']);
            vertices.push(['dim_A', '$A(' + i + ')', '3']);

            vertices.push(['dim_A - ' + width.toString(), '$A(' + i + ')', 'dim_B - 3']);
            vertices.push(['dim_A - ' + width.toString(), '$A(' + i + ') + ' + width, 'dim_B - 3']);
            vertices.push(['dim_A', '$A(' + i + ') + ' + width, 'dim_B - 3']);
            vertices.push(['dim_A', '$A(' + i + ')', 'dim_B - 3']);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});
        }

        v = vertices.length;
        let p = planes.length;
        for(let i = 0; i < 344 / delta - 1; i++) {
            vertices.push([(3 + delta - width + i * delta).toString(), '$B()', '0']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$B()', '0']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$B()', width.toString()]);
            vertices.push([(3 + delta - width + i * delta).toString(), '$B()', width.toString()]);

            vertices.push([(3 + delta - width + i * delta).toString(), '$C()', '0']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$C()', '0']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$C()', width.toString()]);
            vertices.push([(3 + delta - width + i * delta).toString(), '$C()', width.toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$D(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;
        for(let i = 0; i < 344 / delta - 1; i++) {
            vertices.push([(3 + delta - width + i * delta).toString(), '$B()', 'dim_B']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$B()', 'dim_B']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$B()', 'dim_B - ' + width]);
            vertices.push([(3 + delta - width + i * delta).toString(), '$B()', 'dim_B - ' + width]);

            vertices.push([(3 + delta - width + i * delta).toString(), '$C()', 'dim_B']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$C()', 'dim_B']);
            vertices.push([((3 + delta - width + i * delta) + width).toString(), '$C()', 'dim_B - ' + width]);
            vertices.push([(3 + delta - width + i * delta).toString(), '$C()', 'dim_B - ' + width]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$D(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$D(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;
        for(let i = 0; i < 344 / delta - 1; i++) {
            vertices.push(['0', '$B()', (3 + delta - width + i * delta).toString()]);
            vertices.push(['0', '$B()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push([width.toString(), '$B()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push([width.toString(), '$B()', (3 + delta - width + i * delta).toString()]);

            vertices.push(['0', '$C()', (3 + delta - width + i * delta).toString()]);
            vertices.push(['0', '$C()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push([width.toString(), '$C()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push([width.toString(), '$C()', (3 + delta - width + i * delta).toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$E(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;
        for(let i = 0; i < 344 / delta - 1; i++) {
            vertices.push(['dim_A', '$B()', (3 + delta - width + i * delta).toString()]);
            vertices.push(['dim_A', '$B()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push(['dim_A - ' + width, '$B()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push(['dim_A - ' + width, '$B()', (3 + delta - width + i * delta).toString()]);

            vertices.push(['dim_A', '$C()', (3 + delta - width + i * delta).toString()]);
            vertices.push(['dim_A', '$C()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push(['dim_A - ' + width, '$C()', ((3 + delta - width + i * delta) + width).toString()]);
            vertices.push(['dim_A - ' + width, '$C()', (3 + delta - width + i * delta).toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$E(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$E(' + i + ')'});
        }


        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10
        };

        const arrows = {
            'A': [45, 46, 47, 48],
            'B': [49, 50, 51, 52],
            'C': [53, 54, 55, 56]
        };

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Wyrzutnia dachowa prostokątna typ A';

        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        // this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        // this.cache.print();
    }
}