import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { VerticalFormFieldComponent } from './components/vertical-form-field/vertical-form-field.component';
import { DecimalInputComponent } from './components/decimal-input/decimal-input.component';
import { PricingItemComponent } from  './components/pricing-item/pricing-item.component';

@NgModule({
    declarations: [
        FormFieldComponent,
        VerticalFormFieldComponent,
        DecimalInputComponent,
        PricingItemComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FormFieldComponent,
        VerticalFormFieldComponent,
        DecimalInputComponent,
        PricingItemComponent
    ]
})
export class FormsModule { }
