import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { Message } from '../../models/message';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-confirm',
    template: `
    <div class="fixed flex z-50 bg-gray-700 opacity-60 inset-0"></div>

    <div class="modal fixed flex justify-center items-center inset-0 bg-black bg-opacity-75 p-4 z-50">
        <div class="bg-white komunikat rounded-lg max-w-xl overflow-hidden p-8 flex flex-col max-h-full">
            <h3 class="text-xl py-4 leading-6 font-medium text-gray-900 border-b">
                {{message.message}}
            </h3>
            <div class="py-4 mt-2 flex">
                <button 
                    (click)="onClose(action, params)" 
                    class="flex p-3 rounded-lg w-full justify-center bg-blue-800 hover:bg-blue-900 cursor-pointer mr-2" 
                    style="outline: none; min-width: 120px;"
                    type="button"
                >
                    <div class="flex items-center font-medium text-white">
                        Tak
                    </div>
                </button>
                <button 
                    (click)="onClose()"
                    class="flex p-3 rounded-lg w-full justify-center bg-gray-500 hover:bg-gray-600 cursor-pointer ml-2" 
                    style="outline: none; min-width: 120px;"
                    type="button"
                >
                    <div class="flex items-center font-medium text-white">
                        Nie
                    </div>
                </button>
            </div>
        </div>
    </div>
    `
})
export class ConfirmModalComponent extends ModalComponent implements OnInit {
    message: Message;
    
    ngOnInit(): void {
        this.message = this.data.message;
    }
}