import { Przepustnica } from "./przepustnica";
import { Cache } from "../cache";

export class PrzepustnicaWieloplaszczyznowa  extends Przepustnica {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const depth = '18';
        const vertices = [
            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],
            ['0', '0', depth + ' - 3'],
            ['dim_A', '0', depth + ' - 3'],
            ['dim_A', 'dim_B', depth + ' - 3'],
            ['0', 'dim_B', depth + ' - 3'],
            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],
            ['0', '0', depth],
            ['dim_A', '0', depth],
            ['dim_A', 'dim_B', depth],
            ['0', 'dim_B', depth],
            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],
            ['-3', '-3', depth],
            ['dim_A + 3', '-3', depth],
            ['dim_A + 3', 'dim_B + 3', depth],
            ['-3', 'dim_B + 3', depth],
            //arrow A
            ['0', 'dim_B / 2', depth],
            ['0', 'dim_B / 2', depth + ' + 10'],
            ['dim_A', 'dim_B / 2', depth + ' + 10'],
            ['dim_A', 'dim_B / 2', depth],
            //arrow B
            ['dim_A / 2', 'dim_B', depth],
            ['dim_A / 2', 'dim_B', depth + ' + 10'],
            ['dim_A / 2', '0', depth + ' + 10'],
            ['dim_A / 2', '0', depth]

        ];

        const planes = [
            {materials: [0, 1], vertices: [4, 5, 1, 0]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [6, 7, 3, 2]},
            {materials: [0, 1], vertices: [7, 4, 0, 3]},
            {materials: [2], vertices: [0, 1, 9, 8]},
            {materials: [2], vertices: [1, 2, 10, 9]},
            {materials: [2], vertices: [2, 3, 11, 10]},
            {materials: [2], vertices: [3, 0, 8, 11]},
            {materials: [2], vertices: [12, 13, 5, 4]},
            {materials: [2], vertices: [13, 14, 6, 5]},
            {materials: [2], vertices: [14, 15, 7, 6]},
            {materials: [2], vertices: [15, 12, 4, 7]},
            {materials: [2], vertices: [8, 16, 17, 9]},
            {materials: [2], vertices: [9, 17, 18, 10]},
            {materials: [2], vertices: [10, 18, 19, 11]},
            {materials: [2], vertices: [11, 19, 16, 8]},
            {materials: [2], vertices: [12, 20, 21, 13]},
            {materials: [2], vertices: [13, 21, 22, 14]},
            {materials: [2], vertices: [14, 22, 23, 15]},
            {materials: [2], vertices: [15, 23, 20, 12]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': default_vals['C']
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31]
        };

        const gear_radius = 5;
        const gear_radius_with_teeth = gear_radius + 0.25;
        const gear_diameter = 2 * gear_radius;
        const max_B = 350;

        const holes = [];
        const functions = [
            {name: '$A', params: ['x', 'y', 'z'], expression: 'Math.sin(x) * y + z'},
            {name: '$B', params: ['x', 'y', 'z'], expression: 'Math.cos(x) * y + z'},
            {name: '$C', params: ['x'], expression: 'x <= (dim_B - ' + gear_diameter + ') / 2'},
        ];
        super(vertices, planes, dimensions, arrows, holes, options);


        let distance = gear_diameter;
        let odd = true;
        this.drawGear('-2.5', 'dim_B / 2', Number(depth) / 2, gear_radius_with_teeth, '2');
        let v = this.vertices.length;
        this.vertices.push(['0', 'dim_B / 2 + ' + (gear_diameter / 4).toString(), (gear_diameter * (2 - Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString()]);
        this.vertices.push(['0', 'dim_B / 2 - ' + (gear_diameter / 4).toString(), (gear_diameter * (2 + Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString()]);
        this.vertices.push(['dim_A', 'dim_B / 2 - ' + (gear_diameter / 4).toString(), (gear_diameter * (2 + Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString()]);
        this.vertices.push(['dim_A', 'dim_B / 2 + ' + (gear_diameter / 4).toString(), (gear_diameter * (2 - Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString()]);
        this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});

        while(distance <= (max_B - gear_diameter) / 2)
        {
            let p1 = this.planes.length;
            this.drawGear('-2.5', 'dim_B / 2 + ' + distance, Number(depth) / 2, gear_radius_with_teeth, '2', odd);
            this.drawGear('-2.5', 'dim_B / 2 - ' + distance, Number(depth) / 2, gear_radius_with_teeth, '2', odd);

            v = this.vertices.length;
            const a1 = (odd ? distance - gear_diameter / 4 : distance + gear_diameter / 4).toString();
            const a2 = (odd ? distance + gear_diameter / 4 : distance - gear_diameter / 4).toString();
            const b1 = (gear_diameter * (2 - Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString();
            const b2 = (gear_diameter * (2 + Math.sqrt(3)) / 4 + ((Number(depth) - gear_diameter) / 2)).toString();
            this.vertices.push(['0', 'dim_B / 2 + ' + a1, b1]);
            this.vertices.push(['0', 'dim_B / 2 + ' + a2, b2]);
            this.vertices.push(['dim_A', 'dim_B / 2 + ' + a2, b2]);
            this.vertices.push(['dim_A', 'dim_B / 2 + ' + a1, b1]);
            this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});

            v = this.vertices.length;
            this.vertices.push(['0', 'dim_B / 2 - ' + a1, b1]);
            this.vertices.push(['0', 'dim_B / 2 - ' + a2, b2]);
            this.vertices.push(['dim_A', 'dim_B / 2 - ' + a2, b2]);
            this.vertices.push(['dim_A', 'dim_B / 2 - ' + a1, b1]);
            this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});

            let p2 = this.planes.length;
            for(let i = p1; i < p2; i++)
            {
                this.holes.push({plane_index: i.toString(), visible: '$C(' + distance + ')'});
            }

            distance += gear_diameter;
            odd = !odd;
        }

        v = this.vertices.length;
        this.vertices.push(['0', '0', (Number(depth) / 2).toString()]);
        this.vertices.push(['0', '(dim_B % ' + gear_diameter + ') / 2 + (Math.floor(dim_B / ' + gear_diameter + ') % 2 == 0 ? 5 : 0)', (Number(depth) / 2).toString()]);
        this.vertices.push(['dim_A', '(dim_B % ' + gear_diameter + ') / 2 + (Math.floor(dim_B / ' + gear_diameter + ') % 2 == 0 ? 5 : 0)', (Number(depth) / 2).toString()]);
        this.vertices.push(['dim_A', '0', (Number(depth) / 2).toString()]);
        this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});

        v = this.vertices.length;
        this.vertices.push(['0', 'dim_B', (Number(depth) / 2).toString()]);
        this.vertices.push(['0', 'dim_B - (dim_B % ' + gear_diameter + ') / 2 - (Math.floor(dim_B / ' + gear_diameter + ') % 2 == 0 ? 5 : 0)', (Number(depth) / 2).toString()]);
        this.vertices.push(['dim_A', 'dim_B - (dim_B % ' + gear_diameter + ') / 2 - (Math.floor(dim_B / ' + gear_diameter + ') % 2 == 0 ? 5 : 0)', (Number(depth) / 2).toString()]);
        this.vertices.push(['dim_A', 'dim_B', (Number(depth) / 2).toString()]);
        this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});

        let p1 = this.drawHandle('0', 'dim_B / 2', depth + ' / 2');
        let p2 = this.planes.length;
        for(let i = p1; i < p2; i++)
        {
            this.holes.push({plane_index: i.toString(), visible: 'dim_C == 0'});
        }

        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Przepustnica wielopłaszczyznowa';
    }



    drawGear(x, y, z, radius, width, odd = false)
    {
        const teeth_count = 20;
        const smaller_radius_ratio = 0.9;
        const small_radius = radius + ' * ' + smaller_radius_ratio;
        let v = this.vertices.length;

        const angle = 2 * Math.PI / teeth_count;
        let start_angle = angle;
        if(odd) {
            start_angle += angle / 2;
        }
        for(let i = 0; i < teeth_count; i++)
        {
            this.addToothVertices(x, y, z, angle, start_angle + angle * i, radius, small_radius);
            this.addToothVertices(x + ' + ' + width, y, z, angle, start_angle + angle * i, radius, small_radius);
        }
        this.connectTeeth(v, teeth_count);
    }

    addToothVertices(x, y, z, angle_1, angle_2, big_radius, small_radius)
    {
        let angle_a = angle_2 - angle_1 / 4;
        let angle_b = angle_2 - angle_1 / 12;
        let angle_c = angle_2 + angle_1 / 12;
        let angle_d = angle_2 + angle_1 / 4;
        this.vertices.push([x.toString(), '$A(' + angle_a + ', ' + small_radius + ', ' + y + ')', '$B(' + angle_a + ', ' + small_radius + ', ' + z + ')']);
        this.vertices.push([x.toString(), '$A(' + angle_b + ', ' + big_radius + ', ' + y + ')', '$B(' + angle_b + ', ' + big_radius + ', ' + z + ')']);
        this.vertices.push([x.toString(), '$A(' + angle_c + ', ' + big_radius + ', ' + y + ')', '$B(' + angle_c + ', ' + big_radius + ', ' + z + ')']);
        this.vertices.push([x.toString(), '$A(' + angle_d + ', ' + small_radius + ', ' + y + ')', '$B(' + angle_d + ', ' + small_radius + ', ' + z + ')']);
    }

    connectTeeth(v, teeth_count)
    {
        let vertices = [];
        for(let i = 0; i < teeth_count; i++)
        {
            vertices.push(v + 8 * i);
            vertices.push(v + 8 * i + 1);
            vertices.push(v + 8 * i + 2);
            vertices.push(v + 8 * i + 3);
        }
        this.planes.push({materials: [5], vertices: vertices});
        vertices = [];
        for(let i = 0; i < teeth_count; i++)
        {
            vertices.push(v + 4 + 8 * i);
            vertices.push(v + 4 + 8 * i + 1);
            vertices.push(v + 4 + 8 * i + 2);
            vertices.push(v + 4 + 8 * i + 3);
        }
        this.planes.push({materials: [5], vertices: vertices});

        for(let i = 0; i < teeth_count; i++)
        {
            this.planes.push({materials: [5], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            this.planes.push({materials: [5], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            this.planes.push({materials: [5], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            this.planes.push({materials: [5], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * (i % (teeth_count - 1)) + 12, v + 8 * (i % (teeth_count - 1)) + 8]});
        }

    }

    setDimensionValue(name, value) {
        if(name == 'C') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'C') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'C') {
            return this.options[name][this.last_correct_dimensions[name]];
        }
        return this.last_correct_dimensions[name] * 10;
    }
}