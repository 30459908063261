import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { ShapeTemplate } from "../../models/shape-template";
import { environment } from "projects/pdd-open-client/src/environments/environment";
import { Model } from "../../models/model";
import { KanalProstokatny } from "../../models/shape-models/kanal-prostokatny";
import { KolanoProstokatne } from "../../models/shape-models/kolano-prostokatne";
import { LukSymetryczny }  from "../../models/shape-models/luk-symetryczny";
import { LukSymetryczny10_45stopni }  from "../../models/shape-models/luk-symetryczny-10-45-stopni";
import { LukAsymetryczny }  from "../../models/shape-models/luk-asymetryczny";
import { RedukcjaSymetryczna }  from "../../models/shape-models/redukcja-symetryczna";
import { RedukcjaAsymetryczna }  from "../../models/shape-models/redukcja-asymetryczna";
import { TrojnikSymetryczny }  from "../../models/shape-models/trojnik-symetryczny";
import { CokolDachowy } from "../../models/shape-models/cokol-dachowy";
import { CzerpniaScienna } from "../../models/shape-models/czerpnia-scienna";
import { CzerpniaWyrzutniaOkragla } from "../../models/shape-models/czerpnia-wyrzutnia-okragla";
import { CzwornikZOdejsciemOkraglym } from "../../models/shape-models/czwornik-z-odejsciem-okraglym";
import { DyfuzorAsymetryczny } from "../../models/shape-models/dyfuzor-asymetryczny";
import { DyfuzorSymetryczny } from "../../models/shape-models/dyfuzor-symetryczny";
import { NakladkaProstokatna } from "../../models/shape-models/nakladka-prostokatna";
import { Odsadzka } from "../../models/shape-models/odsadzka";
import { PodstawaDachowaOkragla } from "../../models/shape-models/podstawa-dachowa-okragla";
import { PodstawaDachowaProstokatna } from "../../models/shape-models/podstawa-dachowa-prostokatna";
import { PrzepustnicaJednoplaszczyznowa } from "../../models/shape-models/przepustnica-jednoplaszczyznowa";
import { PrzepustnicaWieloplaszczyznowa } from "../../models/shape-models/przepustnica-wieloplaszczyznowa";
import { PrzylaczeElastyczneOkragle } from "../../models/shape-models/przylacze-elastyczne-okragle";
import { PrzylaczeElastyczneProstokatne } from "../../models/shape-models/przylacze-elastyczne-prostokatne";
import { RamkaZSiatka } from "../../models/shape-models/ramka-z-siatka";
import { SkrzynkaRozprezna } from "../../models/shape-models/skrzynka-rozprezna";
import { TlumikProstokatny } from "../../models/shape-models/tlumik-prostokatny";
import { TrojnikOrlowy } from "../../models/shape-models/trojnik-orlowy";
import { TrojnikSiodlowy } from "../../models/shape-models/trojnik-siodlowy";
import { TrojnikZOdejsciemOkraglym } from "../../models/shape-models/trojnik-z-odejsciem-okraglym";
import { WyrzutniaDachowaProstokatnaTypA } from "../../models/shape-models/wyrzutnia-dachowa-prostokatna-typ-a";
import { WyrzutniaDachowaProstokatnaTypB } from "../../models/shape-models/wyrzutnia-dachowa-prostokatna-typ-b";
import { KolanoSymetryczneProstokatne } from "../../models/shape-models/kolano-symetryczne-prostokatne";
import { ZaslepkaProstokatna } from "../../models/shape-models/zaslepka-prostokatna";
import { TrojnikRedukcyjnyZOdejsciemProstokatnym } from "../../models/shape-models/trojnik-redukcyjny-z-odejsciem-prostokatnym"

import { ShapesService } from "projects/pdd-open-client/src/app/services/shapes.service";
import { TrojnikRedukcyjnyZOdejsciemOkraglym } from "../../models/shape-models/trojnik-redukcyjny-z-odejsciem-okraglym"
import { CzwornikZOdejsciemProstokatnym } from "../../models/shape-models/czwornik-z-odejsciem-prostokatnym"

export class Cache {
    functions: any;
    vertices: any;
    holes: any;
    holes_index: any;

    static fromInternalData(vertices, holes, functions, all_dim_values) {
        let new_cache = new Cache();
        new_cache.functions = new Map();
        for(let i = 0; i < functions.length; i++) {
            new_cache.functions.set(functions[i].name, CachedFunction.fromExpression(functions[i].name, functions[i].params, functions[i].expression, new_cache.functions, all_dim_values));
        }
        new_cache.vertices = [];
        for(let i = 0; i < vertices.length; i++) {
            new_cache.vertices.push([CachedValue.fromExpression(vertices[i][0], new_cache.functions, all_dim_values),
                                    CachedValue.fromExpression(vertices[i][1], new_cache.functions, all_dim_values),
                                    CachedValue.fromExpression(vertices[i][2], new_cache.functions, all_dim_values)]);
        }
        new_cache.holes = [];
        new_cache.holes_index = new Map();
        for(let i = 0; i < holes.length; i++) {
            new_cache.holes.push({plane_index: CachedValue.fromExpression(holes[i].plane_index, new_cache.functions, all_dim_values),
                                 visible: CachedValue.fromExpression(holes[i].visible, new_cache.functions, all_dim_values),
                                  vertices: holes[i].vertices});
            if(new_cache.holes[i].plane_index.value !== undefined && (new_cache.holes[i].visible.value !== undefined || (new_cache.holes[i].vertices !== undefined && new_cache.holes[i].vertices.length > 2))) {
                new_cache.holes_index.set(new_cache.holes[i].plane_index.value, i);
            }
        }
        return new_cache;
    }

    static fromCacheFile(vertices, holes, functions, all_dim_values) {
        let new_cache = new Cache();
        new_cache.unserialize(vertices, holes, functions, all_dim_values);
        return new_cache;
    }

    update(changed_dim_names, all_dim_values) {
        for(let func of this.functions.values()) {
            func.update(changed_dim_names, all_dim_values);
        }
        for(let i = 0; i < this.vertices.length; i++) {
            for(let j = 0; j < 3; j++) {
                this.vertices[i][j].update(changed_dim_names, all_dim_values);
            }
        }
        for(let i = 0; i < this.holes.length; i++) {
            let old_plane_index = this.holes[i].plane_index.value;
            this.holes[i].plane_index.update(changed_dim_names, all_dim_values);
            this.holes[i].visible.update(changed_dim_names, all_dim_values);
            let new_plane_index = this.holes[i].plane_index.value;
            if(old_plane_index !== undefined) {
                this.holes_index.delete(old_plane_index);
            }
            if(new_plane_index !== undefined) {
                this.holes_index.set(new_plane_index, i);
            }
        }
    }

    getVertex(index) {
        return [this.vertices[index][0].value, this.vertices[index][1].value, this.vertices[index][2].value];
    }

    getHolePlaneIndex(index) {
        return this.holes[index].plane_index.value;
    }

    getHoleVisibility(index) {
        return this.holes[index].visible.value;
    }

    getHoleVertices(index) {
        return this.holes[index].vertices;
    }

    serialize() {
        let result = "export const vertices = `";
        for(let i = 0; i < this.vertices.length; i++) {
            for(let j = 0; j < 3; j++) {
                let x = this.vertices[i][j];
                for(const dim of x.dimensions) {
                    result += dim;
                }
                result += '#' + x.expression + '#' + x.value.toString() + '\n';
            }
        }

        result += "`;\n\nexport const holes = `";
        for(let i = 0; i < this.holes.length; i++) {
            let x = this.holes[i].plane_index;
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#' + x.expression + '#' + x.value.toString() + '\n';

            x = this.holes[i].visible;
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#' + x.expression + '#' + x.value.toString() + '\n';
            x = this.holes[i].vertices;
            if(x !== undefined) {
                for(let j = 0; j < x.length - 1; j++) {
                    result += (x[j].toString() + ',');
                }
                if(x.length > 0) {
                    result += x[x.length - 1].toString();
                }
            }
            result += '\n';
        }
        result += "`;\n\nexport const functions = `";
        for(let x of this.functions.values()) {
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#';
            for(let i = 0; i < x.params.length - 1; i++) {
                result += x.params[i] + ',';
            }
            if(x.params.length > 0) {
                result += x.params[x.params.length - 1];
            }
            result += '#' + x.expression + '#' + x.name + '\n';
        }
        result += '`;';
        return result;
    }

    unserialize(vertices, holes, functions, all_dim_values) {
        this.functions = new Map();
        let pos = 0;
        while(pos < functions.length) {
            let func = CachedFunction.createEmpty();
            [pos, func.dimensions] = this.unserializeDimensions(functions, pos);
            [pos, func.params] = this.unserializeParams(functions, pos);
            [pos, func.expression] = this.unserializeExpression(functions, pos);
            [pos, func.name] = this.unserializeName(functions, pos);
            this.functions.set(func.name, func);
            CachedFunction.registerFunction(func.name, func.params, func.assignValuesToDims(func.expression, all_dim_values));
        }

        this.vertices = [];
        pos = 0;
        while(pos < vertices.length) {
            let vals = [];
            for(let i = 0; i < 3; i++) {
                vals[i] = CachedValue.createEmpty();
                [pos, vals[i].dimensions] = this.unserializeDimensions(vertices, pos);
                [pos, vals[i].expression] = this.unserializeExpression(vertices, pos);
                [pos, vals[i].value] = this.unserializeValue(vertices, pos);
            }
            this.vertices.push(vals);
        }

        this.holes = [];
        this.holes_index = new Map();

        pos = 0;
        while(pos < holes.length) {
            let plane_index = CachedValue.createEmpty();
            [pos, plane_index.dimensions] = this.unserializeDimensions(holes, pos);
            [pos, plane_index.expression] = this.unserializeExpression(holes, pos);
            [pos, plane_index.value] = this.unserializeValue(holes, pos);
            let visible = CachedValue.createEmpty();
            [pos, visible.dimensions] = this.unserializeDimensions(holes, pos);
            [pos, visible.expression] = this.unserializeExpression(holes, pos);
            [pos, visible.value] = this.unserializeValue(holes, pos);
            let hole_vertices = [];
            while(pos < holes.length && holes[pos] != '\n') {
                let digits = "";
                while(pos < holes.length && holes[pos] != '\n' && holes[pos] != ',') {
                    digits += holes[pos];
                    pos++;
                }
                hole_vertices.push(parseFloat(digits));
                if(pos < holes.length && holes[pos] != '\n') {
                    pos++;
                }
            }
            this.holes.push({plane_index: plane_index, visible: visible, vertices: hole_vertices});
            if(plane_index.value !== undefined && (visible.value !== undefined || (hole_vertices.length > 2))) {
                this.holes_index.set(plane_index.value, this.holes.length - 1);
            }
            pos++
        }
    }

    unserializeName(data, pos) {
        let end = data.indexOf('\n', pos) + 1;
        return [end, data.substr(pos, end - pos - 1)];
    }

    unserializeParams(data, pos) {
        let params = [];
        while(data[pos] != '#') {
            let param = "";
            while(data[pos] != '#' && data[pos] != ',') {
                param += data[pos];
                pos++;
            }
            params.push(param);
            if(data[pos] == ',') {
                pos++;
            }
        }
        pos++;
        return [pos, params];
    }

    unserializeDimensions(data, pos) {
        let dimensions = new Set();
        while(data[pos] != '#') {
            let dimension = data[pos];
            if(pos + 1 < data.length) {
                let digit_candidate = data[pos + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    dimension += digit_candidate;
                    pos++;
                }
            }
            dimensions.add(dimension);
            pos++;
        }
        pos++;
        return [pos, dimensions];
    }

    unserializeExpression(data, pos) {
        let end = data.indexOf('#', pos) + 1;
        return [end, data.substr(pos, end - pos - 1)];
    }

    unserializeValue(data, pos) {
        let end = data.indexOf('\n', pos) + 1;
        if(end == 0) {
            return [data.length, parseFloat(data.substr(pos))];
        } else {
            return [end, parseFloat(data.substr(pos, end - pos - 1))];
        }
    }

    print() {
        console.log(this.serialize());
    }

    planeIsVisible(plane_index) {
        let hole_id = this.holes_index.get(plane_index);
        return (hole_id === undefined || this.holes[hole_id].visible.value);
    }

    getHolesForPlane(plane_index) {
        let hole_id = this.holes_index.get(plane_index);
        if(hole_id === undefined) {
            return [];
        } else {
            let hole_vertices = this.holes[hole_id].vertices;
            if(hole_vertices === undefined || hole_vertices.length <= 2) {
                return [];
            } else {
                return hole_vertices;
            }
        }
    }
}

export class CachedBase {
    assignValuesToDims(expression, all_dim_values) {
        let result = expression;
        let needle = '_';
        let index = result.indexOf(needle);
        while(index != -1) {
            let letter = result[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < result.length) {
                let digit_candidate = result[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let value = all_dim_values[letter + digit];
            result = result.replace(needle + letter + digit, String(value));
            index = result.indexOf(needle, index);
        }
        return result;
    }

    simplifyExpression(expression) {
        let result = "";
        let collected_dim = 0;
        for(let i = 0; i < expression.length; i++) {
            if(expression[i] != ' ') {
                if(expression[i] == 'd' && collected_dim == 0) {
                    collected_dim = 1;
                } else if(expression[i] == 'i' && collected_dim == 1) {
                    collected_dim = 2;
                } else if(expression[i] == 'm' && collected_dim == 2) {
                    collected_dim = 3;
                } else if(expression[i] == '_' && collected_dim == 3) {
                    result += '_';
                    collected_dim = 0;
                } else {
                    if(collected_dim >= 1) {
                        result += 'd';
                    }
                    if(collected_dim >= 2) {
                        result += 'i';
                    }
                    if(collected_dim == 3) {
                        result += 'm';
                    }
                    result += expression[i];
                    collected_dim = 0;
                }
            }
        }
        return result;
    }

    static getDimensionsOfFunctionsInExpression(expression, functions)
    {
        let result = new Set();
        let needle = '$';
        let index = expression.indexOf(needle);
        while(index != -1) {
            let letter = needle + expression[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < expression.length) {
                let digit_candidate = expression[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let func_name = letter + digit;
            let func = functions.get(func_name);
            if(func !== undefined) {
                for (let dimension of func.dimensions) {
                    result.add(dimension);
                }
            }
            index = expression.indexOf(needle, index + func_name.length);
        }
        return result;
    }
}

export class CachedFunction extends CachedBase {
    params: any;
    name: any;
    dimensions: any;
    expression: any;

    static fromExpression(name, params, expression, functions, all_dim_values) {
        let new_cached_function = new CachedFunction();
        new_cached_function.params = params;
        new_cached_function.name = name;
        new_cached_function.dimensions = new Set();
        let parsed_expression = expression;
        let needle = 'dim_';
        let index = parsed_expression.indexOf(needle);
        while(index != -1) {
            let letter = parsed_expression[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < parsed_expression.length) {
                let digit_candidate = parsed_expression[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let dim_index = letter + digit;
            new_cached_function.dimensions.add(dim_index);
            let dim_value = all_dim_values[dim_index];
            parsed_expression = parsed_expression.replace(needle + dim_index, String(dim_value));
            index = parsed_expression.indexOf(needle, index);
        }
        new_cached_function.expression = new_cached_function.simplifyExpression(expression);

        CachedFunction.registerFunction(name, params, parsed_expression);
        let extra_dimensions = CachedFunction.getDimensionsOfFunctionsInExpression(parsed_expression, functions);
        for(let extra_dimension of extra_dimensions) {
            new_cached_function.dimensions.add(extra_dimension);
        }
        return new_cached_function;
    }

    static createEmpty() {
        let new_cached_function = new CachedFunction();
        new_cached_function.params = [];
        new_cached_function.name = "";
        new_cached_function.dimensions = new Set();
        new_cached_function.expression = "";
        return new_cached_function;
    }

    static registerFunction(name, params, parsed_expression) {
        let params_list = "";
        for(let i = 0; i < params.length; i++) {
            params_list += "'" + params[i] + "',";
        }
        eval("window." + name + " = new Function(" + params_list + " 'return " + parsed_expression + "');");
    }

    update(changed_dim_names, all_dim_values) {
        let found = false;
        for(let i = 0; !found && i < changed_dim_names.length; i++) {
            found = this.dimensions.has(changed_dim_names[i]);
        }
        if(found) {
            CachedFunction.registerFunction(this.name, this.params, this.assignValuesToDims(this.expression, all_dim_values));
        }
    }

}

export class CachedValue extends CachedBase {
    dimensions: any;
    expression: any;
    value: any;

    static fromExpression(expression, functions, all_dim_values) {
        let new_cached_value = new CachedValue();
        new_cached_value.dimensions = new Set();
        if(expression === undefined || expression === "") {
            new_cached_value.expression = "1";
            new_cached_value.value = 1;
        } else {
            let parsed_expression = expression;
            let needle = 'dim_';
            let index = parsed_expression.indexOf(needle);
            while(index != -1) {
                let letter = parsed_expression[index + needle.length];
                let digit = "";
                if(index + needle.length + 1 < parsed_expression.length) {
                    let digit_candidate = parsed_expression[index + needle.length + 1];
                    if(digit_candidate >= '0' && digit_candidate <= '9') {
                        digit = digit_candidate;
                    }
                }
                let dim_index = letter + digit;
                new_cached_value.dimensions.add(dim_index);
                let dim_value = all_dim_values[dim_index];
                parsed_expression = parsed_expression.replace(needle + dim_index, String(dim_value));
                index = parsed_expression.indexOf(needle, index);
            }
            new_cached_value.expression = new_cached_value.simplifyExpression(expression);
            new_cached_value.value = eval(parsed_expression);
            if(new_cached_value.value === true) {
                new_cached_value.value = 1;
            } else if (new_cached_value.value === false) {
                new_cached_value.value = 0;
            }
        }
        let extra_dimensions = CachedFunction.getDimensionsOfFunctionsInExpression(new_cached_value.expression, functions);
        for(let extra_dimension of extra_dimensions) {
            new_cached_value.dimensions.add(extra_dimension);
        }
        return new_cached_value;
    }

    static createEmpty() {
        let new_cached_value = new CachedValue();
        new_cached_value.dimensions = new Set();
        new_cached_value.expression = "";
        new_cached_value.value = undefined;
        return new_cached_value;
    }

    update(changed_dim_names, all_dim_values) {
        let found = false;
        for(let i = 0; !found && i < changed_dim_names.length; i++) {
            found = this.dimensions.has(changed_dim_names[i]);
        }
        if(found) {
            this.value = eval(this.assignValuesToDims(this.expression, all_dim_values));
            if(this.value === true) {
                this.value = 1;
            } else if (this.value === false) {
                this.value = 0;
            }
        }
    }
}

@Component({
    selector: 'pdd-shape-viewer',
    template: `
        <div #rendererContainer id="renderContainer" class="shape-viewer-wrapper w-full sticky border text-gray-50 flex justify-center items-center relative">
            <canvas class="w-full" #rendererCanvas id="renderCanvas"></canvas>
            <div *ngIf="loading" class="w-full h-100 absolute flex justify-center align-center">
                <img class="block h-8 mx-auto my-24" alt="Trwa ładowanie" src="assets/icons/bars.svg">
            </div>
        </div>
    `
})
export class ShapeViewerComponent implements OnInit, AfterViewInit {
    @ViewChild('rendererContainer', {static: true})
    public rendererContainer: ElementRef<HTMLCanvasElement>;

    @ViewChild('rendererCanvas', {static: true})
    public rendererCanvas: ElementRef<HTMLCanvasElement>;

    @Input()
    shapeTemplate: ShapeTemplate;

    @Input()
    min_vals: {} = {};

    @Input()
    max_vals: {} = {};

    @Input()
    empty_vals: {} = {};
    
    @Input()
    default_vals: {} = {};

    @Input()
    options: {} = {};

    @Output()
    errorInfo = new EventEmitter<string>();

    model_algorithms: any;
    additional_dims: any;
    model_to_form_dims_mappings_config: any;
    form_to_model_dims_mappings_config: any;

    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private scene: THREE.Scene;
    private lights: THREE.light[];
    private shape: Model;
	
    private controls: any;
    public loading: boolean = false;

    constructor(private shapesService: ShapesService) { }

    ngOnInit(): void {
        console.log("ShapeViewerComponent - ngOnInit");
    }

    ngAfterViewInit():void {
        // console.log("____" + this.shapeTemplate.name);

        let classDefs = {
            'Cokół dachowy': [
                CokolDachowy,
                "Cokół dachowy"
            ],
            'Czerpnia ścienna': [
                CzerpniaScienna,
                "Czerpnia ścienna",
                "data_czerpnia_scienna"
            ],
            'Czerpnia wyrzutnia okrągła': [
                CzerpniaWyrzutniaOkragla,
                "Czerpnia wyrzutnia okrągła",
                "data_czerpnia_wyrzutnia_okragla"
            ],
            'Czwórnik z odejściem okrągłym': [
                CzwornikZOdejsciemOkraglym,
                "Czwórnik z odejściem okrągłym",
                "data_czwornik_z_odejsciem_okraglym"
            ],
            'Dyfuzor asymetryczny': [
                DyfuzorAsymetryczny,
                "Dyfuzor asymetryczny",
                "data_dyfuzor_asymetryczny"
            ],
            'Dyfuzor symetryczny': [
                DyfuzorSymetryczny,
                "Dyfuzor symetryczny",
                "data_dyfuzor_symetryczny"
            ],
            'Kanał': [
                KanalProstokatny,
                "Kanał"
            ],
            'Kolano prostokątne': [
                KolanoProstokatne,
                "Kolano prostokątne"
            ],
            'Łuk symetryczny': [
                LukSymetryczny,
                "Łuk symetryczny",
                "data_luk_symetryczny"
            ],
            'Łuk symetryczny 10-45 stopni': [
                LukSymetryczny10_45stopni,
                "Łuk symetryczny 10-45 stopni"
            ],
            'Łuk asymetryczny': [
                LukAsymetryczny,
                "Łuk asymetryczny",
                "data_luk_asymetryczny"
            ],
            'Nakładka prostokątna': [
                NakladkaProstokatna,
                "Nakładka prostokątna"
            ],
            'Odsadzka': [
                Odsadzka,
                "Odsadzka"
            ],
            'Podstawa dachowa okrągła': [
                PodstawaDachowaOkragla,
                "Podstawa dachowa okrągła",
                "data_podstawa_dachowa_okragla"
            ],
            'Podstawa dachowa prostokątna': [
                PodstawaDachowaProstokatna,
                "Podstawa dachowa prostokątna"
            ],
            'Przepustnica jednopłaszczyznowa': [
                PrzepustnicaJednoplaszczyznowa,
                "Przepustnica jednopłaszczyznowa"
            ],
            'Przepustnica wielopłaszczyznowa': [
                PrzepustnicaWieloplaszczyznowa,
                "Przepustnica wielopłaszczyznowa",
                "data_przepustnica_wieloplaszczyznowa"
            ],
            'Przyłącze elastyczne prostokątne': [
                PrzylaczeElastyczneProstokatne,
                "Przyłącze elastyczne prostokątne"
            ],
            'Przyłącze elastyczne okrągłe': [
                PrzylaczeElastyczneOkragle,
                "Przyłącze elastyczne okrągłe",
                "data_przylacze_okragle"
            ],
            'Ramka z siatką': [
                RamkaZSiatka,
                "Ramka z siatką",
                "data_ramka_z_siatka"
            ],
            'Redukcja symetryczna': [
                RedukcjaSymetryczna,
                "Redukcja symetryczna"
            ],
            'Redukcja asymetryczna': [
                RedukcjaAsymetryczna,
                "Redukcja asymetryczna"
            ],
            'Skrzynka rozprężna': [
                SkrzynkaRozprezna,
                "Skrzynka rozprężna",
                "data_skrzynka_rozprezna"
            ],
            'Tłumik prostokątny': [
                TlumikProstokatny,
                "Tłumik prostokątny"
            ],
            'Trójnik orłowy': [
                TrojnikOrlowy,
                "Trójnik orłowy",
                "data_trojnik_orlowy"
            ],
            'Trójnik siodłowy (sztucer)': [
                TrojnikSiodlowy,
                "Trójnik siodłowy (sztucer)",
                "data_trojnik_siodlowy"
            ],
            'Trójnik symetryczny': [
                TrojnikSymetryczny,
                "Trójnik symetryczny",
                "data_trojnik_symetryczny"
            ],
            'Trójnik z odejściem okrągłym': [
                TrojnikZOdejsciemOkraglym,
                "Trójnik z odejściem okrągłym",
                "data_trojnik_z_odejsciem_okraglym"
            ],
            'Wyrzutnia dachowa prostokątna typ A': [
                WyrzutniaDachowaProstokatnaTypA,
                "Wyrzutnia dachowa prostokątna typ A",
                'data_wyrzutnia_dachowa_prostokatna_typ_a'
            ],
            'Wyrzutnia dachowa prostokątna typ B': [
                WyrzutniaDachowaProstokatnaTypB,
                "Wyrzutnia dachowa prostokątna typ B"
            ],
            'Zaślepka prostokątna': [
                ZaslepkaProstokatna,
                "Zaślepka prostokątna"
            ],
            'Kolano symetryczne prostokątne': [
                KolanoSymetryczneProstokatne,
                "Kolano symetryczne prostokątne",
                "data_kolano_symetryczne_prostokatne"
            ],
            'Trójnik redukcyjny z odejściem prostokątnym': [
                TrojnikRedukcyjnyZOdejsciemProstokatnym,
                "Trójnik redukcyjny z odejściem prostokątnym",
                "data_trojnik_redukcyjny_z_odejsciem_prostokatnym"
            ],
            'Trójnik redukcyjny z odejściem okrągłym': [
                TrojnikRedukcyjnyZOdejsciemOkraglym,
                "Trójnik redukcyjny z odejściem okrągłym",
                //"data_trojnik_redukcyjny_z_odejsciem_okraglym"
            ],
            'Czwórnik z odejściem prostokątnym': [
                CzwornikZOdejsciemProstokatnym,
                "Czwórnik z odejściem prostokątnym",
                //"data_trojnik_redukcyjny_z_odejsciem_okraglym"
            ]
        };

        let classDef = classDefs[this.shapeTemplate.name];

        this.init(classDef);
    }

    init(classDef: any): any {

        if(classDef[2]) {
            
            setTimeout(() => {
                this.loading = true;
                this.shapesService.getShapeViewerData(`${classDef[2]}.json`).subscribe(
                    {
                        next: (res) => {
                            this.itnitIt(classDef, res);
                        },
                        error: (error) => {
                            console.error(error)
                        },
                        complete: () => {
                            
                        }
                    }
                )
            });
            
        } else {
            this.itnitIt(classDef, null);
        }

    }

    itnitIt(classDef: any, initData: any) {

        

        this.scene = new THREE.Scene();
	    this.scene.background = new THREE.Color('white');

        this.lights = [];
        for(let i = 0; i < 8; i++) {
            this.lights[i] = new THREE.PointLight( 0x777777, 1, 0 );
            this.scene.add(this.lights[i]);
        }

        //this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01, 2000);
        //this.camera = new THREE.PerspectiveCamera(70, 4/3, 0.01, 10);
        this.camera = new THREE.PerspectiveCamera(45, 4/3, 0.01, 2000);

        this.renderer = new THREE.WebGLRenderer(
            {
                canvas: this.rendererCanvas.nativeElement,
                antialias: true
            }
        );

        // const renderer = new THREE.WebGLRenderer();
        // renderer.setSize( window.innerWidth, window.innerHeight );
        // document.body.appendChild( renderer.domElement );

        this.renderer.setSize(this.rendererContainer.nativeElement.offsetWidth - 2,  (this.rendererContainer.nativeElement.offsetWidth - 2) * 3/4 );
        
        this.initConfigVals();
        this.shape = new classDef[0](this.empty_vals, this.default_vals, this.options, initData);
        
        this.addShapeToScene(this.scene, this.shape);
        // createForm(shape);
        // attachEventHandlers(shape);
        this.turnOff(this.shape.name, Object.keys(this.shape.dimensions));

        this.camera.position.z = 100;

        //console.log("classDef", classDef);

        if(classDef[0] == ZaslepkaProstokatna) {
            this.camera.position.y = 40;
        }

        if(classDef[0] == PrzepustnicaJednoplaszczyznowa) {
            this.camera.position.y = 20;
        }

        if(classDef[0] == TrojnikSiodlowy) {
            this.camera.position.y = 50;
            this.camera.position.x = 50;
            this.camera.position.z = 50;
        }
        
        if(classDef[0] == TrojnikOrlowy) {
            this.camera.position.y = 10;
            this.camera.position.x = 180;
            this.camera.position.z = 120;
        }

        if(classDef[0] == PodstawaDachowaOkragla || classDef[0] == PodstawaDachowaProstokatna) {
            this.camera.position.y = 120;
        }

        if(classDef[0] == WyrzutniaDachowaProstokatnaTypB) {
            this.camera.position.x = 90;
        }

        if(classDef[0] == KolanoSymetryczneProstokatne) {
            this.camera.position.x = 90;
        }


        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        
        this.centerCamera();
        this.positionLights(this.shape.inner_bounding_box);
        this.controls.update();

        this.animate();

        this.loading = false;
    }

    private initConfigVals() {
    }

    private addShapeToScene(scene:any, shape:any) {
        const [geometries, arrows] = shape.getGeometries();
        for(let i = 0; i < geometries.length; i++) {
            const {geometry, materials} = geometries[i];
            let mesh = new THREE.Mesh(geometry, shape.materials[materials[0]]);
            const plane = new THREE.Object3D();
            plane.add(mesh);
            for(let j = 1; j < materials.length; j++) {
                const otherSide = geometry.clone();
                mesh = new THREE.Mesh(otherSide, shape.materials[materials[j]]);
                plane.add(mesh);
            }
            plane.name = shape.name;
            scene.add(plane);
        }
        for(let i = 0; i < arrows.length; i++) {
            const {geometry, material, dim} = arrows[i];
            const mesh = new THREE.Mesh(geometry, material);
            mesh.name = shape.name + "_arrow_" + dim;
            scene.add(mesh);
        }
    }

    private removeShapeFromScene(scene, shape) {
        //we need 2 loops because we cannot remove inside of traversing
        const to_remove = [];
        let selected_dim = undefined;
        const name_len = shape.name.length;
        scene.traverse(function(child) {
            if(child.name.substr(0, name_len) === shape.name) {
                to_remove.push(child);
                if(child.name.substr(name_len, 7) === "_arrow_") {
                    if(child.visible) {
                        selected_dim = child.name.substr(name_len + 7, 1);
                    }
                }
            }
        });
        to_remove.forEach(function(object){
            scene.remove(object);
        });
        return selected_dim;
    }

    public turnAll() {
        this.turnOff(this.shape.name, Object.keys(this.shape.dimensions));
    }

    public turnOne(dim: string) {
        this.turnOn(this.shape.name, dim);
    }

    private turnOff(shape_name, dim) {
        if(Array.isArray(dim)) {
            dim.forEach(element => this.turnOff(shape_name, element));
        } else {
            return this.setState(shape_name, dim, 'invisible');
        }
    }

    private turnOn(shape_name, dim) {
        return this.setState(shape_name, dim, 'visible');
    }

    public resizeDim(dim, value) {
        //console.log("Resize dim:", dim, value);
    
        let shape = this.shape;
       //console.log("shape.dimensions",shape.dimensions);


        if(value === '' || value === undefined) {
            value = this.empty_vals[shape.name][dim];
        }
        if(value == shape.getDimensionValue(dim)) {
            return;
        }
        shape.setDimension(dim, value);
        let [is_correct, error_code] = shape.isCorrect();
        if(is_correct) {
            shape.saveCorrectDimensions();
            const selected_dim = this.removeShapeFromScene(this.scene, shape);
            this.addShapeToScene(this.scene, shape);
            this.turnOff(shape.name, Object.keys(shape.dimensions));
            if(selected_dim !== undefined) {
                this.turnOn(shape.name, selected_dim);
            }
            this.centerCamera(this.shape.inner_bounding_box, this.shape.outer_bounding_box);
            this.positionLights(shape.inner_bounding_box);
        } else {
            let message = 'Nieprawidłowe wymiary.<br/>' + shape.getErrorString(error_code) + '<br/>Kształt wyświetlany jest z ostanimi prawidłowymi wymiarami:<br/>';
            Object.keys(shape.dimensions).forEach(function(key) {
                message += key + ": " + shape.getLastCorrectDimensionValue(key) + "<br/>";
            });

            // console.log(message);
            this.errorInfo.emit(message);
        }
    }

    private setState(shape_name, dim, state) {
        const arrow_name = shape_name + '_arrow_' + dim;
        this.scene.traverse(function(child) {
            if(child.name.substr(0, arrow_name.length) === arrow_name) {
                if(state == 'visible') {
                    child.visible = true;
                } else {
                    child.visible = false;
                }
            }
        });
    }

    public centerCamera(inner_box = null, outer_box = null) {

        if(!inner_box) {
            inner_box = this.shape.inner_bounding_box;
        }

        if(!outer_box) {
            outer_box = this.shape.outer_bounding_box;
        }

        const boxSize = outer_box.getSize(new THREE.Vector3()).length();
        const boxCenter = inner_box.getCenter(new THREE.Vector3());

        // set the camera to frame the box
        this.frameArea(boxSize * 1.5, boxSize, boxCenter, this.camera);

        // update the Trackball controls to handle the new size
        this.controls.maxDistance = boxSize * 10;
        this.controls.target.copy(boxCenter);
        this.controls.update();
    }

    private frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
        const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
        const halfFovY = THREE.MathUtils.degToRad(camera.fov * .5);
        const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
        // compute a unit vector that points in the direction the camera is now
        // from the center of the box
        const direction = (new THREE.Vector3())
            .subVectors(camera.position, boxCenter)
            //.multiply(new THREE.Vector3(1, 0, 1))
            .normalize();

        // move the camera to a position distance units away from the center
        // in whatever direction the camera was from the center already
        camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

        // pick some near and far values for the frustum that
        // will contain the box.
        camera.near = boxSize / 100;
        camera.far = boxSize * 100;

        camera.updateProjectionMatrix();

        // point the camera to look at the center of the box
        camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    }

    private positionLights(box) {
        const d = 100;
        this.lights[0].position.set(box.min.x - d, box.min.y - d, box.min.z - d);
        this.lights[1].position.set(box.max.x + d, box.max.y + d, box.max.z + d);
        this.lights[2].position.set(box.min.x - d, box.min.y - d, box.max.z + d);
        this.lights[3].position.set(box.min.x - d, box.max.y + d, box.min.z - d);
        this.lights[4].position.set(box.max.x + d, box.min.y - d, box.min.z - d);
        this.lights[5].position.set(box.min.x - d, box.max.y + d, box.max.z + d);
        this.lights[6].position.set(box.max.x + d, box.min.y - d, box.max.z + d);
        this.lights[7].position.set(box.max.x + d, box.max.y + d, box.min.z - d);
    }

    private animate() {
        requestAnimationFrame(() => {this.animate()});
        this.renderer.render(this.scene, this.camera);
    };

}