import { Model } from "../model";
import { Cache } from "../cache";

export class PodstawaDachowaProstokatna extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_C', '0'],
            ['dim_A', 'dim_C', '0'],
            ['dim_A', 'dim_C', 'dim_B'],
            ['0', 'dim_C', 'dim_B'],

            ['-3', 'dim_C', '-3'],
            ['dim_A + 3', 'dim_C', '-3'],
            ['dim_A + 3', 'dim_C', 'dim_B + 3'],
            ['-3', 'dim_C', 'dim_B + 3'],

            ['-6', 'dim_C - 15', '-6'],
            ['dim_A + 6', 'dim_C - 15', '-6'],
            ['dim_A + 6', 'dim_C - 15', 'dim_B + 6'],
            ['-6', 'dim_C - 15', 'dim_B + 6'],

            ['-8', 'dim_C - 15', '-8'],
            ['dim_A + 8', 'dim_C - 15', '-8'],
            ['dim_A + 8', 'dim_C - 15', 'dim_B + 8'],
            ['-8', 'dim_C - 15', 'dim_B + 8'],

            ['-13', 'dim_C - 20', '-13'],
            ['dim_A + 13', 'dim_C - 20', '-13'],
            ['dim_A + 13', 'dim_C - 20', 'dim_B + 13'],
            ['-13', 'dim_C - 20', 'dim_B + 13'],

            ['0', 'dim_C - 15', '0'],
            ['dim_A', 'dim_C - 15', '0'],
            ['dim_A', 'dim_C - 15', 'dim_B'],
            ['0', 'dim_C - 15', 'dim_B'],

            // arrows
            // A 36-39
            ['0', 'dim_C', 'dim_B / 2'],
            ['0', 'dim_C + 10', 'dim_B / 2'],
            ['dim_A', 'dim_C + 10', 'dim_B / 2'],
            ['dim_A', 'dim_C', 'dim_B / 2'],

            // B
            ['dim_A / 2', 'dim_C', '0'],
            ['dim_A / 2', 'dim_C + 10', '0'],
            ['dim_A / 2', 'dim_C + 10', 'dim_B'],
            ['dim_A / 2', 'dim_C', 'dim_B'],

            // C
            ['dim_A + 3', 'dim_C', 'dim_B / 2'],
            ['dim_A + 23', 'dim_C', 'dim_B / 2'],
            ['dim_A + 23', '0', 'dim_B / 2'],
            ['dim_A + 3', '0', 'dim_B / 2']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [4], vertices: [12, 13, 17, 16]},
            {materials: [4], vertices: [13, 14, 18, 17]},
            {materials: [4], vertices: [14, 15, 19, 18]},
            {materials: [4], vertices: [15, 12, 16, 19]},

            {materials: [4], vertices: [12, 13, 21, 20]},
            {materials: [4], vertices: [13, 14, 22, 21]},
            {materials: [4], vertices: [14, 15, 23, 22]},
            {materials: [4], vertices: [15, 12, 20, 23]},

            {materials: [4], vertices: [20, 21, 25, 24]},
            {materials: [4], vertices: [21, 22, 26, 25]},
            {materials: [4], vertices: [22, 23, 27, 26]},
            {materials: [4], vertices: [23, 20, 24, 27]},

            {materials: [4], vertices: [24, 25, 29, 28]},
            {materials: [4], vertices: [25, 26, 30, 29]},
            {materials: [4], vertices: [26, 27, 31, 30]},
            {materials: [4], vertices: [27, 24, 28, 31]},

            {materials: [4], vertices: [20, 21, 33, 32]},
            {materials: [4], vertices: [21, 22, 34, 33]},
            {materials: [4], vertices: [22, 23, 35, 34]},
            {materials: [4], vertices: [23, 20, 32, 35]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10
        };

        const arrows = {
            'A': [36, 37, 38, 39],
            'B': [40, 41, 42, 43],
            'C': [44, 45, 46, 47]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Podstawa dachowa prostokątna';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }
}