import { Model } from "../model";

export class Przepustnica extends Model {
    constructor(vertices, planes, dimensions, arrows, holes, options)
    {
        super(vertices, planes, dimensions, arrows, holes, options);
    }

    drawHandle(x, y, z)
    {
        const box_size = 12;
        const box_height = 3;
        const handle_width = 4;
        const handle_length = 25;
        const handle_depth = 1;
        const v = this.vertices.length;
        this.vertices.push([x + ' - ' + box_height, y + ' - ' + box_size + ' / 2', z + ' - ' + box_size + ' / 2']);
        this.vertices.push([x + ' - ' + box_height, y + ' - ' + box_size + ' / 2', z + ' + ' + box_size + ' / 2']);
        this.vertices.push([x + ' - ' + box_height, y + ' + ' + box_size + ' / 2', z + ' + ' + box_size + ' / 2']);
        this.vertices.push([x + ' - ' + box_height, y + ' + ' + box_size + ' / 2', z + ' - ' + box_size + ' / 2']);
        this.vertices.push([x, y + ' - ' + box_size + ' / 2', z + ' - ' + box_size + ' / 2']);
        this.vertices.push([x, y + ' - ' + box_size + ' / 2', z + ' + ' + box_size + ' / 2']);
        this.vertices.push([x, y + ' + ' + box_size + ' / 2', z + ' + ' + box_size + ' / 2']);
        this.vertices.push([x, y + ' + ' + box_size + ' / 2', z + ' - ' + box_size + ' / 2']);

        this.vertices.push([x, y + ' - ' + handle_width + ' / 4', z + ' - ' + handle_width + ' / 4']);
        this.vertices.push([x, y + ' - ' + handle_width + ' / 4', z + ' + ' + handle_width + ' / 4']);
        this.vertices.push([x, y + ' + ' + handle_width + ' / 4', z + ' + ' + handle_width + ' / 4']);
        this.vertices.push([x, y + ' + ' + handle_width + ' / 4', z + ' - ' + handle_width + ' / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height, y + ' - ' + handle_width + ' / 4', z + ' - ' + handle_width + ' / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height, y + ' - ' + handle_width + ' / 4', z + ' + ' + handle_width + ' / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height, y + ' + ' + handle_width + ' / 4', z + ' + ' + handle_width + ' / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height, y + ' + ' + handle_width + ' / 4', z + ' - ' + handle_width + ' / 4']);

        this.vertices.push([x + ' - 1 - ' + box_height,
                            y + ' + (' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4',
                            z + ' + (-' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ') + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ') * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ') - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ') * Math.sqrt(3) - ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 1 - ' + box_height,
                            y + ' + (-' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4',
                            z + ' + (-' + handle_width + ' * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 4) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 4) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 8) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 8) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 8) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 8) * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(2 * ' + handle_length + ' - ' + handle_width + ' + 4) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((2 * ' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 3 - ' + box_height,
                            y + ' + (-(2 * ' + handle_length + ' - ' + handle_width + ' + 4) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((2 * ' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) - ' + handle_width + ') / 4']);


        this.vertices.push([x + ' - 2 - ' + box_height,
                            y + ' + (' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4',
                            z + ' + (-' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 2 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ') + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ') * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 2 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ') - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ') * Math.sqrt(3) - ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 2 - ' + box_height,
                            y + ' + (-' + handle_width + ' * Math.sqrt(3) + ' + handle_width + ') / 4',
                            z + ' + (-' + handle_width + ' * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 4) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 4) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 8) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 8) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(' + handle_length + ' - ' + handle_width + ' + 8) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((' + handle_length + ' - ' + handle_width + ' + 8) * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(2 * ' + handle_length + ' - ' + handle_width + ' + 4) + ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((2 * ' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) + ' + handle_width + ') / 4']);
        this.vertices.push([x + ' - 4 - ' + box_height,
                            y + ' + (-(2 * ' + handle_length + ' - ' + handle_width + ' + 4) - ' + handle_width + ' * Math.sqrt(3)) / 4',
                            z + ' + ((2 * ' + handle_length + ' - ' + handle_width + ' + 4) * Math.sqrt(3) - ' + handle_width + ') / 4']);

        this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});
        this.planes.push({materials: [4], vertices: [v + 1, v + 5, v + 6, v + 2]});
        this.planes.push({materials: [4], vertices: [v + 3, v + 7, v + 4, v + 0]});

        this.planes.push({materials: [4], vertices: [v + 8, v + 12, v + 13, v + 9]});
        this.planes.push({materials: [4], vertices: [v + 9, v + 13, v + 14, v + 10]});
        this.planes.push({materials: [4], vertices: [v + 10, v + 14, v + 15, v + 11]});
        this.planes.push({materials: [4], vertices: [v + 11, v + 15, v + 12, v + 8]});

        let p = this.planes.length;

        this.planes.push({materials: [3], vertices: [v + 16, v + 17, v + 18, v + 19]});
        this.planes.push({materials: [3], vertices: [v + 18, v + 17, v + 20, v + 21]});
        this.planes.push({materials: [3], vertices: [v + 21, v + 20, v + 22, v + 23]});
        this.planes.push({materials: [5], vertices: [v + 23, v + 22, v + 24, v + 25]});

        this.planes.push({materials: [3], vertices: [v + 26, v + 27, v + 28, v + 29]});
        this.planes.push({materials: [3], vertices: [v + 28, v + 27, v + 30, v + 31]});
        this.planes.push({materials: [3], vertices: [v + 31, v + 30, v + 32, v + 33]});
        this.planes.push({materials: [5], vertices: [v + 33, v + 32, v + 34, v + 35]});

        this.planes.push({materials: [3], vertices: [v + 16, v + 17, v + 27, v + 26]});
        this.planes.push({materials: [3], vertices: [v + 18, v + 19, v + 29, v + 28]});
        this.planes.push({materials: [3], vertices: [v + 19, v + 16, v + 26, v + 29]});
        this.planes.push({materials: [3], vertices: [v + 17, v + 20, v + 30, v + 27]});
        this.planes.push({materials: [3], vertices: [v + 18, v + 21, v + 31, v + 28]});
        this.planes.push({materials: [3], vertices: [v + 20, v + 22, v + 32, v + 30]});
        this.planes.push({materials: [3], vertices: [v + 21, v + 23, v + 33, v + 31]});

        this.planes.push({materials: [5], vertices: [v + 22, v + 24, v + 34, v + 32]});
        this.planes.push({materials: [5], vertices: [v + 23, v + 25, v + 35, v + 33]});
        this.planes.push({materials: [5], vertices: [v + 24, v + 25, v + 35, v + 34]});
        return p;
    }
}