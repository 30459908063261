import { Model } from "../model";
import { Cache } from "../cache";

export class ZaslepkaProstokatna extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_C', '0'],
            ['dim_A', 'dim_C', '0'],
            ['dim_A', 'dim_C', 'dim_B'],
            ['0', 'dim_C', 'dim_B'],

            // arrows
            // A 16 - 17
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],

            // B 18 - 19
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],

            // C 20 - 22
            ['dim_A + 10', 'dim_C', 'dim_B'],
            ['dim_A + 10', '0', 'dim_B'],
            ['dim_A + 3', '0', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [12, 13, 14, 15]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10
        };

        const arrows = {
            'A': [11, 16, 17, 10],
            'B': [9, 18, 19, 10],
            'C': [14, 20, 21, 22]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Zaślepka prostokątna';
    }
}