export class CachedBase {
    assignValuesToDims(expression, all_dim_values) {
        let result = expression;
        let needle = '_';
        let index = result.indexOf(needle);
        while(index != -1) {
            let letter = result[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < result.length) {
                let digit_candidate = result[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let value = all_dim_values[letter + digit];
            result = result.replace(needle + letter + digit, String(value));
            index = result.indexOf(needle, index);
        }
        return result;
    }

    simplifyExpression(expression) {
        let result = "";
        let collected_dim = 0;
        for(let i = 0; i < expression.length; i++) {
            if(expression[i] != ' ') {
                if(expression[i] == 'd' && collected_dim == 0) {
                    collected_dim = 1;
                } else if(expression[i] == 'i' && collected_dim == 1) {
                    collected_dim = 2;
                } else if(expression[i] == 'm' && collected_dim == 2) {
                    collected_dim = 3;
                } else if(expression[i] == '_' && collected_dim == 3) {
                    result += '_';
                    collected_dim = 0;
                } else {
                    if(collected_dim >= 1) {
                        result += 'd';
                    }
                    if(collected_dim >= 2) {
                        result += 'i';
                    }
                    if(collected_dim == 3) {
                        result += 'm';
                    }
                    result += expression[i];
                    collected_dim = 0;
                }
            }
        }
        return result;
    }

    static getDimensionsOfFunctionsInExpression(expression, functions)
    {
        let result = new Set();
        let needle = '$';
        let index = expression.indexOf(needle);
        while(index != -1) {
            let letter = needle + expression[index + needle.length];
            let digit = "";
            if(index + needle.length + 1 < expression.length) {
                let digit_candidate = expression[index + needle.length + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    digit = digit_candidate;
                }
            }
            let func_name = letter + digit;
            let func = functions.get(func_name);
            if(func !== undefined) {
                for (let dimension of func.dimensions) {
                    result.add(dimension);
                }
            }
            index = expression.indexOf(needle, index + func_name.length);
        }
        return result;
    }
}