import { Model } from "../model";
import { Cache } from "../cache";

export class PrzylaczeElastyczneProstokatne extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const rubber_width = 7;
        const metal_width = 4;

        const vertices = [
            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],

            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],

            ['0', '0', metal_width.toString()],
            ['dim_A', '0', metal_width.toString()],
            ['dim_A', 'dim_B', metal_width.toString()],
            ['0', 'dim_B', metal_width.toString()],

            ['0', '0', (metal_width + rubber_width).toString() ],
            ['dim_A', '0', (metal_width + rubber_width).toString()],
            ['dim_A', 'dim_B', (metal_width + rubber_width).toString()],
            ['0', 'dim_B', (metal_width + rubber_width).toString()],

            ['0', '0', (2 * metal_width + rubber_width - 3).toString()],
            ['dim_A', '0', (2 * metal_width + rubber_width - 3).toString()],
            ['dim_A', 'dim_B', (2 * metal_width + rubber_width - 3).toString()],
            ['0', 'dim_B', (2 * metal_width + rubber_width - 3).toString()],

            ['0', '0', (2 * metal_width + rubber_width).toString()],
            ['dim_A', '0', (2 * metal_width + rubber_width).toString()],
            ['dim_A', 'dim_B', (2 * metal_width + rubber_width).toString()],
            ['0', 'dim_B', (2 * metal_width + rubber_width).toString()],

            ['-3', '-3', (2 * metal_width + rubber_width).toString()],
            ['dim_A + 3', '-3', (2 * metal_width + rubber_width).toString()],
            ['dim_A + 3', 'dim_B + 3', (2 * metal_width + rubber_width).toString()],
            ['-3', 'dim_B + 3', (2 * metal_width + rubber_width).toString()],

            //arrow A
            ['0', 'dim_B / 2', (2 * metal_width + rubber_width).toString()],
            ['0', 'dim_B / 2', (2 * metal_width + rubber_width + 10).toString()],
            ['dim_A', 'dim_B / 2', (2 * metal_width + rubber_width + 10).toString()],
            ['dim_A', 'dim_B / 2', (2 * metal_width + rubber_width).toString()],
            //arrow B
            ['dim_A / 2', 'dim_B', (2 * metal_width + rubber_width).toString()],
            ['dim_A / 2', 'dim_B', (2 * metal_width + rubber_width + 10).toString()],
            ['dim_A / 2', '0', (2 * metal_width + rubber_width + 10).toString()],
            ['dim_A / 2', '0', (2 * metal_width + rubber_width).toString()]
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},
            {materials: [2], vertices: [8, 9, 5, 4]},
            {materials: [2], vertices: [9, 10, 6, 5]},
            {materials: [2], vertices: [10, 11, 7, 6]},
            {materials: [2], vertices: [11, 8, 4, 7]},

            {materials: [0, 1], vertices: [12, 13, 9, 8]},
            {materials: [0, 1], vertices: [13, 14, 10, 9]},
            {materials: [0, 1], vertices: [14, 15, 11, 10]},
            {materials: [0, 1], vertices: [15, 12, 8, 11]},
            {materials: [3], vertices: [12, 13, 17, 16]},
            {materials: [3], vertices: [13, 14, 18, 17]},
            {materials: [3], vertices: [14, 15, 19, 18]},
            {materials: [3], vertices: [15, 12, 16, 19]},

            {materials: [0, 1], vertices: [17, 16, 20, 21]},
            {materials: [0, 1], vertices: [18, 17, 21, 22]},
            {materials: [0, 1], vertices: [19, 18, 22, 23]},
            {materials: [0, 1], vertices: [16, 19, 23, 20]},

            {materials: [2], vertices: [25, 24, 20, 21]},
            {materials: [2], vertices: [26, 25, 21, 22]},
            {materials: [2], vertices: [27, 26, 22, 23]},
            {materials: [2], vertices: [24, 27, 23, 20]},

            {materials: [2], vertices: [25, 24, 28, 29]},
            {materials: [2], vertices: [26, 25, 29, 30]},
            {materials: [2], vertices: [27, 26, 30, 31]},
            {materials: [2], vertices: [24, 27, 31, 28]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10
        };

        const arrows = {
            'A': [32, 33, 34, 35],
            'B': [36, 37, 38, 39]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Przyłącze elastyczne prostokątne';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }
}