import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ModalsService } from "../modules/modals/services/modal.service";

@Component({
    selector: 'base-view',
    template: `
        <div>BasePanelView - nie używać bezpośrednio!</div>
    `
})
export class BasePanelViewComponent implements OnInit {
    public form: UntypedFormGroup;
    public formSend: boolean = false;

    protected errorMessages: {};

    constructor(
        protected modalsService: ModalsService,
        protected viewportScroller: ViewportScroller
    ) { }

    protected _locks: string[] = [];

    ngOnInit(): void { }

    get locks(): string[] {
        return this._locks;
    }

    isLocked(scope: string = undefined): boolean {
        return scope 
            ? this._locks.includes('*') || this._locks.includes(scope)
            : this._locks.includes('*');
    }

    lock(scope: string = undefined): void {
        !scope 
            ? this._locks = ['*'] 
            : this._locks.includes(scope) ? null : this._locks.push(scope);
    }

    unlock(scope: string = undefined): void {
        !scope
            ? this._locks = []
            : (this.isLocked(scope) ? this._locks.splice(this._locks.indexOf(scope), 1) : null) 
            
    }

    scrollTo(): void {

    }

    hasErrors(fieldKey: string) {
        let field: UntypedFormControl = this.form.get(fieldKey) as UntypedFormControl;

        if(field && (this.formSend || field.touched)) {
            if(field.errors && this.errorMessages[fieldKey]) {
                return true;
            }
        }

        return false;
    }

    getErrors(fieldKey: string): string {
        let field: UntypedFormControl = this.form.get(fieldKey) as UntypedFormControl;
        let errors = [];
        let errorsStr = "";

        if(field && (this.formSend || field.touched)) {
            if(field.errors && this.errorMessages[fieldKey]) {
                let error: string;

                for (let errorKey in field.errors) {
                    (error = this.errorMessages[fieldKey][errorKey]) ? errors.push(error) : null;
                }
            }

            errorsStr = errors.join(", ");
        }

        return errorsStr;
    }
}