import { Model } from "../model";
import { Cache } from "../cache";

export class CzwornikZOdejsciemOkraglym extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],

            ['0', '0', 'dim_C - 3'],
            ['dim_A', '0', 'dim_C - 3'],
            ['dim_A', 'dim_B', 'dim_C - 3'],
            ['0', 'dim_B', 'dim_C - 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],

            ['0', '0', 'dim_C'],
            ['dim_A', '0', 'dim_C'],
            ['dim_A', 'dim_B', 'dim_C'],
            ['0', 'dim_B', 'dim_C'],

            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],

            ['-3', '-3', 'dim_C'],
            ['dim_A + 3', '-3', 'dim_C'],
            ['dim_A + 3', 'dim_B + 3', 'dim_C'],
            ['-3', 'dim_B + 3', 'dim_C'],
            //arrow A
            ['0', 'dim_B / 2', 'dim_C'],
            ['0', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C'],
            //arrow B
            ['dim_A / 2', 'dim_B', 'dim_C'],
            ['dim_A / 2', 'dim_B', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C'],
            //arrow C
            ['dim_A / 2', 'dim_B + 3', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', 'dim_C'],
            ['dim_A / 2', 'dim_B + 3', 'dim_C']
        ];

        const planes = [
            {materials: [0, 1], vertices: [4, 5, 1, 0]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [6, 7, 3, 2]},
            {materials: [0, 1], vertices: [7, 4, 0, 3]},
            {materials: [2], vertices: [0, 1, 9, 8]},
            {materials: [2], vertices: [1, 2, 10, 9]},
            {materials: [2], vertices: [2, 3, 11, 10]},
            {materials: [2], vertices: [3, 0, 8, 11]},
            {materials: [2], vertices: [12, 13, 5, 4]},
            {materials: [2], vertices: [13, 14, 6, 5]},
            {materials: [2], vertices: [14, 15, 7, 6]},
            {materials: [2], vertices: [15, 12, 4, 7]},
            {materials: [2], vertices: [8, 16, 17, 9]},
            {materials: [2], vertices: [9, 17, 18, 10]},
            {materials: [2], vertices: [10, 18, 19, 11]},
            {materials: [2], vertices: [11, 19, 16, 8]},
            {materials: [2], vertices: [12, 20, 21, 13]},
            {materials: [2], vertices: [13, 21, 22, 14]},
            {materials: [2], vertices: [14, 22, 23, 15]},
            {materials: [2], vertices: [15, 23, 20, 12]}
        ];

        const sectors_count = 100;
        const cylinder_height = 7.5;

        const functions = [
            {name: '$A', params: ['x', 'y'], expression: 'x * Math.cos(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2'},
            {name: '$B', params: ['x', 'y'], expression: 'x * Math.sin(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2'},

            {name: '$D', params: [], expression: 'dim_G == 0 ? dim_A / 2 : dim_G1'},
            {name: '$E', params: [], expression: 'dim_A + ' + cylinder_height},
            {name: '$F', params: [], expression: 'dim_G == 0 ? dim_B / 2 : dim_G1'},
            {name: '$G', params: [], expression: 'dim_F == 0 ? dim_C / 2 : dim_F1'},
            {name: '$H', params: [], expression: 'dim_J == 0 ? dim_C / 2 : dim_J1'},
            {name: '$I', params: [], expression: 'dim_K == 0 ? dim_B / 2 : dim_K1'},
            {name: '$J', params: [], expression: 'dim_K == 0 ? dim_A / 2 : dim_K1'},
            {name: '$K', params: ['x', 'y', 'z', 'v'], expression: 'dim_E == 0 ? x : dim_E == 1 ? y : dim_E == 2 ? z : v'},
            {name: '$L', params: ['x', 'y', 'z', 'v'], expression: 'dim_I == 0 ? x : dim_I == 1 ? y : dim_I == 2 ? z : v'},
        ];


        // cylinder #1:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$K(-' + cylinder_height + ', $A(dim_D, ' + i + ') + $D(), $E(), - $A(dim_D, ' + i + ') + dim_A - $D())',
                           '$K(- $A(dim_D, ' + i + ') + dim_B - $F(), -' + cylinder_height + ', $A(dim_D, ' + i + ') + $F(), dim_B + ' + cylinder_height + ')',
                           '$B(dim_D, ' + i + ') + $G()']);
            vertices.push(['$K(0, $A(dim_D, ' + i + ') + $D(), dim_A, - $A(dim_D, ' + i + ') + dim_A - $D())',
                           '$K(- $A(dim_D, ' + i + ') + dim_B - $F(), 0, $A(dim_D, ' + i + ') + $F(), dim_B)',
                           '$B(dim_D, ' + i + ') + $G()']);
            planes.push({materials: [0, 1], vertices: [36 + 2 * ((i + 1) % sectors_count), 37 + 2 * ((i + 1) % sectors_count), 37 + 2 * i, 36 + 2 * i]});
        }
        // arrow D
        vertices.push(['$K(-10 - ' + cylinder_height + ', dim_D / 2 + $D(), $E() + 10, -dim_D / 2 + dim_A - $D())',
                       '$K(-dim_D / 2 + dim_B - $F(), -10 - ' + cylinder_height + ', dim_D / 2 + $F(), dim_B + ' + cylinder_height + ' + 10)',
                       '$G()']);
        vertices.push(['$K(-10 - ' + cylinder_height + ', -dim_D / 2 + $D(), $E() + 10, dim_D / 2 + dim_A - $D())',
                       '$K(dim_D / 2 + dim_B - $F(), -10 - ' + cylinder_height + ', -dim_D / 2 + $F(), dim_B + ' + cylinder_height + ' + 10)',
                       '$G()']);

        //arrow G1
        vertices.push(['$K(-3, $D(), dim_A + 3, dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)',
                       '$K(dim_G == 0 ? dim_B / 2 : dim_B - dim_G1, -3, $F(), dim_B + 3)',
                       '0']);
        vertices.push(['$K(-10 - ' + cylinder_height + ', $D(), $E() + 10, dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)',
                       '$K((dim_G == 0 ? dim_B / 2 : dim_B - dim_G1), -10 - ' + cylinder_height + ', $F(), dim_B + ' + cylinder_height + ' + 10)',
                       '0']);
        vertices.push(['$K(-10 - ' + cylinder_height + ', $D(), $E() + 10, dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)',
                       '$K(dim_G == 0 ? dim_B / 2 : dim_B - dim_G1, -10 - ' + cylinder_height + ', $F(), dim_B + ' + cylinder_height + ' + 10)',
                       '$G()']);
        vertices.push(['$K(0, $D(), dim_A, dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)',
                       '$K(dim_G == 0 ? dim_B / 2 : dim_B - dim_G1, 0, $F(), dim_B)',
                       '$G()']);
        //arrow H1
        vertices.push(['$K(0, 0, dim_A, dim_A)',
                       '$K(dim_B, 0, 0, dim_B)',
                       '$G()']);
        vertices.push(['$K(-10 - ' + cylinder_height + ', 0, $E() + 10, dim_A)',
                       '$K(dim_B, -10 - ' + cylinder_height + ', 0, dim_B + ' + cylinder_height + ' + 10)',
                       '$G()']);

        // cylinder #2:
         for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$L(-' + cylinder_height + ', $A(dim_H, ' + i + ') + $J(), $E(), - $A(dim_H, ' + i + ') + dim_A - $J())',
                           '$L(- $A(dim_H, ' + i + ') + dim_B - $I(), -' + cylinder_height + ', $A(dim_H, ' + i + ') + $I(), dim_B + ' + cylinder_height + ')',
                           '$B(dim_H, ' + i + ') + $H()']);
            vertices.push(['$L(0, $A(dim_H, ' + i + ') + $J(), dim_A, - $A(dim_H, ' + i + ') + dim_A - $J())',
                           '$L(- $A(dim_H, ' + i + ') + dim_B - $I(), 0, $A(dim_H, ' + i + ') + $I(), dim_B)',
                           '$B(dim_H, ' + i + ') + $H()']);
               planes.push({materials: [0, 1], vertices: [36 + 2 * sectors_count + 8 + 2 * ((i + 1) % sectors_count), 37 + 2 * sectors_count + 8 + 2 * ((i + 1) % sectors_count), 37 + 2 * sectors_count + 8 + 2 * i, 36 + 2 * sectors_count + 8 + 2 * i]});
           }

        //arrow I
        vertices.push(['$L(-10 - ' + cylinder_height + ', dim_H / 2 + $J(), $E() + 10, -dim_H / 2 + dim_A - $J())',
                       '$L(-dim_H / 2 + dim_B - $I(), -10 - ' + cylinder_height + ', dim_H / 2 + $I(), dim_B + ' + cylinder_height + ' + 10)',
                       '$H()']);
        vertices.push(['$L(-10 - ' + cylinder_height + ', -dim_H / 2 + $J(), $E() + 10, dim_H / 2 + dim_A - $J())',
                       '$L(dim_H / 2 + dim_B - $I(), -10 - ' + cylinder_height + ', -dim_H / 2 + $I(), dim_B + ' + cylinder_height + ' + 10)',
                       '$H()']);
        //arrow K1
        vertices.push(['$L(-3, $J(), dim_A + 3, dim_K == 0 ? dim_A / 2 : dim_A - dim_K1)',
                       '$L(dim_K == 0 ? dim_B / 2 : dim_B - dim_K1, -3, $I(), dim_B + 3)',
                       '0']);
        vertices.push(['$L(-10 - ' + cylinder_height + ', $J(), $E() + 10, dim_K == 0 ? dim_A / 2 : dim_A - dim_K1)',
                       '$L(dim_K == 0 ? dim_B / 2 : dim_B - dim_K1, -10 - ' + cylinder_height + ', $I(), dim_B + ' + cylinder_height + ' + 10)',
                       '0']);
        vertices.push(['$L(-10 - ' + cylinder_height + ', $J(), $E() + 10, dim_K == 0 ? dim_A / 2 : dim_A - dim_K1)',
                       '$L(dim_K == 0 ? dim_B / 2 : dim_B - dim_K1, -10 - ' + cylinder_height + ', $I(), dim_B + ' + cylinder_height + ' + 10)',
                       '$H()']);
        vertices.push(['$L(0, $J(), dim_A, dim_K == 0 ? dim_A / 2 : dim_A - dim_K1)',
                       '$L(dim_K == 0 ? dim_B / 2 : dim_B - dim_K1, 0, $I(), dim_B)',
                       '$H()']);
        //arrow L1
        vertices.push(['$L(0, 0, dim_A, dim_A)',
                       '$L(dim_B, 0, 0, dim_B)',
                       '$H()']);
        vertices.push(['$L(-10 - ' + cylinder_height + ', 0, $E() + 10, dim_A)',
                       '$L(dim_B, -10 - ' + cylinder_height + ', 0, dim_B + ' + cylinder_height + ' + 10)',
                       '$H()']);


        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': default_vals['E'],
            'F': default_vals['F'],
            'F1': empty_vals['F1'] / 10,
            'G': default_vals['G'],
            'G1': empty_vals['G1'] / 10,
            'H': empty_vals['H'] / 10,
            'I': default_vals['I'],
            'J': default_vals['J'],
            'J1': empty_vals['J1'] / 10,
            'K': default_vals['K'],
            'K1': empty_vals['K1'] / 10
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31],
            'C': [32, 33, 34, 35],
            'D': [36, 36 + 2 * sectors_count, 37 + 2 * sectors_count, 36 + sectors_count],
            'F1': [38 + 2 * sectors_count, 39 + 2 * sectors_count, 40 + 2 * sectors_count, 41 + 2 * sectors_count],
            'G1': [42 + 2 * sectors_count, 43 + 2 * sectors_count, 40 + 2 * sectors_count, 41 + 2 * sectors_count],
            'H': [36 + 2 * sectors_count + 8, 36 + 2 * sectors_count + 8 + 2 * sectors_count, 37 + 2 * sectors_count + 8 + 2 * sectors_count, 36 + 2 * sectors_count + 8 + sectors_count],
            'J1': [38 + 2 * sectors_count + 8 + 2 * sectors_count, 39 + 2 * sectors_count + 8 + 2 * sectors_count, 40 + 2 * sectors_count + 8 + 2 * sectors_count, 41 + 2 * sectors_count + 8 + 2 * sectors_count],
            'K1': [42 + 2 * sectors_count + 8 + 2 * sectors_count, 43 + 2 * sectors_count + 8 + 2 * sectors_count, 40 + 2 * sectors_count + 8 + 2 * sectors_count, 41 + 2 * sectors_count + 8 + 2 * sectors_count]
        };

        const circle_1 = [];
        for(let i = 0; i < sectors_count; i++) {
            circle_1.push(36 + 2 * i);
        }

        const circle_2 = [];
        for(let i = 0; i < sectors_count; i++) {
            circle_2.push(36 + 2 * sectors_count + 8 + 2 * i);
        }

        const holes = [
            {plane_index: 'dim_E == 0 ? 3 : (dim_E == 1 ? 0 : (dim_E == 2 ? 1 : 2))', vertices: circle_1},
            {plane_index: 'dim_I == 0 ? 3 : (dim_I == 1 ? 0 : (dim_I == 2 ? 1 : 2))', vertices: circle_2}
        ];

        super(vertices, planes, dimensions, arrows, holes, options);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Czwórnik z odejściem okrągłym';
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        //this.cache.print();
    }

    setDimensionValue(name, value) {
        if(name == 'E' || name == 'F' || name == 'G' || name == 'I' || name == 'J' || name == 'K') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'E' || name == 'F' || name == 'G' || name == 'I' || name == 'J' || name == 'K') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'E' || name == 'F' || name == 'G' || name == 'I' || name == 'J' || name == 'K') {
            return this.options[name][this.last_correct_dimensions[name]];
        } else if(name == 'F1') {
            if(this.last_correct_dimensions['F'] == '0') {
                return '';
            }
        } else if(name == 'G1') {
            if(this.last_correct_dimensions['G'] == '0') {
                return '';
            }
        } else if(name == 'J1') {
            if(this.last_correct_dimensions['J'] == '0') {
                return '';
            }
        } else if(name == 'K1') {
            if(this.last_correct_dimensions['K'] == '0') {
                return '';
            }
        }
        return this.last_correct_dimensions[name] * 10;
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let b = this.dimensions['B'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];
        let e = this.dimensions['E'];
        let f = this.dimensions['F'];
        let f1 = this.dimensions['F1'];
        let g = this.dimensions['G'];
        let g1 = this.dimensions['G1'];
        let h = this.dimensions['H'];
        let i = this.dimensions['I'];
        let j = this.dimensions['J'];
        let j1 = this.dimensions['J1'];
        let k = this.dimensions['K'];
        let k1 = this.dimensions['K1'];
        if(f == '0') {
            if(d + 6 > c) {
                return [false, 1];
            }
        } else {
            if(f1 - d / 2 < 3 || f1 + d / 2 > c - 3) {
                return [false, 2];
            }
        }
        if(e == '0' || e == '2') {
            if(g == '0') {
                if(d > b) {
                    return [false, 3];
                }
            } else {
                if(g1 - d / 2 < 0 || g1 + d / 2 > b) {
                    return [false, 4];
                }
            }
        } else {
            if(g == '0') {
                if(d > a) {
                    return [false, 5];
                }
            } else {
                if(g1 - d / 2 < 0 || g1 + d / 2 > a) {
                    return [false, 6];
                }
            }
        }


        if(j == '0') {
            if(h + 6 > c) {
                return [false, 7];
            }
        } else {
            if(j1 - i / 2 < 3 || j1 + i / 2 > c - 3) {
                return [false, 8];
            }
        }
        if(i == '0' || i == '2') {
            if(k == '0') {
                if(h > b) {
                    return [false, 9];
                }
            } else {
                if(k1 - h / 2 < 0 || k1 + h / 2 > b) {
                    return [false, 10];
                }
            }
        } else {
            if(k == '0') {
                if(h > a) {
                    return [false, 11];
                }
            } else {
                if(k1 - h / 2 < 0 || k1 + h / 2 > a) {
                    return [false, 12];
                }
            }
        }

        if(i == e) {
            return [false, 13];
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Średnica D większa od wymiaru C po odliczeniu zapasu na wykończenie. Zmniejsz średnicę D, lub powiększ wymiar C.";
        } else if(error_code == 2) {
            return "Odejście okrągłe #1 wystaje poza wymiar C lub nachodzi na wykończenie. Zmniejsz średnicę D, powiększ wymiar C, lub przesuń otwór #1.";
        } else if(error_code == 3) {
            return "Średnica D większa od wymiaru B. Zmniejsz średnicę D, lub powiększ wymiar B.";
        } else if(error_code == 4) {
            return "Odejście okrągłe #1 wystaje poza wymiar B. Zmniejsz średnicę D, powiększ wymiar B, lub przesuń otwór #1.";
        } else if(error_code == 5) {
            return "Średnica D większa od wymiaru A. Zmniejsz średnicę D, lub powiększ wymiar A.";
        } else if(error_code == 6) {
            return "Odejście okrągłe #1 wystaje poza wymiar A. Zmniejsz średnicę D, powiększ wymiar A, lub przesuń otwór #1.";
        } else if(error_code == 7) {
            return "Średnica H większa od wymiaru C po odliczeniu zapasu na wykończenie. Zmniejsz średnicę H, lub powiększ wymiar C.";
        } else if(error_code == 8) {
            return "Odejście okrągłe #2 wystaje poza wymiar C lub nachodzi na wykończenie. Zmniejsz średnicę H, powiększ wymiar C, lub przesuń otwór #2.";
        } else if(error_code == 9) {
            return "Średnica H większa od wymiaru B. Zmniejsz średnicę H, lub powiększ wymiar B.";
        } else if(error_code == 10) {
            return "Odejście okrągłe #2 wystaje poza wymiar B. Zmniejsz średnicę H, powiększ wymiar B, lub przesuń otwór #2.";
        } else if(error_code == 11) {
            return "Średnica H większa od wymiaru A. Zmniejsz średnicę H, lub powiększ wymiar A.";
        } else if(error_code == 12) {
            return "Odejście okrągłe #2 wystaje poza wymiar A. Zmniejsz średnicę H, powiększ wymiar A, lub przesuń otwór #2.";
        } else if(error_code == 13) {
            return "Otwory okrągłe muszą znajdować się na różnych ścianach kanału. Zmień bok jednego z otworów.";
        }
        return "";
    }
}