import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShapeEditorComponent } from './components/shape-editor/shape-editor.component';
import { CommentParamComponent } from './components/params/comment-param/comment-param.component';
import { CountParamComponent } from './components/params/count-param/count-param.component';
import { NumericParamComponent } from './components/params/numeric-param/numeric-param.component';
import { OptionsParamComponent } from './components/params/options-param/options-param.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IntegerInputComponent } from '../forms/components/integer-input/integer-input.component';
import { ShapeViewerComponent } from './components/shape-viewer/shape-viewer.component';



@NgModule({
    declarations: [
        CommentParamComponent,
        CountParamComponent,
        NumericParamComponent,
        OptionsParamComponent,
        ShapeEditorComponent,
        IntegerInputComponent,
        ShapeViewerComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        ShapeEditorComponent,
        CommentParamComponent,
        CountParamComponent,
        NumericParamComponent,
        OptionsParamComponent,
        ShapeEditorComponent,
        IntegerInputComponent,
        ShapeViewerComponent
    ]
})
export class ShapesModule { }
