import { Model } from "../model";
import { Cache } from "../cache";

export class CokolDachowy extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const functions = [];
        const vertices = [
            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['5', '0', '5'],
            ['dim_A - 5', '0', '5'],
            ['dim_A - 5', '0', 'dim_B - 5'],
            ['5', '0', 'dim_B - 5'],

            ['5', '-1', '5'],
            ['dim_A - 5', '-1', '5'],
            ['dim_A - 5', '-1', 'dim_B - 5'],
            ['5', '-1', 'dim_B - 5'],

            ['5', '-dim_C - (dim_E == 0 ? 0 : (5 * Math.tan(Math.PI / 180 * dim_F)))', '5'],
            ['dim_A - 5', '-dim_C - (dim_E == 0 ? 0 : ((dim_G == 0 ? dim_A - 5: 5) * Math.tan(Math.PI / 180 * dim_F)))', '5'],
            ['dim_A - 5', '-dim_C - (dim_E == 0 ? 0 : (((dim_G == 0 ? dim_A : dim_B) - 5) * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B - 5'],
            ['5', '-dim_C - (dim_E == 0 ? 0 : ((dim_G == 0 ? 5 : dim_B - 5) * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B - 5'],

            ['-10', '-dim_C - (dim_E == 0 ? 0 : (-10 * Math.tan(Math.PI / 180 * dim_F)))', '-10'],
            ['dim_A + 10', '-dim_C - (dim_E == 0 ? 0 : ((dim_G == 0 ? dim_A + 10: -10) * Math.tan(Math.PI / 180 * dim_F)))', '-10'],
            ['dim_A + 10', '-dim_C - (dim_E == 0 ? 0 : (((dim_G == 0 ? dim_A : dim_B) + 10) * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B + 10'],
            ['-10', '-dim_C - (dim_E == 0 ? 0 : ((dim_G == 0 ? -10 : dim_B + 10) * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B + 10'],

            ['0', '-dim_C', '0'],
            ['dim_A', '-dim_C - (dim_E == 0 ? 0 : (dim_G == 0 ? dim_A * Math.tan(Math.PI / 180 * dim_F): 0))', '0'],
            ['dim_A', '-dim_C - (dim_E == 0 ? 0 : ((dim_G == 0 ? dim_A : dim_B) * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B'],
            ['0', '-dim_C - (dim_E == 0 ? 0 : (dim_G == 0 ? 0 : dim_B  * Math.tan(Math.PI / 180 * dim_F)))', 'dim_B'],

            // arrows
            // A 24-27
            ['0', '-dim_C / 2', 'dim_B'],
            ['0', '-dim_C / 2', 'dim_B + 10'],
            ['dim_A', '-dim_C / 2', 'dim_B + 10'],
            ['dim_A', '-dim_C / 2', 'dim_B'],

            // B
            ['dim_A', '-dim_C / 2', '0'],
            ['dim_A + 10', '-dim_C / 2', '0'],
            ['dim_A + 10', '-dim_C / 2', 'dim_B'],
            ['dim_A', '-dim_C / 2', 'dim_B'],

            // C
            ['dim_G == 0 ? 0 : dim_A', '0', 'dim_G == 0 ? dim_B : 0'],
            ['dim_G == 0 ? 0 : dim_A + 20', '0', 'dim_G == 0 ? dim_B + 20 : 0'],
            ['dim_G == 0 ? 0 : dim_A + 20', '-dim_C', 'dim_G == 0 ? dim_B + 20 : 0'],
            ['dim_G == 0 ? 0 : dim_A + 10', '-dim_C', 'dim_G == 0 ? dim_B + 10 : 0']

        ];

        const planes = [
            {materials: [0, 1], vertices: [0, 1, 5, 4]},
            {materials: [0, 1], vertices: [1, 2, 6, 5]},
            {materials: [0, 1], vertices: [2, 3, 7, 6]},
            {materials: [0, 1], vertices: [3, 0, 4, 7]},

            {materials: [0, 1], vertices: [4, 5, 9, 8]},
            {materials: [0, 1], vertices: [5, 6, 10, 9]},
            {materials: [0, 1], vertices: [6, 7, 11, 10]},
            {materials: [0, 1], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [12, 13, 9, 8]},
            {materials: [0, 1], vertices: [13, 14, 10, 9]},
            {materials: [0, 1], vertices: [14, 15, 11, 10]},
            {materials: [0, 1], vertices: [15, 12, 8, 11]},

            {materials: [5], vertices: [8, 9, 13, 12]},
            {materials: [5], vertices: [9, 10, 14, 13]},
            {materials: [5], vertices: [10, 11, 15, 14]},
            {materials: [5], vertices: [11, 8, 12, 15]},

            {materials: [4], vertices: [16, 17, 13, 12]},
            {materials: [4], vertices: [17, 18, 14, 13]},
            {materials: [4], vertices: [18, 19, 15, 14]},
            {materials: [4], vertices: [19, 16, 12, 15]},

            {materials: [0, 1], vertices: [20, 21, 1, 0]},
            {materials: [0, 1], vertices: [21, 22, 2, 1]},
            {materials: [0, 1], vertices: [22, 23, 3, 2]},
            {materials: [0, 1], vertices: [23, 20, 0, 3]},
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': default_vals['D'],
            'E': default_vals['E'],
            'F': empty_vals['F'],
            'G': default_vals['G']
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31],
            'C': [32, 33, 34, 35]
        };

        const holes = [
            {plane_index: '8', visible: 'dim_D == 0'},
            {plane_index: '9', visible: 'dim_D == 0'},
            {plane_index: '10', visible: 'dim_D == 0'},
            {plane_index: '11', visible: 'dim_D == 0'},
            {plane_index: '12', visible: 'dim_D == 1'},
            {plane_index: '13', visible: 'dim_D == 1'},
            {plane_index: '14', visible: 'dim_D == 1'},
            {plane_index: '15', visible: 'dim_D == 1'},
        ];

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Cokół dachowy';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }

    setDimensionValue(name, value) {
        if(name == 'D' || name == 'E' || name == 'F' || name == 'G') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'D' || name == 'E' || name == 'F' || name == 'G') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'D' || name == 'E') {
            return this.options[name][this.last_correct_dimensions[name]];
        } else if(name == 'F' || name == 'G') {
            if(this.last_correct_dimensions['E'] == '0') {
                return '';
            }
            if(name == 'G') {
                return this.options[name][this.last_correct_dimensions[name]];
            }
        }
        return this.last_correct_dimensions[name] * 10;
    }
    
}