import { Model } from "../model";
import { Cache } from "../cache";

export class LukAsymetryczny extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const sectors_count = 100;
        const vertices = [
            // sheets 0 - 7
            ['0', '3', '0'],
            ['dim_C', '3', '0'],
            ['dim_C', 'dim_F', '0'],
            ['0', 'dim_F', '0'],

            ['0', '3', 'dim_B'],
            ['dim_C', '3', 'dim_B'],
            ['dim_C', 'dim_F', 'dim_B'],
            ['0', 'dim_F', 'dim_B']
        ];

        const planes = [
            {materials: [0, 1], vertices: [0, 1, 2, 3]},
            {materials: [0, 1], vertices: [7, 6, 5, 4]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [0, 3, 7, 4]},
        ];



        const functions = [
            {name: '$A', params: ['x', 'y', 'z'], expression: 'x * Math.cos((y + 1) * Math.PI / 180 * dim_D / ' + sectors_count + ') + z'},
            {name: '$B', params: ['x', 'y', 'z'], expression: 'x * Math.sin((y + 1) * Math.PI / 180 * dim_D / ' + sectors_count + ') + z'},
            {name: '$C', params: [], expression: 'dim_G + Math.min(dim_A, dim_C)'},
            {name: '$D', params: [], expression: 'Math.cos(Math.PI / 180 * dim_D)'},
            {name: '$E', params: [], expression: 'dim_G + Math.max(dim_A, dim_C)'},
            {name: '$F', params: [], expression: '$C() * ($C() / $D() - $E()) / ($C() / $D() - $C())'},
            {name: '$G', params: [], expression: 'Math.sin(Math.PI / 180 * dim_D)'},
            {name: '$H', params: [], expression: '(dim_C >= dim_A ? dim_C - (dim_D == 90 ? (dim_A + dim_G) : $F()) : (dim_D == 90 ? -dim_G : dim_C - $F()))'},
            {name: '$I', params: [], expression: 'dim_F + (dim_A >= dim_C ? (dim_D == 90 ? dim_A - dim_C : $G() * (dim_A + dim_G - $F())) : 0)'},
        ];

        //small arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(dim_G,' + i + ', -dim_G)', '$B(dim_G,' + i + ', dim_F)', '0']);
            vertices.push(['$A(dim_G,' + i + ', -dim_G)', '$B(dim_G,' + i + ', dim_F)', 'dim_B']);
            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [8 + 2 * i, 9 + 2 * i, 8 + 2 * i - 1, 7 + 2 * i - 1]});
            }
        }
        planes.push({materials: [0, 1], vertices: [8, 9, 7, 3]});
        //big arc:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(dim_D == 90 ? $C() : $F(), ' + i + ', $H())',
                           '$B(dim_D == 90 ? $C() : $F(), ' + i + ', $I())',
                            '0']);
            vertices.push(['$A(dim_D == 90 ? $C() : $F(), ' + i + ', $H())',
                           '$B(dim_D == 90 ? $C() : $F(), ' + i + ', $I())',
                            'dim_B']);

            if(i > 0) {
                planes.push({materials: [0, 1], vertices: [2 * sectors_count + 7 + 2 * i - 1, 2 * sectors_count + 8 + 2 * i - 1, 2 * sectors_count + 9 + 2 * i, 2 * sectors_count + 8 + 2 * i]});
            }
        }
        planes.push({materials: [0, 1], vertices: [2, 6, 9 + 2 * sectors_count, 8 + 2 * sectors_count]});

        //side A:
        const side_A = [3, 2];
        for(let i = 0; i < sectors_count; i++) {
            side_A.push(8 + 2 * sectors_count + 2 * i);
        }
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_A.push(8 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_A});

        //side B:
        const side_B = [];
        for(let i = sectors_count - 1; i >= 0; i--) {
            side_B.push(9 + 2 * sectors_count + 2 * i);
        }
        side_B.push(6);
        side_B.push(7);
        for(let i = 0; i < sectors_count; i++) {
            side_B.push(9 + 2 * i);
        }
        planes.push({materials: [0, 1], vertices: side_B});

        // finishing part 1
        vertices.push(['0', '0', '0']);
        vertices.push(['dim_C', '0', '0']);
        vertices.push(['0', '0', 'dim_B']);
        vertices.push(['dim_C', '0', 'dim_B']);
        vertices.push(['-3', '0', '-3']);
        vertices.push(['dim_C + 3', '0', '-3']);
        vertices.push(['dim_C + 3', '0', 'dim_B + 3']);
        vertices.push(['-3', '0', 'dim_B + 3']);

        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 9 + 4 * sectors_count, 1, 0]});
        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 11 + 4 * sectors_count, 5, 4]});
        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 10 + 4 * sectors_count, 4, 0]});
        planes.push({materials: [2], vertices: [9 + 4 * sectors_count, 11 + 4 * sectors_count, 5, 1]});

        planes.push({materials: [2], vertices: [8 + 4 * sectors_count, 12 + 4 * sectors_count, 13 + 4 * sectors_count, 9 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [9 + 4 * sectors_count, 13 + 4 * sectors_count, 14 + 4 * sectors_count, 11 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [11 + 4 * sectors_count, 14 + 4 * sectors_count, 15 + 4 * sectors_count, 10 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [10 + 4 * sectors_count, 15 + 4 * sectors_count, 12 + 4 * sectors_count, 8 + 4 * sectors_count]});

        // second end
        // 16+4*sectors_count-19+4*sectors_count
        vertices.push(['dim_G * ($D() - 1) - (dim_E - 3) * $G()', 'dim_G * $G() + dim_F + (dim_E - 3) * $D()', '0']);
        vertices.push(['dim_G * ($D() - 1) - (dim_E - 3) * $G()', 'dim_G * $G() + dim_F + (dim_E - 3) * $D()', 'dim_B']);
        vertices.push(['(dim_G + dim_A) * $D() - dim_G - (dim_E - 3) * $G()', '(dim_G + dim_A) * $G() + dim_F + (dim_E - 3) * $D()', '0']);
        vertices.push(['(dim_G + dim_A) * $D() - dim_G - (dim_E - 3) * $G()', '(dim_G + dim_A) * $G() + dim_F + (dim_E - 3) * $D()', 'dim_B']);
        planes.push({materials: [0, 1], vertices: [16 + 4 * sectors_count, 17 + 4 * sectors_count, 7 + 2 * sectors_count, 6 + 2 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [6 + 4 * sectors_count, 7 + 4 * sectors_count, 19 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [6 + 2 * sectors_count, 6 + 4 * sectors_count, 18 + 4 * sectors_count, 16 + 4 * sectors_count]});
        planes.push({materials: [0, 1], vertices: [7 + 2 * sectors_count, 17 + 4 * sectors_count, 19 + 4 * sectors_count, 7 + 4 * sectors_count]});

        // finishing part 2
        // 20+4*sectors_count-23+4*sectors_count
        vertices.push(['dim_G * ($D() - 1) - dim_E * $G()', 'dim_G * $G() + dim_F + dim_E * $D()', '0']);
        vertices.push(['dim_G * ($D() - 1) - dim_E * $G()', 'dim_G * $G() + dim_F + dim_E * $D()', 'dim_B']);
        vertices.push(['(dim_G + dim_A) * $D() - dim_G - dim_E * $G()', '(dim_G + dim_A) * $G() + dim_F + dim_E * $D()', '0']);
        vertices.push(['(dim_G + dim_A) * $D() - dim_G - dim_E * $G()', '(dim_G + dim_A) * $G() + dim_F + dim_E * $D()', 'dim_B']);
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 21 + 4 * sectors_count, 17 + 4 * sectors_count, 16 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 23 + 4 * sectors_count, 19 + 4 * sectors_count, 18 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 16 + 4 * sectors_count, 18 + 4 * sectors_count, 22 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [21 + 4 * sectors_count, 17 + 4 * sectors_count, 19 + 4 * sectors_count, 23 + 4 * sectors_count]});

        // 24+4*sectors_count-27+4*sectors_count
        vertices.push(['(dim_G - 3) * $D() - dim_G - dim_E * $G()', '(dim_G - 3) * $G() + dim_F + dim_E * $D()', '-3']);
        vertices.push(['(dim_G - 3) * $D() - dim_G - dim_E * $G()', '(dim_G - 3) * $G() + dim_F + dim_E * $D()', 'dim_B + 3']);
        vertices.push(['(dim_G + dim_A + 3) * $D() - dim_G - dim_E * $G()', '(dim_G + dim_A + 3) * $G() + dim_F + dim_E * $D()', '-3']);
        vertices.push(['(dim_G + dim_A + 3) * $D() - dim_G - dim_E * $G()', '(dim_G + dim_A + 3) * $G() + dim_F + dim_E * $D()', 'dim_B + 3']);
        planes.push({materials: [2], vertices: [20 + 4 * sectors_count, 24 + 4 * sectors_count, 26 + 4 * sectors_count, 22 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [22 + 4 * sectors_count, 26 + 4 * sectors_count, 27 + 4 * sectors_count, 23 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [23 + 4 * sectors_count, 27 + 4 * sectors_count, 25 + 4 * sectors_count, 21 + 4 * sectors_count]});
        planes.push({materials: [2], vertices: [21 + 4 * sectors_count, 25 + 4 * sectors_count, 24 + 4 * sectors_count, 20 + 4 * sectors_count]});

        // arrows
        // A 28+4*sectors_count-29+4*sectors_count + 17 + 19
        vertices.push(['dim_G * ($D() - 1) - (dim_E - 3) * $G()', 'dim_G * $G() + dim_F + (dim_E - 3) * $D()', 'dim_B + 10']);
        vertices.push(['(dim_G + dim_A) * $D() - dim_G - (dim_E - 3) * $G()', '(dim_G + dim_A) * $G() + dim_F + (dim_E - 3) * $D()', 'dim_B + 10']);

        // B 30+4*sectors_count-31+4*sectors_count + 1-5
        vertices.push(['dim_C + 10', '3', '0']);
        vertices.push(['dim_C + 10', '3', 'dim_B']);

        // E 32+4*sectors_count-34+4*sectors_count + 43+4*sectors_count
        vertices.push(['(dim_G - 3) * $D() - dim_G - dim_E * $G()', '(dim_G - 3) * $G() + dim_F + dim_E * $D()', 'dim_B']);
        vertices.push(['(dim_G - 10) * $D() - dim_G - dim_E * $G()', '(dim_G - 10) * $G() + dim_F + dim_E * $D()', 'dim_B']);
        vertices.push(['(dim_G - 10) * $D() - dim_G', '(dim_G - 10) * $G() + dim_F', 'dim_B']);

        // F 35+4*sectors_count-37+4*sectors_count + 3
        vertices.push(['-10', 'dim_F', 'dim_B']);
        vertices.push(['-10', '0', 'dim_B']);
        vertices.push(['-3', '0', 'dim_B']);

        // G 38+4*sectors_count-40+4*sectors_count + 8+sectors_count
        vertices.push(['-dim_G', 'dim_F', 'dim_B']);
        vertices.push(['-dim_G', 'dim_F', 'dim_B + 10']);
        vertices.push(['dim_G * (Math.cos(Math.PI / 180 * dim_D / 2) - 1)', 'dim_G * Math.sin(Math.PI / 180 * dim_D / 2) + dim_F', 'dim_B + 10']);

        // C 41+4*sectors_count-42+4*sectors_count + 4-5
        vertices.push(['0', '3', 'dim_B + 10']);
        vertices.push(['dim_C', '3', 'dim_B + 10']);

        // extra vertex for E arrow 43+4*sectors_count
        vertices.push(['dim_G * $D() - dim_G', 'dim_G * $G() + dim_F', 'dim_B']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'],
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'G': empty_vals['G'] / 10
        };

        const arrows = {
            'A': [17 + 4 * sectors_count, 28 + 4 * sectors_count, 29 + 4 * sectors_count, 19 + 4 * sectors_count],
            'B': [1, 30 + 4 * sectors_count, 31 + 4 * sectors_count, 5],
            'C': [4, 41 + 4 * sectors_count, 42 + 4 * sectors_count, 5],
            'E': [32 + 4 * sectors_count, 33 + 4 * sectors_count, 34 + 4 * sectors_count, 43 + 4 * sectors_count],
            'F': [37 + 4 * sectors_count, 36 + 4 * sectors_count, 35 + 4 * sectors_count, 7],
            'G': [38 + 4 * sectors_count, 39 + 4 * sectors_count, 40 + 4 * sectors_count, 7 + sectors_count],
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        // this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Łuk asymetryczny';
        //this.cache.print();
    }

    setDimensionValue(name, value) {
        if(name == 'D') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'D') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];
        let g = this.dimensions['G'];
        let r = (g + Math.min(a, c)) * ((g + Math.min(a, c)) / Math.cos(Math.PI / 180 * d) - (g + Math.max(a, c))) / ((g + Math.min(a, c)) / Math.cos(Math.PI / 180 * d) - (g + Math.min(a, c)));

        if(r < 0) {
            return [false, 1];
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Nieprawidłowa kombinacja wymiarów A, C, D i G. Wprowadzoe wymiary nie pozwalają na skonstruowanie łuku. Spróbuj zmniejszyć różnicę pomiędzy wymiarem A i C lub zwiększyć kąt.";
        }
        return "";
    }
    
}