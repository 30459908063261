import { Model } from "../model";
import { Cache } from "../cache";

export class PrzylaczeElastyczneOkragle extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const rubber_width = 7;
        const metal_width = 4;
        const sectors_count = 100;

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': default_vals['B']
        };

        const arrows = {
            'A': [8 * sectors_count, 8 * sectors_count + 1, 8 * sectors_count + 2, 8 * sectors_count + 3]
        };
        const functions = [
            {name: '$A', params: ['x', 'y'], expression: 'x * Math.sin(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_A / 2'},
            {name: '$B', params: ['x', 'y'], expression: 'x * Math.cos(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2'},
        ];
        super([], [], dimensions, arrows, [], options);

        this.addVerticesCircle('dim_A + 3', '0', sectors_count);
        this.addVerticesCircle('dim_A', '0', sectors_count);
        this.connectCircles(0, 1, [2], sectors_count);
        this.addVerticesCircle('dim_A', '3', sectors_count);
        this.connectCircles(1, 2, [2], sectors_count);
        this.addVerticesCircle('dim_A', metal_width.toString(), sectors_count);
        this.connectCircles(2, 3, [0, 1], sectors_count);
        this.addVerticesCircle('dim_A', (metal_width + rubber_width).toString(), sectors_count);
        this.connectCircles(3, 4, [3], sectors_count);
        this.addVerticesCircle('dim_A', (2 * metal_width + rubber_width - 3).toString(), sectors_count);
        this.connectCircles(4, 5, [0, 1], sectors_count);
        this.addVerticesCircle('dim_A', (2 * metal_width + rubber_width).toString(), sectors_count);
        this.connectCircles(5, 6, [2], sectors_count);
        this.addVerticesCircle('dim_A + 3', (2 * metal_width + rubber_width).toString(), sectors_count);
        this.connectCircles(6, 7, [2], sectors_count);
        this.vertices.push(['0', '0', (2 * metal_width + rubber_width).toString()]);
        this.vertices.push(['0', '0', (2 * metal_width + rubber_width + 10).toString()]);
        this.vertices.push(['dim_A', '0', (2 * metal_width + rubber_width + 10).toString()]);
        this.vertices.push(['dim_A', '0', (2 * metal_width + rubber_width).toString()]);
        this.name = 'Przyłącze elastyczne okrągłe';
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
    }

    addVerticesCircle(diameter, z, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            this.vertices.push(['$A(' + diameter + ', ' + i + ')', '$B(' + diameter + ', ' + i + ')', z]);
        }
    }

    connectCircles(circle_1, circle_2, materials, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            this.planes.push({materials: materials, vertices: [circle_1 * sectors_count + (i % sectors_count), circle_1 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + (i % sectors_count)]});
        }
    }

    setDimensionValue(name, value) {
        if(name == 'B') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'B') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'B') {
            return this.options[name][this.last_correct_dimensions[name]];
        }
        return this.last_correct_dimensions[name] * 10;
    }
}