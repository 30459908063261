import { Model } from "../model";
import { Cache } from "../cache";

export class TlumikProstokatny extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],

            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],

            ['0', '0', 'dim_C - 3'],
            ['dim_A', '0', 'dim_C - 3'],
            ['dim_A', 'dim_B', 'dim_C - 3'],
            ['0', 'dim_B', 'dim_C - 3'],

            ['0', '0', 'dim_C'],
            ['dim_A', '0', 'dim_C'],
            ['dim_A', 'dim_B', 'dim_C'],
            ['0', 'dim_B', 'dim_C'],

            ['-3', '-3', 'dim_C'],
            ['dim_A + 3', '-3', 'dim_C'],
            ['dim_A + 3', 'dim_B + 3', 'dim_C'],
            ['-3', 'dim_B + 3', 'dim_C'],

            //arrow A
            ['0', 'dim_B / 2', 'dim_C'],
            ['0', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C'],
            //arrow B
            ['dim_A / 2', 'dim_B', 'dim_C'],
            ['dim_A / 2', 'dim_B', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C'],
            //arrow C
            ['dim_A / 2', 'dim_B + 3', '0'],
            ['dim_A / 2', 'dim_B + 10', '0'],
            ['dim_A / 2', 'dim_B + 10', 'dim_C'],
            ['dim_A / 2', 'dim_B + 3', 'dim_C']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},
            {materials: [2], vertices: [8, 9, 5, 4]},
            {materials: [2], vertices: [9, 10, 6, 5]},
            {materials: [2], vertices: [10, 11, 7, 6]},
            {materials: [2], vertices: [11, 8, 4, 7]},

            {materials: [0, 1], vertices: [12, 13, 9, 8]},
            {materials: [0, 1], vertices: [13, 14, 10, 9]},
            {materials: [0, 1], vertices: [14, 15, 11, 10]},
            {materials: [0, 1], vertices: [15, 12, 8, 11]},

            {materials: [2], vertices: [12, 13, 17, 16]},
            {materials: [2], vertices: [13, 14, 18, 17]},
            {materials: [2], vertices: [14, 15, 19, 18]},
            {materials: [2], vertices: [15, 12, 16, 19]},
            {materials: [2], vertices: [16, 17, 21, 20]},
            {materials: [2], vertices: [17, 18, 22, 21]},
            {materials: [2], vertices: [18, 19, 23, 22]},
            {materials: [2], vertices: [19, 16, 20, 23]}
        ];

        const holes = [];
        const dist = [0, 25, 40, 60, 80, 100, 120, 140];
        const functions = [];
        for(let i = 0; i < 8; i++) {
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D', '0', '3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D + dim_D', '0', '3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D + dim_D', 'dim_B', '3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D', 'dim_B', '3']);

            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D', '0', 'dim_C - 3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D + dim_D', '0', 'dim_C - 3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D + dim_D', 'dim_B', 'dim_C - 3']);
            vertices.push([(i + 1).toString() + ' * (dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + ' + i.toString() + ' * dim_D', 'dim_B', 'dim_C - 3']);

            planes.push({materials: [0, 1], vertices: [36 + 8 * i, 36 + 8 * i + 1, 36 + 8 * i + 2, 36 + 8 * i + 3]});
            planes.push({materials: [0, 1], vertices: [36 + 8 * i + 7, 36 + 8 * i + 6, 36 + 8 * i + 5, 36 + 8 * i + 4]});
            planes.push({materials: [5], vertices: [36 + 8 * i + 3, 36 + 8 * i + 7, 36 + 8 * i + 4, 36 + 8 * i]});
            planes.push({materials: [5], vertices: [36 + 8 * i + 5, 36 + 8 * i + 6, 36 + 8 * i + 2, 36 + 8 * i + 1]});

            holes.push({plane_index: (20 + 4 * i).toString(), visible: 'dim_A > ' + dist[i]});
            holes.push({plane_index: (20 + 4 * i + 1).toString(), visible: 'dim_A > ' + dist[i]});
            holes.push({plane_index: (20 + 4 * i + 2).toString(), visible: 'dim_A > ' + dist[i]});
            holes.push({plane_index: (20 + 4 * i + 3).toString(), visible: 'dim_A > ' + dist[i]});
        }

        //arrow D
        vertices.push(['(dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1)', 'dim_B / 2', 'dim_C - 3']);
        vertices.push(['(dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1)', 'dim_B / 2', 'dim_C + 10']);
        vertices.push(['(dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + dim_D', 'dim_B / 2', 'dim_C + 10']);
        vertices.push(['(dim_A - (dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) * dim_D) / ((dim_A <= 25 ? 1 : (dim_A <= 40 ? 2 : (dim_A <= 60 ? 3 : (dim_A <= 80 ? 4 : (dim_A <= 100 ? 5 : (dim_A <= 120 ? 6 : (dim_A <= 140 ? 7 : 8))))))) + 1) + dim_D', 'dim_B / 2', 'dim_C - 3']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31],
            'C': [32, 33, 34, 35],
            'D': [100, 101, 102, 103]
        };

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Tłumik prostokątny';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let d = this.dimensions['D'];

        let n = (a <= 25 ? 1 : (a <= 40 ? 2 : (a <= 60 ? 3 : (a <= 80 ? 4 : (a <= 100 ? 5 : (a <= 120 ? 6 : (a <= 140 ? 7 : 8)))))));
        if(a <= n * d) {
            return [false, 1];
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            let a = this.dimensions['A'];
            let d = this.dimensions['D'];
            let n = (a <= 25 ? 1 : (a <= 40 ? 2 : (a <= 60 ? 3 : (a <= 80 ? 4 : (a <= 100 ? 5 : (a <= 120 ? 6 : (a <= 140 ? 7 : 8)))))));
            return "Wymiar D jest zbyt duży w stosunku do wymiaru A. Zgodnie z normą liczba kulis dla tłumika o szerokości " + (a * 10) + "mm wynosi: " + n + ". Zmniejsz wymiar D, lub powiększ wymiar A.";
        }
        return "";
    }
}