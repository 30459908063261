import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParamDef } from '../../../models/param-def';
import { ParamConf } from '../../../models/param-conf';

@Component({
    selector: 'app-param',
    template: `<div>Bazowy parametr - nie używać</div>`
})
export class ParamComponent implements OnInit {

    @Input()
    paramConfig: ParamConf;

    @Input()
    formSubmitted: boolean;

    // ...
    @Input() 
    selectedParamId: number = null;

    @Input()
    layoutMode: "param-wide" | "param-narrow" = "param-narrow";

    @Output()
    focus: EventEmitter<ParamDef> = new EventEmitter<ParamDef>();

    ngOnInit(): void { }

    public showError(): boolean {
        return (this.paramConfig.control.dirty || this.formSubmitted) && this.paramConfig.control.invalid;
    }

    public getError(): string {
        return "Błąd";
    }

    public getClasses(): string {
        return this.layoutMode;
    }

    onFocus() {
        this.focus.emit(this.paramConfig.param);
    }

}
