import { Observable, of } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { InquiriesService } from "../services/inquiries.service";

export function inquiryInitializer(inquiriesService: InquiriesService) {
    return () => {
        return inquiriesService.retriveInquiry().pipe(
            tap(
                () => console.info("inquiryInitializer: inicjalizacja zapytania wykonana.")
            ) 
        )
    };
}