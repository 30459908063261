import { Model } from "../model";
import { Cache } from "../cache";

export class RedukcjaAsymetryczna extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_F', '0'],
            ['dim_A', 'dim_F', '0'],
            ['dim_A', 'dim_F', 'dim_B'],
            ['0', 'dim_F', 'dim_B'],

            ['dim_H', 'dim_E - dim_G', 'dim_I'],
            ['dim_C + dim_H', 'dim_E - dim_G', 'dim_I'],
            ['dim_C + dim_H', 'dim_E - dim_G', 'dim_D + dim_I'],
            ['dim_H', 'dim_E - dim_G', 'dim_D + dim_I'],

            ['dim_H', 'dim_E - 3', 'dim_I'],
            ['dim_C + dim_H', 'dim_E - 3', 'dim_I'],
            ['dim_C + dim_H', 'dim_E - 3', 'dim_D + dim_I'],
            ['dim_H', 'dim_E - 3', 'dim_D + dim_I'],

            ['dim_H', 'dim_E', 'dim_I'],
            ['dim_C + dim_H', 'dim_E', 'dim_I'],
            ['dim_C + dim_H', 'dim_E', 'dim_D + dim_I'],
            ['dim_H', 'dim_E', 'dim_D + dim_I'],

            ['dim_H - 3', 'dim_E', 'dim_I - 3'],
            ['dim_C + dim_H + 3', 'dim_E', 'dim_I - 3'],
            ['dim_C + dim_H + 3', 'dim_E', 'dim_D + dim_I + 3'],
            ['dim_H - 3', 'dim_E', 'dim_D + dim_I + 3'],

            // arrows
            // A 32-33
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],

            // B 34-35
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],

            // C 36-37
            ['dim_H', 'dim_E - dim_G', 'dim_I + dim_D + 10'],
            ['dim_C + dim_H', 'dim_E - dim_G', 'dim_I + dim_D + 10'],

            // D 38-39
            ['dim_H + dim_C + 10', 'dim_E - dim_G', 'dim_I'],
            ['dim_H + dim_C + 10', 'dim_E - dim_G', 'dim_D + dim_I'],

            // E 40-43
            ['dim_A + 3', '0', 'dim_B / 2'],
            ['Math.max(dim_C + dim_H, dim_A) + 10', '0', 'dim_B / 2'],
            ['Math.max(dim_C + dim_H, dim_A) + 10', 'dim_E', 'dim_B / 2'],
            ['dim_C + dim_H + 3', 'dim_E', 'dim_B / 2'],

            // F 44-46
            ['dim_A + 10', '0', 'dim_B / 2'],
            ['dim_A + 10', 'dim_F', 'dim_B / 2'],
            ['dim_A', 'dim_F', 'dim_B / 2'],

            // G 47-50
            ['dim_C + dim_H', 'dim_E - dim_G', 'dim_I + dim_D / 2'],
            ['dim_C + dim_H + 10', 'dim_E - dim_G', 'dim_I + dim_D / 2'],
            ['dim_C + dim_H + 10', 'dim_E', 'dim_I + dim_D / 2'],
            ['dim_C + dim_H + 3', 'dim_E', 'dim_I + dim_D / 2'],

            // H 51-52
            ['0', 'dim_E - dim_G', 'dim_D + dim_I + 10'],
            ['0', 'dim_E - dim_G', 'dim_D + dim_I'],

            // I 53-54
            ['dim_H + dim_C + 10', 'dim_E - dim_G', '0'],
            ['dim_H + dim_C', 'dim_E - dim_G', '0'],
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [0, 1], vertices: [12, 13, 17, 16]},
            {materials: [0, 1], vertices: [13, 14, 18, 17]},
            {materials: [0, 1], vertices: [14, 15, 19, 18]},
            {materials: [0, 1], vertices: [15, 12, 16, 19]},

            {materials: [0, 1], vertices: [16, 17, 21, 20]},
            {materials: [0, 1], vertices: [17, 18, 22, 21]},
            {materials: [0, 1], vertices: [18, 19, 23, 22]},
            {materials: [0, 1], vertices: [19, 16, 20, 23]},

            {materials: [2], vertices: [20, 21, 25, 24]},
            {materials: [2], vertices: [21, 22, 26, 25]},
            {materials: [2], vertices: [22, 23, 27, 26]},
            {materials: [2], vertices: [23, 20, 24, 27]},

            {materials: [2], vertices: [24, 25, 29, 28]},
            {materials: [2], vertices: [25, 26, 30, 29]},
            {materials: [2], vertices: [26, 27, 31, 30]},
            {materials: [2], vertices: [27, 24, 28, 31]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10,
            'F': empty_vals['F'] / 10,
            'G': empty_vals['G'] / 10,
            'H': empty_vals['H'] / 10,
            'I': empty_vals['I'] / 10
        };

        const arrows = {
            'A': [11, 32, 33, 10],
            'B': [9, 34, 35, 10],
            'C': [19, 36, 37, 18],
            'D': [17, 38, 39, 18],
            'E': [40, 41, 42, 43],
            'F': [40, 44, 45, 46],
            'G': [47, 48, 49, 50],
            'H': [19, 36, 51, 52],
            'I': [17, 38, 53, 54],
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Redukcja asymetryczna';
    }

    isCorrect() {
        let e = this.dimensions['E'];
        let f = this.dimensions['F'];
        let g = this.dimensions['G'];

        if(f + g > e) {
            return [false, 1];
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Suma wymiarów F i G jest większa od wymiaru E. Zmniejsz wymiary F lub G, lub powiększ wymiar E.";
        }
        return "";
    }
}