import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ToastModalComponent } from './components/toast-modal/toast-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';



@NgModule({
    declarations: [
        ModalComponent,
        ToastModalComponent,
        ConfirmModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ModalComponent,
        ToastModalComponent,
        ConfirmModalComponent
    ]
})
export class ModalsModule { }
