// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const APP_URL = "http://127.0.0.1:8000";
//const APP_URL = "https://pdddev.api.aeronic.com.pl";
const APP_URL = "https://pdddev.api.aeronic.com.pl";

export const environment = {
    production: false,
    version: "0.38.0",
    env: "local",
    
    username: "test",
    password: "testxxxx",
    
    imagesURL: `${APP_URL}`,
    modelsURL: 'assets/models', //`${APP_URL}`,
    apiURL: `${APP_URL}/api`,
    shapeTemplatesApiURL: `${APP_URL}/api/shape_templates`,
    inquiryApiURL: `${APP_URL}/api/inquiries`, 
    customersApiURL: `${APP_URL}/api/customers`, 
    offersApiURL: `${APP_URL}/api/offers_by_key`, 
    ordersApiURL: `${APP_URL}/api/orders`, 
};

import { TestBed } from '@angular/core/testing';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.