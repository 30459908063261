import { ComponentRef } from '@angular/core';
import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { ModalConfig } from '../models/modal-config';

@Injectable({
    providedIn: 'root'
})
export class ModalsService {
    private containerRef: ViewContainerRef;
    private timer: any;

    constructor(protected componentFactoryResolver: ComponentFactoryResolver) { }
    
    /**
     * Ustaw kontener dla modali
     * 
     * @param containerRef
     */
    set modalsContainer(containerRef: ViewContainerRef) {
        if(!containerRef) {
            throw new Error("Inicjalizacja serwisu ModalsService: nieprawidłowa referencja ViewContainerRef: " +  containerRef);
        }

        this.containerRef = containerRef;
    }

    /**
     * Pokaż modal (jeżeli inny modal był otwarty - zamknij go)
     * 
     * @param modalConfig 
     */
    showModal(modalConfig: ModalConfig) { 
        if(this.containerRef && modalConfig) {
            this.closeModal();

            const factory = this.componentFactoryResolver.resolveComponentFactory(modalConfig.type);
            const componentRef: ComponentRef<any> = this.containerRef.createComponent(factory);
            
            componentRef.instance.data = modalConfig.data;
            componentRef.instance.action = modalConfig.action ? modalConfig.action : null;
            componentRef.instance.params = modalConfig.params;

            componentRef.changeDetectorRef.detectChanges();
            componentRef.instance.close.subscribe(
                (params?: {
                    action?: (params?: any) => {},
                    params: any
                }) => { 
                    this.closeModal();
                    if(params && params.action) {
                        if(params.params) {
                            params.action(params.params);
                        } else {
                            params.action(params.params);
                        }
                    } 
                });

            if(modalConfig.time! != 0) this.setTimer(modalConfig.time);

        } else {
            console.log("Nieudane podłączenie okna modalnego.");
        }
    }

    /**
     * Ustawia timer zamykający okno modala
     * 
     * @param time 
     */
    private setTimer(time: number):void  {
        if(this.timer) window.clearTimeout(this.timer);
        this.timer = setTimeout(() => this.closeModal(), time, this);
    }

    /**
     * Zamknij otwarty modal
     */
    public closeModal():void {
        if(this.timer) window.clearTimeout(this.timer);
        this.containerRef.clear();
    }
}
