import { Model } from "../model";
import { Cache } from "../cache";

export class RamkaZSiatka extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],

            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],
            //arrow A
            ['0', 'dim_B / 2', '3'],
            ['0', 'dim_B / 2', '3 + 10'],
            ['dim_A', 'dim_B / 2', '3 + 10'],
            ['dim_A', 'dim_B / 2', '3'],
            //arrow B
            ['dim_A / 2', 'dim_B', '3'],
            ['dim_A / 2', 'dim_B', '3 + 10'],
            ['dim_A / 2', '0', '3 + 10'],
            ['dim_A / 2', '0', '3'],
        ];

        const planes = [
            {materials: [2], vertices: [4, 5, 1, 0]},
            {materials: [2], vertices: [5, 6, 2, 1]},
            {materials: [2], vertices: [6, 7, 3, 2]},
            {materials: [2], vertices: [7, 4, 0, 3]},


            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]}
        ];

        const delta = 5;
        const width = 0.3;
        const holes = [];
        const functions = [
            {name: '$A', params: ['x'], expression: 'dim_A - ' + delta + ' > x * ' + delta},
            {name: '$B', params: ['x'], expression: 'dim_B - ' + delta + ' > x * ' + delta},
        ];

        let v = vertices.length;
        let p = planes.length;
        for(let i = 0; i < 350 / delta; i++) {
            vertices.push([(delta - width + i * delta).toString(), '0', '0']);
            vertices.push([((delta - width + i * delta) + width).toString(), '0', '0']);
            vertices.push([((delta - width + i * delta) + width).toString(), '0', width.toString()]);
            vertices.push([(delta - width + i * delta).toString(), '0', width.toString()]);

            vertices.push([(delta - width + i * delta).toString(), 'dim_B', '0']);
            vertices.push([((delta - width + i * delta) + width).toString(), 'dim_B', '0']);
            vertices.push([((delta - width + i * delta) + width).toString(), 'dim_B', width.toString()]);
            vertices.push([(delta - width + i * delta).toString(), 'dim_B', width.toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$A(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$A(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$A(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$A(' + i + ')'});
        }

        v = vertices.length;
        p = planes.length;
        for(let i = 0; i < 350 / delta; i++) {
            vertices.push(['0', (delta - width + i * delta).toString(), '0']);
            vertices.push(['0', ((delta - width + i * delta) + width).toString(), '0']);
            vertices.push(['0', ((delta - width + i * delta) + width).toString(), width.toString()]);
            vertices.push(['0', (delta - width + i * delta).toString(), width.toString()]);

            vertices.push(['dim_A', (delta - width + i * delta).toString(), '0']);
            vertices.push(['dim_A', ((delta - width + i * delta) + width).toString(), '0']);
            vertices.push(['dim_A', ((delta - width + i * delta) + width).toString(), width.toString()]);
            vertices.push(['dim_A', (delta - width + i * delta).toString(), width.toString()]);

            planes.push({materials: [3], vertices: [v + 8 * i, v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 1]});
            planes.push({materials: [3], vertices: [v + 8 * i + 1, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 2]});
            planes.push({materials: [3], vertices: [v + 8 * i + 2, v + 8 * i + 6, v + 8 * i + 7, v + 8 * i + 3]});
            planes.push({materials: [3], vertices: [v + 8 * i + 3, v + 8 * i + 7, v + 8 * i + 4, v + 8 * i + 0]});

            holes.push({plane_index: (p + 4 * i).toString(), visible: '$B(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 1).toString(), visible: '$B(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 2).toString(), visible: '$B(' + i + ')'});
            holes.push({plane_index: (p + 4 * i + 3).toString(), visible: '$B(' + i + ')'});
        }


        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10
        };

        const arrows = {
            'A': [12, 13, 14, 15],
            'B': [16, 17, 18, 19]
        };



        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.name = 'Ramka z siatką';
    }
}