import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';


import { Customer } from 'projects/base-lib/src/lib/modules/inquiries/models/customer';
import { LocalStorageService } from 'projects/base-lib/src/public-api';

const CUSTOMER_KEY_LS_KEY: string = "PDD_CUSTOMER_ID";

@Injectable({
    providedIn: 'root'
})
export class CustomersService {
    private customersApiURL = environment.customersApiURL;
    private _customer: Customer;
    
    constructor(
        private localStorage: LocalStorageService,
        private httpClient: HttpClient
    ) { };

    /**
     * Zwraca robocze zapytanie
     */
    public get customer(): Customer {
        return this._customer;
    }

    /**
     * 
     * @returns 
     */
    public retriveCustomer(): Observable<Customer> {
        const customerId = this.localStorage.get(CUSTOMER_KEY_LS_KEY);

        if(customerId && customerId != undefined) {
            return this.getcustomer(customerId).pipe(
                catchError(
                    (error) => {
                        this.localStorage.remove(CUSTOMER_KEY_LS_KEY);
                        return of(null);
                    }
                ),
                map(
                    (response: any) => {
                        return response ? Object.assign(new Customer(), response as Customer) : null;
                    }
                ),
                tap(
                    (customer: Customer) => {
                        this._customer = customer;
                        if(customer) {
                            this.localStorage.set(CUSTOMER_KEY_LS_KEY, String(customer.key));
                        }
                    }
                )
            )
        } else {
            return of(null);
        }
    }   

    /**
     * Wczytaj zapytanie o wskazanym identyfikatorze
     * 
     * @param customerId 
     * @returns Observable<Customer>
     */
    private getcustomer(customerId: string): Observable<Customer> {
        return this.httpClient.get(
            `${this.customersApiURL}/${customerId}`,
            {}
        ).pipe(
            map(
                (responce: any) => responce.data as Customer 
            )
        ) as Observable<Customer>;
    }

    public createCustomer(customer: Customer): Observable<Customer> {
        if(this.customer && this.isSame(customer)) {
            return of(this.customer);
        } else {
            return this.postCustomer(customer).pipe(
                map(
                    (responce: any) => responce.data as Customer 
                ),
                tap(
                    (customer: Customer) => {
                        this._customer = customer;
                        this.localStorage.set(CUSTOMER_KEY_LS_KEY, String(customer.key));
                    }
                )  
            );
        }
    }

    public isSame(customer: Customer): boolean {
        return this.customer 
            && this.customer.name == customer.name
            && this.customer.email == customer.email
            && this.customer.phone == customer.phone
            && this.customer.city == customer.city
            && this.customer.zip_code == customer.zip_code
            && this.customer.street == customer.street
            ? true
            : false;
    }

    /**
     * Utwórz nowe zapytanie i zapamiętaj jego identyfikator w LocalStorage
     * 
     * @returns Observable<Customer>
     */
    private postCustomer(customer: Customer): Observable<Customer> {
        return this.httpClient.post(
            `${this.customersApiURL}`,
            customer
        ) as Observable<Customer>;
    }
}
