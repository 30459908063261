import { Component, Input, OnInit } from '@angular/core';
import { NumericParamConf } from '../../../models/numeric-param-conf';
import { ParamComponent } from '../param/param.component';

@Component({
    selector: 'app-numeric-param',
    templateUrl: './numeric-param.component.html',
    styleUrls: ['./numeric-param.component.scss']
})
export class NumericParamComponent extends ParamComponent implements OnInit {
    
    @Input()
    paramConfig: NumericParamConf;

    public getError(): string {
        if(this.paramConfig.control.errors?.required) {
            return `Parametr "${this.paramConfig.param.name}" jest wymagany`;
        } else if (this.paramConfig.control.errors?.pattern) {
            return `Wprowadź prawidłową liczbę`;
        } else if (this.paramConfig.control.errors?.min || this.paramConfig.control.errors?.max) {
            return `Wymagana wartość w zakresie od ${this.paramConfig.param.min_val} do ${this.paramConfig.param.max_val} mm`;
        }

        return "Sprawdź wprowadzone dane.";
    }

    public getClasses(): string {
        return this.layoutMode + (this.paramConfig && this.selectedParamId == this.paramConfig.param.id ? " param-selected" : "");
    }
}