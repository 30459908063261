import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, filter, find, map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from 'projects/base-lib/src/public-api';
import { Inquiry } from 'projects/base-lib/src/lib/modules/inquiries/models/inquiry';
import { Shape } from 'projects/base-lib/src/lib/modules/shapes/models/shape';
import { CustomShape } from 'projects/base-lib/src/lib/modules/shapes/models/custom-shape';


const INQUIRY_KEY_LS_KEY: string = "PDD_INQUIRY_ID";

@Injectable({
    providedIn: 'root'
})
export class InquiriesService {
    private inquiryApiURL = environment.inquiryApiURL;
    private _inquiry: Inquiry;
    
    constructor(
        private localStorage: LocalStorageService,
        private httpClient: HttpClient
    ) { };

    /**
     * Zwraca robocze zapytanie
     */
    public get inquiry(): Observable<Inquiry> {
        if(this._inquiry) {
            return of(this._inquiry);
        } else {
            return this.retriveInquiry();
        }
    }

    public resetInquiry(): Observable<Inquiry> {
        return this.postInquiry().pipe(
            map(
                (response: any) => {
                    return Object.assign(new Inquiry(), response.data as Inquiry);
                }
            ),
            tap(
                (inquiry: Inquiry) => {
                    !inquiry.shapes ? inquiry.shapes = [] : null;
                    this._inquiry = inquiry;
                    this.localStorage.set(INQUIRY_KEY_LS_KEY, String(inquiry.key));
                }
            )
        )
    }

    /**
     * 
     * @returns 
     */
    public retriveInquiry(): Observable<Inquiry> {
        const inquiryKey = this.localStorage.get(INQUIRY_KEY_LS_KEY);
        const inquiry$: Observable<Inquiry> = inquiryKey ? this.getInquiry(inquiryKey) : this.postInquiry();

        return inquiry$.pipe(
            catchError(
                (error) => {
                    return this.postInquiry();
                }
            ),
            map(
                (response: any) => {
                    return Object.assign(new Inquiry(), response.data as Inquiry);
                }
            ),
            tap(
                (inquiry: Inquiry) => {
                    !inquiry.shapes ? inquiry.shapes = [] : null;
                    this._inquiry = inquiry;
                    this.localStorage.set(INQUIRY_KEY_LS_KEY, String(inquiry.key));
                }
            )
        );
    }   

    /**
     * Wczytaj zapytanie o wskazanym identyfikatorze
     * 
     * @param inquiryId 
     * @returns Observable<Inquiry>
     */
    private getInquiry(inquiryKey: string): Observable<Inquiry> {
        return this.httpClient.get(
            `${this.inquiryApiURL}/${inquiryKey}`,
            {}
        ) as Observable<Inquiry>;
    }

    /**
     * Utwórz nowe zapytanie i zapamiętaj jego identyfikator w LocalStorage
     * 
     * @returns Observable<Inquiry>
     */
    private postInquiry(): Observable<Inquiry> {
        return this.httpClient.post(
            `${this.inquiryApiURL}`,
            {}
        ) as Observable<Inquiry>;
    }

    public addShape(shape: Shape): Observable<Shape> {
        return (this.httpClient.post(
            `${this.inquiryApiURL}/${this._inquiry.key}/shapes`,
            {
                shape_template: shape.shape_template.id,
                count: shape.count,
                comment: shape.comment,
                params: shape.params
            }
        ) as Observable<Shape>).pipe(
            map(
                (response: any) => response.data as Shape
            ),
            tap(
                (shape: Shape) => this._inquiry.shapes.push(shape)
            )
        );
    }

    public addCustomShape(params: any): Observable<CustomShape> {
        let formData = new FormData();
        
        for (var i = 0; i < params.drawingsToUpload.length; i++) {
            formData.append("drawings[]", params.drawingsToUpload[i], params.drawingsToUpload[i].name);
        }

        formData.append("count", params.count);
        formData.append("comment", params.comment);

        return (this.httpClient.post(
            `${this.inquiryApiURL}/${this._inquiry.key}/custom_shapes`,
            formData
        ) as Observable<CustomShape>).pipe(
            map(
                (response: any) => response.data as CustomShape
            ),
            tap(
                (customShape: CustomShape) => this._inquiry.custom_shapes.push(customShape)
            )
        );
    }

    public getShape(shapeId: number): Observable<Shape> {
        const shape: Shape = this._inquiry.shapes.find(
            (shape: Shape) => shape.id == shapeId
        )

        if(!shape) {
            throwError (`'W zamówieniu brak kształtu o identyfikatorze: '${shapeId}`);
        }

        return of(shape);
    }

    public getCustomShape(customShapeId: number): Observable<CustomShape> {
        const customShape: CustomShape = this._inquiry.custom_shapes.find(
            (customShape: CustomShape) => customShape.id == customShapeId
        )

        if(!customShape) {
            throwError (`'W zamówieniu brak kształtu niestandardowego o identyfikatorze: '${customShapeId}`);
        }

        return of(customShape);
    }

    public updateShape(shape: Shape): Observable<Shape> {
        return (this.httpClient.patch(
            `${this.inquiryApiURL}/${this._inquiry.key}/shapes/${shape.id}`,
            {
                shape_template: shape.shape_template.id,
                count: shape.count,
                comment: shape.comment,
                params: shape.params.map(
                    (param) => {
                        return {
                            template_id: param.template_id,
                            value: param.value
                        }
                    }
                )
            }
        ).pipe(
            tap(
                (response: any) => {
                    let oldShape: Shape = this._inquiry.shapes.find(
                        (shapeEl: Shape) => shape.id == shapeEl.id
                    );

                    oldShape = Object.assign(oldShape, shape);
                }
            )
        ) as Observable<Shape>);
    }

    public updateCustomShape(customShape: CustomShape, params: any): Observable<CustomShape> {
        let formData = new FormData();
        
        for (var i = 0; i < params.existingDrawings.length; i++) {
            formData.append("existing_drawings[]", params.existingDrawings[i].id);
        }

        formData.delete('drawings[0]');
        for (var i = 0; i < params.drawingsToUpload.length; i++) {
            formData.append("drawings[]", params.drawingsToUpload[i], params.drawingsToUpload[i].name);
        }

        formData.append("count", params.count);
        formData.append("comment", params.comment);

        return (this.httpClient.post(
            `${this.inquiryApiURL}/${this._inquiry.key}/custom_shapes/${customShape.id}`,
            formData
        ) as Observable<CustomShape>).pipe(
            map(
                (response: any) => response.data as CustomShape
            )
        );
    }

    public deleteShape(shapeId: number): Observable<boolean> {
        return (this.httpClient.delete(
            `${this.inquiryApiURL}/${this._inquiry.key}/shapes/${shapeId}`,
        ) as Observable<boolean>).pipe(
            tap(
                () => this._inquiry.shapes.splice(this._inquiry.shapes.findIndex((shape) => shape.id == shapeId), 1)
            )
        );
    }

    public deleteCustomShape(customShapeId: number): Observable<boolean> {
        return (this.httpClient.delete(
            `${this.inquiryApiURL}/${this._inquiry.key}/custom_shapes/${customShapeId}`,
        ) as Observable<boolean>).pipe(
            tap(
                () => this._inquiry.custom_shapes.splice(this._inquiry.custom_shapes.findIndex((customShape) => customShape.id == customShapeId), 1)
            )
        );
    }

    public patchInquiry(inquiry: Inquiry, data: any): Observable<Inquiry> {
        return this.httpClient.patch(
            `${this.inquiryApiURL}/${inquiry.key}`,
            data
        ) as Observable<Inquiry>;
    }

    

    public mapProperties(paramsIN: any) {
        const paramsOUT = {...paramsIN};

        Object.keys(paramsOUT).forEach(key => {
            if(paramsOUT[key] === true) paramsOUT[key] = 1;
            if(paramsOUT[key] === false) paramsOUT[key] = 0;
        });

        return paramsOUT;
    }
}
