import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShapeTemplate } from 'projects/base-lib/src/lib/modules/shapes/models/shape-template';
import { iif, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShapesService {
    private shapeTemplates: ShapeTemplate[];
    private shapeTemplatesApiURL = environment.shapeTemplatesApiURL;

    constructor(private http: HttpClient) { 
        this.shapeTemplates;
    }

    /**
     * 
     */
    getShapeTemplates(): Observable<ShapeTemplate[]>
    { 
        return (iif(
            () => this.shapeTemplates != null, 
            of(this.shapeTemplates),
            this.http.get(
                `${this.shapeTemplatesApiURL}?only_valid=1`,
                {}
            ).pipe(
                map((response: any) => response.data ),
                tap((response: any) => this.shapeTemplates = response)
            )
        ));
    }

    /**
     * 
     * @param shapeTemplateId 
     * @returns 
     */
    getShapeTemplate(shapeTemplateId: number): Observable<ShapeTemplate | undefined> {
        return of(this.shapeTemplates.find(
            shapeTemplate => shapeTemplate.id == shapeTemplateId
        ));
    }

    getShapeViewerData(fileName: string): Observable<any> {
        return this.http.get(`./assets/data/${fileName}`);
    }
            
}
