import { Model } from "../model";
import { Cache } from "../cache";


export class PodstawaDachowaOkragla extends Model {
    square_height = 0;
    cone_height = 0;
    ring_height = 0;

    constructor(empty_vals, default_vals, options, DATA = null) {
        super([], [], {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': default_vals['C']
        }, {}, [], options);

        const sectors_count = 100;
        const square_height = 5;
        const cone_height = 15;
        const ring_height = 1;
        const cone_lower_delta = 4;
        const ring_delta = 8;
        const square_delta = 10;
        const arc_angle = (Math.PI / 180 * 360).toString();
        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': default_vals['C']
        };

        const arrows = {};
        const functions = [
            {name: '$A', params: ['x', 'y'], expression: 'x * Math.cos(y * ' + arc_angle + ' / ' + sectors_count + ') / 2'},
            {name: '$B', params: ['x', 'y'], expression: 'x * Math.sin(y * ' + arc_angle + ' / ' + sectors_count + ') / 2'},
        ];
        this.addVerticesCircle('dim_A + 6', '0', sectors_count);
        this.addVerticesCircle('dim_A', '0', sectors_count);
        this.connectCircles(0, 1, [2], sectors_count);
        this.addVerticesCircle('dim_A', '3', sectors_count);
        this.connectCircles(1, 2, [2], sectors_count);
        let v = this.vertices.length;
        this.addVerticesCircle('dim_A + ' + cone_lower_delta, 'dim_B - ' + ring_height + ' - ' + cone_height, sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B - ' + ring_height, sectors_count);
        this.connectCircles(3, 4, [0, 1], sectors_count);
        this.connectCircles(2, 4, [0, 1], sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B', sectors_count);
        this.connectCircles(4, 5, [0, 1], sectors_count);
        this.addVerticesCircle('dim_A + ' + ring_delta, 'dim_B - ' + ring_height, sectors_count);
        this.addVerticesCircle('dim_A + ' + ring_delta, 'dim_B', sectors_count);
        this.connectCircles(7, 5, [0, 1], sectors_count);
        this.connectCircles(4, 6, [0, 1], sectors_count);
        this.connectCircles(6, 7, [0, 1], sectors_count);
        v = this.vertices.length;
        this.addVerticesCircle('dim_A', 'dim_B - ' + ring_height + ' - ' + cone_height, sectors_count);
        const circle2 = [];
        for(let i = 0; i < sectors_count; i++) {
            circle2.push(v + i);
        }
        v = this.vertices.length;
        this.vertices.push(['-dim_A / 2 - ' + square_delta, 'dim_B - ' + ring_height + ' - ' + cone_height, '-dim_A / 2 - ' + square_delta]);
        this.vertices.push(['dim_A / 2 + ' + square_delta, 'dim_B - ' + ring_height + ' - ' + cone_height, '-dim_A / 2 - ' + square_delta]);
        this.vertices.push(['dim_A / 2 + ' + square_delta, 'dim_B - ' + ring_height + ' - ' + cone_height, 'dim_A / 2 + ' + square_delta]);
        this.vertices.push(['-dim_A / 2 - ' + square_delta, 'dim_B - ' + ring_height + ' - ' + cone_height, 'dim_A / 2 + ' + square_delta]);

        this.vertices.push(['-dim_A / 2 - ' + square_delta + ' - ' + square_height, 'dim_B - ' + ring_height + ' - ' + cone_height + ' - ' + square_height, '-dim_A / 2 - ' + square_delta + ' - ' + square_height]);
        this.vertices.push(['dim_A / 2 + ' + square_delta + ' + ' + square_height, 'dim_B - ' + ring_height + ' - ' + cone_height + ' - ' + square_height, '-dim_A / 2 - ' + square_delta + ' - ' + square_height]);
        this.vertices.push(['dim_A / 2 + ' + square_delta + ' + ' + square_height, 'dim_B - ' + ring_height + ' - ' + cone_height + ' - ' + square_height, 'dim_A / 2 + ' + square_delta + ' + ' + square_height]);
        this.vertices.push(['-dim_A / 2 - ' + square_delta + ' - ' + square_height, 'dim_B - ' + ring_height + ' - ' + cone_height + ' - ' + square_height, 'dim_A / 2 + ' + square_delta + ' + ' + square_height]);

        let p = this.planes.length;
        this.planes.push({materials: [4], vertices: [v, v + 1, v + 2, v + 3]});
        //this.planes.push({materials: [4], vertices: [v + 4, v + 5, v + 6, v + 7]});

        this.planes.push({materials: [4], vertices: [v, v + 1, v + 5, v + 4]});
        this.planes.push({materials: [4], vertices: [v + 1, v + 2, v + 6, v + 5]});
        this.planes.push({materials: [4], vertices: [v + 2, v + 3, v + 7, v + 6]});
        this.planes.push({materials: [4], vertices: [v + 3, v + 0, v + 4, v + 7]});

        this.holes.push({plane_index: p.toString(), vertices: circle2});
        //this.holes.push({plane_index: (p + 1).toString(), vertices: circle2});

        v = this.vertices.length;
        this.vertices.push(['-dim_A / 2', '0', '0']);
        this.vertices.push(['-dim_A / 2', '-10', '0']);
        this.vertices.push(['dim_A / 2', '-10', '0']);
        this.vertices.push(['dim_A / 2', '0', '0']);
        this.arrows.A = [v, v + 1, v + 2, v + 3];

        this.vertices.push(['dim_A / 2 + 3', '0', '0']);
        this.vertices.push(['dim_A / 2 + ' + square_delta + ' + ' + square_height + ' + 10', '0', '0']);
        this.vertices.push(['dim_A / 2 + ' + square_delta + ' + ' + square_height + ' + 10', 'dim_B', '0']);
        this.vertices.push(['dim_A / 2 + ' + ring_delta + ' / 2', 'dim_B', '0']);
        this.arrows.B = [v + 4, v + 5, v + 6, v + 7];

        this.square_height = square_height;
        this.cone_height = cone_height;
        this.ring_height = ring_height;

        this.name = 'Podstawa dachowa okrągła';

        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
    }

    addVerticesCircle(diameter, z, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            this.vertices.push(['$A(' + diameter + ', ' + i + ')', z, '$B(' + diameter + ', ' + i + ')']);
        }
    }

    connectCircles(circle_1, circle_2, materials, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            this.planes.push({materials: materials, vertices: [circle_1 * sectors_count + (i % sectors_count), circle_1 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + ((i + 1) % sectors_count), circle_2 * sectors_count + (i % sectors_count)]});
        }
    }

    setDimensionValue(name, value) {
        if(name == 'C') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'C') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    isCorrect() {
        let b = this.dimensions['B'];
        if(b <= this.ring_height + this.cone_height + this.square_height + 3) {
            return [false, 1];
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Wymiar B jest zbyt mały. Powinien wynosić minimum: " + (10 * (this.ring_height + this.cone_height + this.square_height + 3)) + "mm. Zwiększ wymiar B.";
        }
        return "";
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'C') {
            return this.options[name][this.last_correct_dimensions[name]];
        }
        return this.last_correct_dimensions[name] * 10;
    }
}