import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { BasePanelViewComponent } from "projects/base-lib/src/lib/components/base-panel-view.component";
import { ModalsService } from "../../../modals/services/modal.service";
import { NumericParamConf } from "../../models/numeric-param-conf";
import { NumericParamDef } from "../../models/numeric-param-def";
import { OptionsParamConf } from "../../models/options-param-conf";
import { OptionsParamDef } from "../../models/options-param-def";
import { ParamConf } from "../../models/param-conf";
import { Shape } from "../../models/shape";
import { ShapeTemplate } from "../../models/shape-template";

const PARAM_ID_PREFIX: string = "param_";

@Component({
    template: `
        <div>Klasa bazowa - nie używać bezpośrednio</div>
    `
})
export class ShapeEditorComponent extends BasePanelViewComponent implements OnInit {

    public mode: "create" | "update" = "create"; 

    public shape: Shape;
    public shapeTemplate: ShapeTemplate;

    public form: UntypedFormGroup;
    public countFC: UntypedFormControl;
    public commentFC: UntypedFormControl;
    public metalSheetTypeFC: UntypedFormControl;
    public priceFC: UntypedFormControl;
    public formConfig: any;
    public formSubmitted: boolean = false;

    constructor(
        protected modalsService: ModalsService,
        protected viewportScroller: ViewportScroller,
    ) {
        super(modalsService, viewportScroller);
    }

    ngOnInit(): void { 
        this.init();
        this.createForm();
        this.afterInit();
    }

    public getImg(): string {
        return Shape.getImg(this.shape);
    }

    protected init() { }

    protected afterInit() { }

    protected createForm() {
        let formConfig: (NumericParamConf | OptionsParamConf)[] = [];
        
        this.shape.shape_template.param_templates.forEach(
            (param: any) => {
                switch(param.type) {
                    case "numeric":
                        // Utwórz parametr liczbowy
                        formConfig.push(this.createNumericParam(param as NumericParamDef));
                        break;
                    case "options":
                        // Utwórz parametr listy opcji
                        formConfig.push(this.createOptionsParam(param as OptionsParamDef));
                        break;
                    default:
                        // Zgłoś wyjątek - nieznany typ parametru.
                }
            }
        );

        
        this.form = this.prepareForm(formConfig);
        this.createActivations(formConfig, this.form);

        this.formConfig = formConfig;
    }

    protected getParamValue(paramId: number) {
        const param: any = this.shape.params.find(
            (param: any) => param.template_id == paramId
        );

        return param ? param.value : null; 
    }

    protected createNumericParam(param: NumericParamDef): NumericParamConf {
        // console.log("param.id", param.id);

        let value = this.getParamValue(param.id);

        value = value ? value : param.default_val != null ? param.default_val : "";

        const control: UntypedFormControl = new UntypedFormControl(
            {
                value: value,
                disabled: param.activates_on && param.activates_on.length > 0
            }, 
            [
                Validators.required, 
                Validators.pattern(/^-?(\d+)$/),
                Validators.min(param.min_val), 
                Validators.max(param.max_val)
            ]
        );

        // console.log(param.id);

        return (Object).assign(
            new NumericParamConf(), {
                param: param,
                control: control
            }
        );
    }

    protected createOptionsParam(param: OptionsParamDef): OptionsParamConf {
        let value = this.getParamValue(param.id);

        value = value ? value : param.default_val != null ? param.default_val : "";

        return (Object).assign(
            new OptionsParamConf(), {
                param: param,
                control: new UntypedFormControl(
                    {
                        value: value, //this.getParamValue(param.id),
                        disabled: (!this.getParamValue(param.id) && param.activates_on && param.activates_on.length > 0)
                    }, 
                    [
                        Validators.required
                    ]
                )
            }
        );
    }

    protected prepareForm(formConfig: ParamConf[]): UntypedFormGroup {
        let formGroup = new UntypedFormGroup({});

        formConfig.forEach(
            (paramConf: ParamConf) => {
                formGroup.addControl(
                    PARAM_ID_PREFIX + paramConf.param.id,
                    paramConf.control
                );
            }
        )

        this.countFC = new UntypedFormControl(this.shape.count, [Validators.required, Validators.min(1)]);
        formGroup.addControl(
            "count",
            this.countFC
        );

        this.commentFC = new UntypedFormControl(this.shape.comment, []);
        formGroup.addControl(
            "comment",
            this.commentFC
        );

        this.metalSheetTypeFC = new UntypedFormControl(this.shape.metal_sheet_type ? this.shape.metal_sheet_type.id : null, []);
        formGroup.addControl(
            "metal_sheet_type",
            this.metalSheetTypeFC
        );  

        this.priceFC = new UntypedFormControl(
            // this.shape.price == "0,00" ? null : Number(Number(Number(String(this.shape.price).replace(',', '.')).toFixed(2))), 
            this.shape.price == "0.00" ? null : this.shape.price, 
            []
        );
        formGroup.addControl(
            "price",
            this.priceFC
        );

        return formGroup;
    }

    protected createActivations(formConfig: ParamConf[], form: UntypedFormGroup) {
        

        formConfig.filter(
            (paramConf) => paramConf.param.activates_on && paramConf.param.activates_on.length > 0
        ).forEach(
            (paramConf) => {
                let active: boolean = false;
                let oldValue = null;

                const fields = [];

                paramConf.param.activates_on?.forEach(
                    (activation: {
                        param_id: number,
                        option_id: number
                    }) => {
                        let fieldIndex: number = fields.findIndex((f) => f.param_id == activation.param_id);

                        if(fieldIndex == -1) {
                            fields.push(
                                {
                                    param_id: activation.param_id,
                                    option_ids: [
                                        activation.option_id
                                    ]
                                }
                            )
                        } else {
                            fields[fieldIndex].option_ids.push(activation.option_id);
                        }
                        

                        
                    }
                );

                fields.forEach(
                    (field) => {
                        form.get(PARAM_ID_PREFIX + field.param_id)?.valueChanges.subscribe(
                            (value) => {
                                if(value == oldValue && active) return;
                                oldValue = value;

                                if(field.option_ids.findIndex((item) => item == value) != -1) {
                                    paramConf.control.enable();
                                    active = true;
                                } else {
                                    paramConf.control.reset("");
                                    paramConf.control.disable();
                                }
                            }
                        );
                    }
                )
            }
        );
    }

    public submit(): void {
        this.formSubmitted = true;

        if(this.form.invalid) {
            return;
        }

        this.lock('pending');

        let shape: Shape = {
            id: this.shape.id,
            count: this.countFC.value,
            comment: this.commentFC.value,
            shape_template: this.shape.shape_template,
            params: []
        };


        if(this.mode == "update") {
            shape = Object.assign(
                shape, 
                { 
                    metal_sheet_type: { id: this.metalSheetTypeFC.value },
                    price: this.priceFC.value
                }
            );
        }

        this.shape.shape_template.param_templates.forEach(element => {
            let fc:UntypedFormControl = this.form.get(PARAM_ID_PREFIX + element.id) as UntypedFormControl;
            shape.params.push({
                "template_id": element.id,
                "value": fc.enabled ? Number(fc.value) : null
            });
        });

        this.mode == "create" ? this.create(shape) : this.update(shape);
    }

    protected create(shape: Shape) { } 

    protected update(shape: Shape) { }
}
