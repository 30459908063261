import { Model } from "../model";
import { Cache } from "../cache";

export class TrojnikZOdejsciemOkraglym extends Model {
    //błąd: gdy E == 1 to G jest liczone "od dołu" a nie "od góry"
    //jak wycinać otwór?

    constructor(empty_vals, default_vals, options, DATA = null) {

        const vertices = [
            ['0', '0', '3'],
            ['dim_A', '0', '3'],
            ['dim_A', 'dim_B', '3'],
            ['0', 'dim_B', '3'],
            ['0', '0', 'dim_C - 3'],
            ['dim_A', '0', 'dim_C - 3'],
            ['dim_A', 'dim_B', 'dim_C - 3'],
            ['0', 'dim_B', 'dim_C - 3'],
            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', 'dim_B', '0'],
            ['0', 'dim_B', '0'],
            ['0', '0', 'dim_C'],
            ['dim_A', '0', 'dim_C'],
            ['dim_A', 'dim_B', 'dim_C'],
            ['0', 'dim_B', 'dim_C'],
            ['-3', '-3', '0'],
            ['dim_A + 3', '-3', '0'],
            ['dim_A + 3', 'dim_B + 3', '0'],
            ['-3', 'dim_B + 3', '0'],
            ['-3', '-3', 'dim_C'],
            ['dim_A + 3', '-3', 'dim_C'],
            ['dim_A + 3', 'dim_B + 3', 'dim_C'],
            ['-3', 'dim_B + 3', 'dim_C'],
            //arrow A
            ['0', 'dim_B / 2', 'dim_C'],
            ['0', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C + 10'],
            ['dim_A', 'dim_B / 2', 'dim_C'],
            //arrow B
            ['dim_A / 2', 'dim_B', 'dim_C'],
            ['dim_A / 2', 'dim_B', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C + 10'],
            ['dim_A / 2', '0', 'dim_C'],
            //arrow C
            ['dim_A / 2', 'dim_B + 3', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', '0'],
            ['dim_A / 2', 'dim_B + 3 + 10', 'dim_C'],
            ['dim_A / 2', 'dim_B + 3', 'dim_C']
        ];

        const planes = [
            {materials: [0, 1], vertices: [4, 5, 1, 0]},
            {materials: [0, 1], vertices: [5, 6, 2, 1]},
            {materials: [0, 1], vertices: [6, 7, 3, 2]},
            {materials: [0, 1], vertices: [7, 4, 0, 3]},
            {materials: [2], vertices: [0, 1, 9, 8]},
            {materials: [2], vertices: [1, 2, 10, 9]},
            {materials: [2], vertices: [2, 3, 11, 10]},
            {materials: [2], vertices: [3, 0, 8, 11]},
            {materials: [2], vertices: [12, 13, 5, 4]},
            {materials: [2], vertices: [13, 14, 6, 5]},
            {materials: [2], vertices: [14, 15, 7, 6]},
            {materials: [2], vertices: [15, 12, 4, 7]},
            {materials: [2], vertices: [8, 16, 17, 9]},
            {materials: [2], vertices: [9, 17, 18, 10]},
            {materials: [2], vertices: [10, 18, 19, 11]},
            {materials: [2], vertices: [11, 19, 16, 8]},
            {materials: [2], vertices: [12, 20, 21, 13]},
            {materials: [2], vertices: [13, 21, 22, 14]},
            {materials: [2], vertices: [14, 22, 23, 15]},
            {materials: [2], vertices: [15, 23, 20, 12]}
        ];

        const sectors_count = 200;
        const cylinder_height = 7.5;

        const functions = [
            {name: '$A', params: ['x'], expression: 'dim_E == 0 ? -' + cylinder_height + ' : dim_D * Math.cos(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_A - (dim_G == 0 ? dim_A / 2 : dim_G1)'},
            {name: '$B', params: ['x'], expression: 'dim_E == 0 ? -dim_D * Math.cos(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_B - (dim_G == 0 ? dim_B / 2 : dim_G1) : -' + cylinder_height},
            {name: '$C', params: ['x'], expression: 'dim_D * Math.sin(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + (dim_F == 0 ? dim_C / 2 : dim_F1)'},
            {name: '$D', params: ['x'], expression: 'dim_E == 0 ? 0 : dim_D * Math.cos(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_A - (dim_G == 0 ? dim_A / 2 : dim_G1)'},
            {name: '$E', params: ['x'], expression: 'dim_E == 0 ? -dim_D * Math.cos(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + dim_B - (dim_G == 0 ? dim_B / 2 : dim_G1) : 0'},
            {name: '$F', params: ['x'], expression: 'dim_D * Math.sin(x * Math.PI / 180 * 360 / ' + sectors_count + ') / 2 + (dim_F == 0 ? dim_C / 2 : dim_F1)'},
        ];

        // cylinder:
        for(let i = 0; i < sectors_count; i++) {
            vertices.push(['$A(' + i + ')', '$B(' + i + ')', '$C(' + i + ')']);
            vertices.push(['$D(' + i + ')', '$E(' + i + ')', '$F(' + i + ')']);
            planes.push({materials: [0, 1], vertices: [36 + 2 * ((i + 1) % sectors_count), 37 + 2 * ((i + 1) % sectors_count), 37 + 2 * i, 36 + 2 * i]});
        }

        vertices.push(['dim_E == 0 ? -10 - ' + cylinder_height + ' : dim_D / 2 + dim_A - (dim_G == 0 ? dim_A / 2 : dim_G1)', 'dim_E == 0 ? -dim_D / 2 + dim_B - (dim_G == 0 ? dim_B / 2 : dim_G1) : -10 - ' + cylinder_height, '(dim_F == 0 ? dim_C / 2 : dim_F1)']);
        vertices.push(['dim_E == 0 ? -10 - ' + cylinder_height + ' : -dim_D / 2 + dim_A - (dim_G == 0 ? dim_A / 2 : dim_G1)', 'dim_E == 0 ? dim_D / 2 + dim_B - (dim_G == 0 ? dim_B / 2 : dim_G1) : -10 - ' + cylinder_height, '(dim_F == 0 ? dim_C / 2 : dim_F1)']);

        vertices.push(['dim_E == 0 ? -3 : (dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)', 'dim_E == 0 ? (dim_G == 0 ? dim_B / 2 : dim_B - dim_G1) : -3', '0']);
        vertices.push(['dim_E == 0 ? -10 - ' + cylinder_height + ' : (dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)', 'dim_E == 0 ? (dim_G == 0 ? dim_B / 2 : dim_B - dim_G1) : -10 - ' + cylinder_height, '0']);
        vertices.push(['dim_E == 0 ? -10 - ' + cylinder_height + ' : (dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)', 'dim_E == 0 ? (dim_G == 0 ? dim_B / 2 : dim_B - dim_G1) : -10 - ' + cylinder_height, 'dim_F == 0 ? dim_C / 2 : dim_F1']);
        vertices.push(['dim_E == 0 ? 0 : (dim_G == 0 ? dim_A / 2 : dim_A - dim_G1)', 'dim_E == 0 ? (dim_G == 0 ? dim_B / 2 : dim_B - dim_G1) : 0', 'dim_F == 0 ? dim_C / 2 : dim_F1']);

        vertices.push(['dim_E == 0 ? 0 : dim_A', 'dim_E == 0 ? dim_B : 0', 'dim_F == 0 ? dim_C / 2 : dim_F1']);
        vertices.push(['dim_E == 0 ? -10 - ' + cylinder_height + ': dim_A', 'dim_E == 0 ? dim_B : -10 - ' + cylinder_height, 'dim_F == 0 ? dim_C / 2 : dim_F1']);

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': default_vals['E'],
            'F': default_vals['F'],
            'F1': empty_vals['F1'] / 10,
            'G': default_vals['G'],
            'G1': empty_vals['G1'] / 10
        };

        const arrows = {
            'A': [24, 25, 26, 27],
            'B': [28, 29, 30, 31],
            'C': [32, 33, 34, 35],
            'D': [36, 36 + 2 * sectors_count, 37 + 2 * sectors_count, 36 + sectors_count],
            'F1': [38 + 2 * sectors_count, 39 + 2 * sectors_count, 40 + 2 * sectors_count, 41 + 2 * sectors_count],
            'G1': [42 + 2 * sectors_count, 43 + 2 * sectors_count, 40 + 2 * sectors_count, 41 + 2 * sectors_count]
        };

        const circle = [];
        for(let i = 0; i < sectors_count; i++) {
            circle.push(36 + 2 * i);
        }

        const holes = [
            {plane_index: 'dim_E == 0 ? 3 : 0', vertices: circle}
        ];


        super(vertices, planes, dimensions, arrows, holes, options);
        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
        this.name = 'Trójnik z odejściem okrągłym';
        //this.cache.print();
    }

    setDimensionValue(name, value) {
        if(name == 'E' || name == 'F' || name == 'G') {
            this.dimensions[name] = value;
        } else {
            this.dimensions[name] = value / 10;
        }
    }

    getDimensionValue(name) {
        if(name == 'E' || name == 'F' || name == 'G') {
            return this.dimensions[name];
        } else {
            return this.dimensions[name] * 10;
        }
    }

    getLastCorrectDimensionValue(name) {
        if(name == 'E' || name == 'F' || name == 'G') {
            return this.options[name][this.last_correct_dimensions[name]];
        } else if(name == 'F1') {
            if(this.last_correct_dimensions['F'] == '0') {
                return '';
            }
        } else if(name == 'G1') {
            if(this.last_correct_dimensions['G'] == '0') {
                return '';
            }
        }
        return this.last_correct_dimensions[name] * 10;
    }
    
    isCorrect() {
        let a = this.dimensions['A'];
        let b = this.dimensions['B'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];
        let e = this.dimensions['E'];
        let f = this.dimensions['F'];
        let f1 = this.dimensions['F1'];
        let g = this.dimensions['G'];
        let g1 = this.dimensions['G1'];
        if(f == '0') {
            if(d + 6 > c) {
                return [false, 1];
            }
        } else {
            if(f1 - d / 2 < 3 || f1 + d / 2 > c - 3) {
                return [false, 2];
            }
        }
        if(e == '0') {
            if(g == '0') {
                if(d > b) {
                    return [false, 3];
                }
            } else {
                if(g1 - d / 2 < 0 || g1 + d / 2 > b) {
                    return [false, 4];
                }
            }
        } else {
            if(g == '0') {
                if(d > a) {
                    return [false, 5];
                }
            } else {
                if(g1 - d / 2 < 0 || g1 + d / 2 > a) {
                    return [false, 6];
                }
            }
        }
        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Średnica D większa od wymiaru C po odliczeniu zapasu na wykończenie. Zmniejsz średnicę D, lub powiększ wymiar C.";
        } else if(error_code == 2) {
            return "Odejście okrągłe wystaje poza wymiar C lub nachodzi na wykończenie. Zmniejsz średnicę D, powiększ wymiar C, lub przesuń otwór.";
        } else if(error_code == 3) {
            return "Średnica D większa od wymiaru B. Zmniejsz średnicę D, lub powiększ wymiar B.";
        } else if(error_code == 4) {
            return "Odejście okrągłe wystaje poza wymiar B. Zmniejsz średnicę D, powiększ wymiar B, lub przesuń otwór.";
        } else if(error_code == 5) {
            return "Średnica D większa od wymiaru A. Zmniejsz średnicę D, lub powiększ wymiar A.";
        } else if(error_code == 6) {
            return "Odejście okrągłe wystaje poza wymiar A. Zmniejsz średnicę D, powiększ wymiar A, lub przesuń otwór.";
        }
        return "";
    }
}