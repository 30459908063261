import { Component, Input, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
    selector: 'vertical-form-field',
    template: `
        <div class="mt-6">
            <label for="{{fieldId}}" class="block font-medium leading-5">{{fieldLabel}}</label>
            <div class="mt-1 flex justify-beetwen items-center">
                <ng-content></ng-content>
            </div>
            <div *ngIf="!isValid()" class="mt-1 text-red-500 text-sm">
                {{errors}}
            </div>
        </div>
    `
})
export class VerticalFormFieldComponent extends FormFieldComponent implements OnInit { }