import { CachedBase } from "./cached-based";
import { CachedFunction } from "./cached-function";

export class CachedValue extends CachedBase {
    dimensions: any;
    expression: any;
    value: any;

    static fromExpression(expression, functions, all_dim_values) {
        let new_cached_value = new CachedValue();
        new_cached_value.dimensions = new Set();
        if(expression === undefined || expression === "") {
            new_cached_value.expression = "1";
            new_cached_value.value = 1;
        } else {
            let parsed_expression = expression;
            let needle = 'dim_';
            let index = parsed_expression.indexOf(needle);
            while(index != -1) {
                let letter = parsed_expression[index + needle.length];
                let digit = "";
                if(index + needle.length + 1 < parsed_expression.length) {
                    let digit_candidate = parsed_expression[index + needle.length + 1];
                    if(digit_candidate >= '0' && digit_candidate <= '9') {
                        digit = digit_candidate;
                    }
                }
                let dim_index = letter + digit;
                new_cached_value.dimensions.add(dim_index);
                let dim_value = all_dim_values[dim_index];
                parsed_expression = parsed_expression.replace(needle + dim_index, String(dim_value));
                index = parsed_expression.indexOf(needle, index);
            }
            new_cached_value.expression = new_cached_value.simplifyExpression(expression);
            new_cached_value.value = eval(parsed_expression);
            if(new_cached_value.value === true) {
                new_cached_value.value = 1;
            } else if (new_cached_value.value === false) {
                new_cached_value.value = 0;
            }
        }
        let extra_dimensions = CachedFunction.getDimensionsOfFunctionsInExpression(new_cached_value.expression, functions);
        for(let extra_dimension of extra_dimensions) {
            new_cached_value.dimensions.add(extra_dimension);
        }
        return new_cached_value;
    }

    static createEmpty() {
        let new_cached_value = new CachedValue();
        new_cached_value.dimensions = new Set();
        new_cached_value.expression = "";
        new_cached_value.value = undefined;
        return new_cached_value;
    }

    update(changed_dim_names, all_dim_values) {
        let found = false;
        for(let i = 0; !found && i < changed_dim_names.length; i++) {
            found = this.dimensions.has(changed_dim_names[i]);
        }
        if(found) {
            this.value = eval(this.assignValuesToDims(this.expression, all_dim_values));
            if(this.value === true) {
                this.value = 1;
            } else if (this.value === false) {
                this.value = 0;
            }
        }
    }
}