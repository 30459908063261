import { Model } from "../model";
import { Cache } from "../cache";

export class KolanoProstokatne extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            // sheets 0 - 11
            ['0', '3', '0'],
            ['dim_C', '3', '0'],
            ['dim_C', 'dim_E', '0'],
            ['dim_C + dim_D - 3', 'dim_E', '0'],
            ['dim_C + dim_D - 3', 'dim_A + dim_E', '0'],
            ['0', 'dim_A + dim_E', '0'],

            ['0', '3', 'dim_B'],
            ['dim_C', '3', 'dim_B'],
            ['dim_C', 'dim_E', 'dim_B'],
            ['dim_C + dim_D - 3', 'dim_E', 'dim_B'],
            ['dim_C + dim_D - 3', 'dim_A + dim_E', 'dim_B'],
            ['0', 'dim_A + dim_E', 'dim_B'],

            // finishing 12 - 27
            ['0', '0', '0'],
            ['dim_C', '0', '0'],
            ['dim_C', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['-3', '0', '-3'],
            ['dim_C + 3', '0', '-3'],
            ['dim_C + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['dim_C + dim_D', 'dim_E', '0'],
            ['dim_C + dim_D', 'dim_A + dim_E', '0'],
            ['dim_C + dim_D', 'dim_A + dim_E', 'dim_B'],
            ['dim_C + dim_D', 'dim_E', 'dim_B'],

            ['dim_C + dim_D', 'dim_E - 3', '-3'],
            ['dim_C + dim_D', 'dim_A + dim_E + 3', '-3'],
            ['dim_C + dim_D', 'dim_A + dim_E + 3', 'dim_B + 3'],
            ['dim_C + dim_D', 'dim_E - 3', 'dim_B + 3'],

            // arrows
            // A 28-29 + 3-4
            ['dim_C + dim_D - 3', 'dim_E', 'dim_B + 10'],
            ['dim_C + dim_D - 3', 'dim_A + dim_E', 'dim_B + 10'],

            // B 30-31 + 4 + 10
            ['dim_C + dim_D - 3', 'dim_A + dim_E + 10', '0'],
            ['dim_C + dim_D - 3', 'dim_A + dim_E + 10', 'dim_B'],

            // C 32-33 + 0-1
            ['0', '3', 'dim_B + 10'],
            ['dim_C', '3', 'dim_B + 10'],

            // D 34-36 + 2
            ['dim_C', 'dim_E', 'dim_B + 10'],
            ['dim_C + dim_D', 'dim_E', 'dim_B + 10'],
            ['dim_C + dim_D', 'dim_E', 'dim_B + 3'],

            // E 37-38 + 2 + 34
            ['dim_C', '0', 'dim_B + 3'],
            ['dim_C', '0', 'dim_B + 10']
        ];

        const planes = [
            {materials: [0, 1], vertices: [0, 1, 2, 3, 4, 5]},
            {materials: [0, 1], vertices: [11, 10, 9, 8, 7, 6]},
            {materials: [0, 1], vertices: [0, 5, 11, 6]},
            {materials: [0, 1], vertices: [5, 4, 10, 11]},
            {materials: [0, 1], vertices: [7, 8, 2, 1]},
            {materials: [0, 1], vertices: [8, 9, 3, 2]},
            {materials: [2], vertices: [0, 1, 13, 12]},
            {materials: [2], vertices: [1, 7, 14, 13]},
            {materials: [2], vertices: [7, 6, 15, 14]},
            {materials: [2], vertices: [6, 0, 12, 15]},
            {materials: [2], vertices: [12, 16, 17, 13]},
            {materials: [2], vertices: [13, 17, 18, 14]},
            {materials: [2], vertices: [14, 18, 19, 15]},
            {materials: [2], vertices: [15, 19, 16, 12]},
            {materials: [2], vertices: [3, 4, 21, 20]},
            {materials: [2], vertices: [4, 10, 22, 21]},
            {materials: [2], vertices: [10, 9, 23, 22]},
            {materials: [2], vertices: [9, 3, 20, 23]},
            {materials: [2], vertices: [20, 24, 25, 21]},
            {materials: [2], vertices: [21, 25, 26, 22]},
            {materials: [2], vertices: [22, 26, 27, 23]},
            {materials: [2], vertices: [23, 27, 24, 20]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10
        };

        const arrows = {
            'A': [9, 28, 29, 10],
            'B': [4, 30, 31, 10],
            'C': [6, 32, 33, 7],
            'D': [8, 34, 35, 36],
            'E': [8, 34, 38, 37]
        };

        const holes = [];
        const functions = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        this.name = 'Kolano prostokątne';
    }
}