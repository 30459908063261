import { environment } from "projects/pdd-open-client/src/environments/environment";
import { ElementType } from "projects/pdd-panel-client/src/app/modules/config/models/element-type";
import { Param } from "./param";
import { ShapeTemplate } from "./shape-template";

/**
 * Szablon kształtu
 */
export class Shape {
    static imagesURL = environment.imagesURL;

    id?: number;
    count: number;
    comment: string = "";
    shape_template: ShapeTemplate;
    params: Param[];
    locked?: boolean;
    area?: number;
    price?: string;
    total_price?: number;
    price_category?: string;
    metal_sheet_type?: ElementType; 
    metal_sheet_cost?: string; 
    metal_sheet_area?: string;
    frame_p20_length?: string;
    frame_p20_cost?: string;
    frame_p30_length?: string;
    frame_p30_cost?: string;
    corners_p20_count?: string;
    corners_p20_cost?: string;
    corners_p30_count?: string;
    corners_p30_cost?: string;
    silicone_amount?: string;
    silicone_cost?: string;
    single_reinforcement_count?: string;
    cross_reinforcement_count?: string;
    reinforcement_cost?: string;
    total_cost?: string;

    static getImg(shape: Shape): string {
        return `${this.imagesURL}/storage/shapes/${shape.shape_template.path_to_picture_file}`;
    }
}