<div [ngClass]="getClasses()" class="flex flex-wrap mb-4 items-center">
    <div class="param-left-col text-right p-2 justify-end">
        <label class="flex items-center justify-end">
            {{paramConfig.param.name}} <div class="rounded w-8 h-8 flex items-center justify-center bg-blue-800 p-2 text-white m-1 font-medium">{{paramConfig.param.short_name}}</div>:
        </label>
    </div>
    <div class="param-right-col items-center">
        <select (focus) = 'onFocus()' style="width: 8rem;" class="param-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300" [ngClass]="{'border-red-600': showError()}" [formControl]="paramConfig.control">
            <option *ngIf="!paramConfig.control.value" value="">Wybierz</option>
            <option *ngFor="let option of paramConfig.param.options; let i=index" value="{{option.id}}">{{option.name}}</option>
        </select>
        {{paramConfig.param.unit_of_measure}}
    </div>
    <div class="param-left-col"></div>
    <div *ngIf="showError()" class="param-right-col mt-1 text-sm text-red-600">
        {{getError()}}
    </div>
</div>