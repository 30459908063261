import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'form-field',
    template: `
        <div class="flex flex-wrap mb-4 items-center">
            <div class="w-1/2 text-right p-2">
                <label [for]="fieldId">
                    {{fieldLabel}}:
                </label>
            </div>
            <div class="w-1/2">
                <ng-content></ng-content>
            </div>
            <div class="w-1/2"></div>
            <div *ngIf="!isValid()" class="w-1/2 mt-1 text-sm text-red-600 pr-2">
                {{errors}}
            </div>
        </div>
    `
})
export class FormFieldComponent implements OnInit {

    @Input()
    fieldLabel: string = "";

    @Input()
    fieldId: string;
    
    @Input()
    errors: string = "";

    constructor() { };

    ngOnInit() { }

    isValid(): boolean {
        return this.errors == "";
    }
}
