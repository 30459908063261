import * as THREE from 'three';

export class Model {
    public evaluated_vertices: any;
    public options: any;
    public last_correct_dimensions: any;
    public materials: any;
    public arrow_material: any;
    public inner_bounding_box: any;
    public outer_bounding_box: any;
    public name: string;
    public vertices: any;
    public planes: any;
    public holes: any;
    public dimensions: any;
    public arrows: any;
    public cache: any;

    constructor(vertices, planes, dimensions, arrows, holes, options) {
        this.vertices = vertices;
        this.planes = planes;
        this.holes = holes;
        this.dimensions = dimensions;
        this.last_correct_dimensions = {};
        this.options = options;
        
        Object.entries(this.dimensions).forEach(([dim, value]) => {
            this.last_correct_dimensions[dim] = value;
        }, this);
        this.arrows = arrows;
        this.materials = [
            new THREE.MeshPhongMaterial( { color: 0x32f, emissive: 0x95a7f0, specular: 0x555555, reflectivity: 0.5, side: THREE.FrontSide, flatShading: true } ),
            new THREE.MeshPhongMaterial( { color: 0x93dc4c, emissive: 0x040f04, specular: 0xaabb2f, reflectivity: 0.5, side: THREE.BackSide, flatShading: true } ),
            new THREE.MeshPhongMaterial( { color: 0xff0000, emissive: 0x222222, specular: 0x222222, reflectivity: 0.2, side: THREE.DoubleSide, flatShading: true } ),
            new THREE.MeshPhongMaterial( { color: 0x778899, emissive: 0x222222, specular: 0x222222, reflectivity: 0.2, side: THREE.DoubleSide, flatShading: true } ),
            new THREE.MeshPhongMaterial( { color: 0x32f, emissive: 0x95a7f0, specular: 0x555555, reflectivity: 0.5, side: THREE.DoubleSide, flatShading: true } ),
            new THREE.MeshPhongMaterial( { color: 0x050505, emissive: 0x222222, specular: 0x222222, reflectivity: 0.2, side: THREE.DoubleSide, flatShading: true } ),
        ];
        this.arrow_material = new THREE.MeshPhongMaterial( { color: 0x000000, emissive: 0x222222, specular: 0x222222, reflectivity: 0.2, side: THREE.FrontSide, flatShading: true } );
        this.inner_bounding_box = new THREE.Box3(); //only shape without arrows
        this.outer_bounding_box = new THREE.Box3(); //shape with arrows
    }

    getVertex(index) {
        return this.cache.getVertex(index);
    }

    setDimensionValue(name, value) {
        this.dimensions[name] = value / 10;
    }
    setDimension(name, value) {
        const t0 = performance.now();
        this.setDimensionValue(name, value);
        this.cache.update([name], this.dimensions);
        const t1 = performance.now();
    }

    getDimensionValue(name) {
        return this.dimensions[name] * 10;
    }

    getLastCorrectDimensionValue(name) {
        return this.last_correct_dimensions[name] * 10;
    }

    saveCorrectDimensions() {
        Object.entries(this.dimensions).forEach(([dim, value]) => {
            this.last_correct_dimensions[dim] = value;
        }, this);
    }

    findHolesForPlane(plane_index) {
        return this.cache.getHolesForPlane(plane_index);
    }

    planeIsVisible(plane_index) {
        return this.cache.planeIsVisible(plane_index);
    }

    drawShapeFromCorners(corners) {
        const shape = new THREE.Shape();
        shape.moveTo(corners[0][0], corners[0][1]);
        for(let i = 1; i < corners.length; i++) {
            shape.lineTo(corners[i][0], corners[i][1]);
        }
        shape.lineTo(corners[0][0], corners[0][1]);
        return shape;
    }

    getGeometries() {
        const geometries = [];
        this.inner_bounding_box.makeEmpty();
        let plane_index = 0;
        this.planes.forEach((plane) => {
            if(this.planeIsVisible(plane_index)) {
                const unique_vertices = this.selectUniqueVertices(plane.vertices);
                if(unique_vertices.length >= 3) {
                    const [matrix, inverse_matrix] = this.calculateTransformationMatrices(unique_vertices);
                    const plane_corners = this.transformCorners(unique_vertices, matrix);
                    if(plane_corners.length > 0) {
                        const shape = this.drawShapeFromCorners(plane_corners);
                        const hole_vertices = this.findHolesForPlane(plane_index);
                        const unique_hole_vertices = this.selectUniqueVertices(hole_vertices);
                        const hole_corners = this.transformCorners(unique_hole_vertices, matrix);
                        if(hole_corners.length > 2) {
                            const hole = this.drawShapeFromCorners(hole_corners);
                            shape.holes.push(hole);
                        }
                        const geometry = new THREE.ShapeGeometry(shape);
                        geometry.applyMatrix4(inverse_matrix);
                        geometry.computeBoundingBox();
                        this.inner_bounding_box.union(geometry.boundingBox);
                        geometries.push({geometry: geometry, materials: plane.materials});
                    }
                }
            }
            plane_index++;
        }, this);
        this.outer_bounding_box = this.inner_bounding_box.clone();
        const arrows = [];
        Object.entries(this.arrows).forEach(([dim, arrow]) => {
            const [attachment_A, end_A, end_B, attachment_B] = this.calculateArrowPosition(arrow);
            const arrow_elements = this.generateArrow(end_A, end_B, attachment_A, attachment_B);
            arrow_elements.forEach((element) => {
                arrows.push({geometry: element, material: this.arrow_material, dim: dim});
            }, this);
        }, this);
        return [geometries, arrows];
    }

    calculateArrowPosition(arrow) {
        let result = [];
        arrow.forEach((index) => {
            const tab = this.getVertex(index);
            result.push(new THREE.Vector3(tab[0], tab[1], tab[2]));
        }, this);
        return result;
    }

    calculateGeometryRotation(point_A, point_B) {
        const a = THREE.Object3D.DefaultUp; // new THREE.Vector3(0, 1, 0); // by default cyliner "looks" in this direction
        const b = (new THREE.Vector3()).subVectors(point_B, point_A).normalize();
        const c = a.dot(b);
        const R = new THREE.Matrix3();
        const v = (new THREE.Vector3()).crossVectors(a, b);
        if(c == -1) {
            //do nothing - a is a negation of b, so we don't have to rotate: set R to -I
            // for this case the formula in else statement would nt work because of division by 0
            R.multiplyScalar(-1);
        } else {
            const Z = new THREE.Matrix3();
            Z.set(	  0, -v.z,  v.y,
                    v.z,    0, -v.x,
                   -v.y,  v.x,    0);
            const Z2  = new THREE.Matrix3();
            Z2.multiplyMatrices(Z, Z).multiplyScalar(1 / (1 + c));
            R.set(1 + Z.elements[0] + Z2.elements[0], Z.elements[3] + Z2.elements[3], Z.elements[6] + Z2.elements[6],
                  Z.elements[1] + Z2.elements[1], 1 + Z.elements[4] + Z2.elements[4], Z.elements[7] + Z2.elements[7],
                  Z.elements[2] + Z2.elements[2], Z.elements[5] + Z2.elements[5], 1 + Z.elements[8] + Z2.elements[8]);
        }
        return R;
    }

    generateCylinder(point_A, point_B) {
        const R = this.calculateGeometryRotation(point_A, point_B);
        const height = point_A.distanceTo(point_B);
        const v = (new THREE.Vector3()).subVectors(point_B, point_A).normalize();
        const geometry = new THREE.CylinderGeometry(0.5, 0.5, height, 32);
        geometry.applyMatrix4((new THREE.Matrix4()).setFromMatrix3(R));
        geometry.translate(point_A.x + v.x * height / 2, point_A.y + v.y * height / 2, point_A.z + v.z * height / 2);
        return geometry;
    }

    generateCone(point_A, point_B) {
        const R = this.calculateGeometryRotation(point_A, point_B);
        const height = point_A.distanceTo(point_B);
        const v = (new THREE.Vector3()).subVectors(point_B, point_A).normalize();
        const geometry = new THREE.ConeGeometry(1, height, 32);
        geometry.applyMatrix4((new THREE.Matrix4()).setFromMatrix3(R));
        geometry.translate(point_A.x + v.x * height / 2, point_A.y + v.y * height / 2, point_A.z + v.z * height / 2);
        return geometry;
    }

    generateArrow(end_A, end_B, attachment_A, attachment_B) {
        const result = [];
        let v = (new THREE.Vector3()).subVectors(end_A, attachment_A).normalize().multiplyScalar(4);
        result.push(this.generateCylinder(attachment_A, end_A.clone().add(v)));
        result.push(this.generateCylinder(attachment_B, end_B.clone().add(v)));

        v.subVectors(end_B, end_A).normalize().multiplyScalar(4);
        const x_A = end_A.clone();
        const x_B = end_B.clone();
        if(end_A.distanceTo(end_B) > 16) {
            x_A.add(v);
            x_B.sub(v);
            result.push(this.generateCone(x_A, end_A));
            result.push(this.generateCone(x_B, end_B));
        } else {
            x_A.sub(v);
            x_B.add(v);
            result.push(this.generateCylinder(x_A, end_A));
            result.push(this.generateCylinder(x_B, end_B));
            const v1 = (new THREE.Vector3()).subVectors(attachment_A, end_A).normalize();
            const v2 = (new THREE.Vector3()).subVectors(end_B, end_A).normalize();
            v.addVectors(v1, v2).normalize().multiplyScalar(5);
            result.push(this.generateCylinder(end_A.clone().add(v), end_A.clone().sub(v)));
            result.push(this.generateCylinder(end_B.clone().add(v), end_B.clone().sub(v)));
        }

        result.push(this.generateCylinder(x_A, x_B));
        return result;
    }

    calculateTransformationMatrices(vertices) {
        const A = vertices[0];
        const B = vertices[1];
        let k = 2;
        const U = new THREE.Vector3(A[0] - B[0], A[1] - B[1], A[2] - B[2]);
        let found = false;
        let C = U;
        let N = U;
        let V = U;
        //find C which is not colinear with A and B
        while(k < vertices.length && !found) {
            C = vertices[k];
            V = new THREE.Vector3(C[0] - B[0], C[1] - B[1], C[2] - B[2]);
            N = new THREE.Vector3();
            N.crossVectors(U, V);
            if(N.x == 0 && N.y == 0 && N.z == 0) {
                k++;
            } else {
                found = true;
            }
        }
        if(!found) {
            return [false, false];
        }

        N.normalize();
        U.normalize();
        const Z = new THREE.Vector3();
        Z.crossVectors(N, U).normalize();

        const inverse_rotation = new THREE.Matrix4();
        inverse_rotation.makeBasis(U, Z, N);
        const rotation = inverse_rotation.clone();
        rotation.transpose();
        const translation = new THREE.Matrix4();
        translation.setPosition(-B[0], -B[1], -B[2]);
        const inverse_translation = new THREE.Matrix4();
        inverse_translation.setPosition(B[0], B[1], B[2]);
        const transformation = new THREE.Matrix4();
        transformation.multiplyMatrices(rotation, translation);
        const inverse_transformation = new THREE.Matrix4();
        inverse_transformation.multiplyMatrices(inverse_translation, inverse_rotation);
        return [transformation, inverse_transformation];
    }

    selectUniqueVertices(vertices) {
        const result = [];
        let prev_vertex = undefined;
        for(let i = 0; i < vertices.length; i++) {
            // console.log('I: ' + i);
            // console.log('vertices[i]: ' + vertices[i]);
            const vertex = this.getVertex(vertices[i]);
            if(prev_vertex === undefined || vertex[0] != prev_vertex[0] || vertex[1] != prev_vertex[1] || vertex[2] != prev_vertex[2]) {
                result.push(vertex);
                prev_vertex = vertex;
            }
        }
        if(result.length > 1) {
            if(result[0][0] == result[result.length - 1][0] && result[0][1] == result[result.length - 1][1] && result[0][2] == result[result.length - 1][2]) {
                result.pop();
            }
        }
        return result;
    }

    transformCorners(vertices, matrix) {
        const result = [];
        for(let i = 0; i < vertices.length; i++) {
            const vertex = vertices[i];
            const corner = new THREE.Vector4(vertex[0], vertex[1], vertex[2], 1);
            corner.applyMatrix4(matrix);
            result.push([corner.x, corner.y]);
        }
        return result;
    }

    isCorrect() {
        return [true, 0];
    }

    getErrorString(error_code) {
        return "";
    }
}