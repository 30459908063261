import { Model } from "../model";
import { Cache } from "../cache"

export class WyrzutniaDachowaProstokatnaTypB extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-3', '0', '-3'],
            ['dim_A + 3', '0', '-3'],
            ['dim_A + 3', '0', 'dim_B + 3'],
            ['-3', '0', 'dim_B + 3'],

            ['0', '0', '0'],
            ['dim_A', '0', '0'],
            ['dim_A', '0', 'dim_B'],
            ['0', '0', 'dim_B'],

            ['0', '3', '0'],
            ['dim_A', '3', '0'],
            ['dim_A', '3', 'dim_B'],
            ['0', '3', 'dim_B'],

            ['0', 'dim_C - 41', '0'],
            ['dim_A', 'dim_C - 41', '0'],
            ['dim_A', 'dim_C - 41', 'dim_B'],
            ['0', 'dim_C - 41', 'dim_B'],

            ['-7', 'dim_C - 41', '0'],
            ['0', 'dim_C - 41', '-7'],
            ['dim_A', 'dim_C - 41', '-7'],
            ['dim_A + 7', 'dim_C - 41', '0'],
            ['dim_A + 7', 'dim_C - 41', 'dim_B'],
            ['dim_A', 'dim_C - 41', 'dim_B + 7'],
            ['0', 'dim_C - 41', 'dim_B + 7'],
            ['-7', 'dim_C - 41', 'dim_B'],

            ['0', 'dim_C', '0'],
            ['dim_A', 'dim_C', '0'],
            ['dim_A', 'dim_C', 'dim_B'],
            ['0', 'dim_C', 'dim_B'],

            ['-7', 'dim_C', '0'],
            ['0', 'dim_C', '-7'],
            ['dim_A', 'dim_C', '-7'],
            ['dim_A + 7', 'dim_C', '0'],
            ['dim_A + 7', 'dim_C', 'dim_B'],
            ['dim_A', 'dim_C', 'dim_B + 7'],
            ['0', 'dim_C', 'dim_B + 7'],
            ['-7', 'dim_C', 'dim_B'],

            ['-7', 'dim_C', '-7'],
            ['dim_A + 7', 'dim_C', '-7'],
            ['dim_A + 7', 'dim_C', 'dim_B + 7'],
            ['-7', 'dim_C', 'dim_B + 7'],

            ['-7', 'dim_C - 2', '-7'],
            ['dim_A + 7', 'dim_C - 2', '-7'],
            ['dim_A + 7', 'dim_C - 2', 'dim_B + 7'],
            ['-7', 'dim_C - 2', 'dim_B + 7'],

            // arrows
            // A 44-47
            ['0', '3', 'dim_B'],
            ['0', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B + 10'],
            ['dim_A', '3', 'dim_B'],

            // B 48-51
            ['dim_A', '3', '0'],
            ['dim_A + 10', '3', '0'],
            ['dim_A + 10', '3', 'dim_B'],
            ['dim_A', '3', 'dim_B'],

            // C 52-55
            ['dim_A + 3', '0', 'dim_B / 2'],
            ['dim_A + 7 + 10', '0', 'dim_B / 2'],
            ['dim_A + 7 + 10', 'dim_C', 'dim_B / 2'],
            ['dim_A + 7', 'dim_C', 'dim_B / 2']


        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [8, 9, 13, 12]},
            {materials: [0, 1], vertices: [9, 10, 14, 13]},
            {materials: [0, 1], vertices: [10, 11, 15, 14]},
            {materials: [0, 1], vertices: [11, 8, 12, 15]},

            {materials: [4], vertices: [16, 17, 12]},
            {materials: [4], vertices: [18, 19, 13]},
            {materials: [4], vertices: [20, 21, 14]},
            {materials: [4], vertices: [22, 23, 15]},

            {materials: [4], vertices: [12, 17, 18, 13]},
            {materials: [4], vertices: [13, 19, 20, 14]},
            {materials: [4], vertices: [14, 21, 22, 15]},
            {materials: [4], vertices: [15, 23, 16, 12]},

            {materials: [4], vertices: [12, 24, 29, 17]},
            {materials: [4], vertices: [13, 25, 30, 18]},
            {materials: [4], vertices: [13, 25, 31, 19]},
            {materials: [4], vertices: [14, 26, 32, 20]},
            {materials: [4], vertices: [14, 26, 33, 21]},
            {materials: [4], vertices: [15, 27, 34, 22]},
            {materials: [4], vertices: [15, 27, 35, 23]},
            {materials: [4], vertices: [12, 24, 28, 16]},

            {materials: [4], vertices: [16, 28, 29, 17]},
            {materials: [4], vertices: [18, 30, 31, 19]},
            {materials: [4], vertices: [20, 32, 33, 21]},
            {materials: [4], vertices: [22, 34, 35, 23]},

            {materials: [4], vertices: [36, 37, 38, 39]},

            {materials: [4], vertices: [36, 37, 41, 40]},
            {materials: [4], vertices: [37, 38, 42, 41]},
            {materials: [4], vertices: [38, 39, 43, 42]},
            {materials: [4], vertices: [39, 36, 40, 43]}

        ];

        let v = vertices.length;
        for(let i = 0; i < 5; i++) {
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString(), '-1']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString(), '-1']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 2', '-1']);
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 2', '-1']);

            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 7', '-6']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 7', '-6']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 9', '-6']);
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 9', '-6']);

            planes.push({materials: [4], vertices: [v + 8 * i, v + 8 * i + 1, v + 8 * i + 2, v + 8 * i + 3]});
            planes.push({materials: [4], vertices: [v + 8 * i + 2, v + 8 * i + 3, v + 8 * i + 4, v + 8 * i + 5]});
            planes.push({materials: [4], vertices: [v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 7]});
        }

        v = vertices.length;
        for(let i = 0; i < 5; i++) {
            vertices.push(['-1', 'dim_C - 8 * ' + i.toString(), 'dim_B']);
            vertices.push(['-1', 'dim_C - 8 * ' + i.toString(), '0']);
            vertices.push(['-1', 'dim_C - 8 * ' + i.toString() + ' - 2', '0']);
            vertices.push(['-1', 'dim_C - 8 * ' + i.toString() + ' - 2', 'dim_B']);

            vertices.push(['-6', 'dim_C - 8 * ' + i.toString() + ' - 7', 'dim_B']);
            vertices.push(['-6', 'dim_C - 8 * ' + i.toString() + ' - 7', '0']);
            vertices.push(['-6', 'dim_C - 8 * ' + i.toString() + ' - 9', '0']);
            vertices.push(['-6', 'dim_C - 8 * ' + i.toString() + ' - 9', 'dim_B']);

            planes.push({materials: [4], vertices: [v + 8 * i, v + 8 * i + 1, v + 8 * i + 2, v + 8 * i + 3]});
            planes.push({materials: [4], vertices: [v + 8 * i + 2, v + 8 * i + 3, v + 8 * i + 4, v + 8 * i + 5]});
            planes.push({materials: [4], vertices: [v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 7]});
        }

        v = vertices.length;
        for(let i = 0; i < 5; i++) {
            vertices.push(['dim_A + 1', 'dim_C - 8 * ' + i.toString(), 'dim_B']);
            vertices.push(['dim_A + 1', 'dim_C - 8 * ' + i.toString(), '0']);
            vertices.push(['dim_A + 1', 'dim_C - 8 * ' + i.toString() + ' - 2', '0']);
            vertices.push(['dim_A + 1', 'dim_C - 8 * ' + i.toString() + ' - 2', 'dim_B']);

            vertices.push(['dim_A + 6', 'dim_C - 8 * ' + i.toString() + ' - 7', 'dim_B']);
            vertices.push(['dim_A + 6', 'dim_C - 8 * ' + i.toString() + ' - 7', '0']);
            vertices.push(['dim_A + 6', 'dim_C - 8 * ' + i.toString() + ' - 9', '0']);
            vertices.push(['dim_A + 6', 'dim_C - 8 * ' + i.toString() + ' - 9', 'dim_B']);

            planes.push({materials: [4], vertices: [v + 8 * i, v + 8 * i + 1, v + 8 * i + 2, v + 8 * i + 3]});
            planes.push({materials: [4], vertices: [v + 8 * i + 2, v + 8 * i + 3, v + 8 * i + 4, v + 8 * i + 5]});
            planes.push({materials: [4], vertices: [v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 7]});
        }

        v = vertices.length;
        for(let i = 0; i < 5; i++) {
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString(), 'dim_B + 1']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString(), 'dim_B + 1']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 2', 'dim_B + 1']);
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 2', 'dim_B + 1']);

            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 7', 'dim_B + 6']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 7', 'dim_B + 6']);
            vertices.push(['0', 'dim_C - 8 * ' + i.toString() + ' - 9', 'dim_B + 6']);
            vertices.push(['dim_A', 'dim_C - 8 * ' + i.toString() + ' - 9', 'dim_B + 6']);

            planes.push({materials: [4], vertices: [v + 8 * i, v + 8 * i + 1, v + 8 * i + 2, v + 8 * i + 3]});
            planes.push({materials: [4], vertices: [v + 8 * i + 2, v + 8 * i + 3, v + 8 * i + 4, v + 8 * i + 5]});
            planes.push({materials: [4], vertices: [v + 8 * i + 4, v + 8 * i + 5, v + 8 * i + 6, v + 8 * i + 7]});
        }

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10
        };

        const arrows = {
            'A': [44, 45, 46, 47],
            'B': [48, 49, 50, 51],
            'C': [52, 53, 54, 55]
        };

        const holes = [];
        const functions = [];
        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Wyrzutnia dachowa prostokątna typ B';
        this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
    }
}