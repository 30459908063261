<div [ngClass]="getClasses()" class="flex flex-wrap mb-4 items-center">
    <div class="param-left-col text-right p-2 justify-end">
        <label class="flex items-center justify-end">
            {{paramConfig.param.name}} <div class="rounded w-8 h-8 flex items-center justify-center bg-blue-800 p-2 text-white m-1 font-medium">{{paramConfig.param.short_name}}</div>:
        </label>
    </div>
    <div class="param-right-col">
        <!--
        <input 
            style="width: 6rem;"
            class="param-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300" 
            type="number" 
            [formControl]="paramConfig.control"
            [ngClass]="{'error': showError()}"
            size="6"
        >
        -->
        <int-input (focus)="onFocus()" [error]="showError()" [formControl]="paramConfig.control"></int-input>
        {{paramConfig.param.unit_of_measure}} <span class="text-gray-400">({{paramConfig.param.min_val}} - {{paramConfig.param.max_val}} {{paramConfig.param.unit_of_measure}})</span>
    </div>
    <div class="param-left-col"></div>
    <div *ngIf="showError()" class="param-right-col mt-1 text-sm text-red-600">
        {{getError()}}
    </div>
</div>