import { HostListener } from '@angular/core';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { BasePanelViewComponent } from 'projects/base-lib/src/lib/components/base-panel-view.component';

@Component({
    selector: 'app-modal',
    template: `
        <div class="modal fixed flex justify-center items-center inset-0 bg-black bg-opacity-75 p-4 z-50">
            <div class="bg-white komunikat rounded-lg max-w-xl overflow-hidden p-8 flex flex-col max-h-full">
                <h3 class="text-lg py-4 leading-6 font-medium text-gray-900 border-b">
                    Tytuł
                </h3>
                <!-- <div class="overflow-auto my-4" [innerHTML]="komunikat.tresc"></div>-->
                <div class="py-4 mt-2 border-t sm:flex sm:flex-row-reverse">
                    <button type="button" class="px-12 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
                    (click)="onClose()">
                        Potwierdź
                    </button>
                    <button type="button" class="px-12 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm  py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto "(click)="onClose()">
                        Zamknij
                    </button>
                </div>
            </div>
        </div>
    `
})
export class ModalComponent extends BasePanelViewComponent implements OnInit {
    close: EventEmitter<any> = new EventEmitter<any>();
    action: () => {};
    params: any;
    data: any;

    ngOnInit(): void { }

    @HostListener('click', ['$event', '$event.target'])
    onClick(event: Event, element: HTMLElement) {
        if(element.classList.contains('modal')) {
            event.stopPropagation();
            this.close.emit();
        }
    }

    onClose(action?: () => {}, params?: any) {
        this.close.emit({
            action: action,
            params: params
        });
    }
}