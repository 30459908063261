import { Model } from "../model";
import { Cache } from "../cache";

export class TrojnikSiodlowy extends Model {
    constructor(empty_vals, default_vals, options, DATA = null) {
        const vertices = [
            ['-dim_D / 2 - 3', 'dim_A / 2 + dim_E', 'dim_B / 2 - dim_C / 2 - 3'],
            ['dim_D / 2 + 3', 'dim_A / 2 + dim_E', 'dim_B / 2 - dim_C / 2 - 3'],
            ['dim_D / 2 + 3', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2 + 3'],
            ['-dim_D / 2 - 3', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2 + 3'],

            ['-dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2'],
            ['-dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2'],

            ['-dim_D / 2', 'dim_A / 2 + dim_E - 3', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E - 3', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E - 3', 'dim_B / 2 + dim_C / 2'],
            ['-dim_D / 2', 'dim_A / 2 + dim_E - 3', 'dim_B / 2 + dim_C / 2'],

            ['-dim_D / 2', 'Math.sqrt(dim_A * dim_A - dim_D * dim_D) / 2', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'Math.sqrt(dim_A * dim_A - dim_D * dim_D) / 2', 'dim_B / 2 - dim_C / 2'],
            ['dim_D / 2', 'Math.sqrt(dim_A * dim_A - dim_D * dim_D) / 2', 'dim_B / 2 + dim_C / 2'],
            ['-dim_D / 2', 'Math.sqrt(dim_A * dim_A - dim_D * dim_D) / 2', 'dim_B / 2 + dim_C / 2'],

            //arrow A
            ['-dim_A / 2', '0', 'dim_B'],
            ['-dim_A / 2', '0', 'dim_B + 10'],
            ['dim_A / 2', '0', 'dim_B + 10'],
            ['dim_A / 2', '0', 'dim_B'],
            //arrow B
            ['dim_A / 2', '0', '0'],
            ['dim_A / 2 + 10', '0', '0'],
            ['dim_A / 2 + 10', '0', 'dim_B'],
            ['dim_A / 2', '0', 'dim_B'],
            //arrow C
            ['0', 'dim_A / 2 + dim_E', 'dim_B / 2 - dim_C / 2'],
            ['0', 'dim_A / 2 + dim_E + 10', 'dim_B / 2 - dim_C / 2'],
            ['0', 'dim_A / 2 + dim_E + 10', 'dim_B / 2 + dim_C / 2'],
            ['0', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2'],
            //arrow D
            ['-dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2'],
            ['-dim_D / 2', 'dim_A / 2 + dim_E + 10', 'dim_B / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E + 10', 'dim_B / 2'],
            ['dim_D / 2', 'dim_A / 2 + dim_E', 'dim_B / 2'],
            //arrow E
            ['0', 'dim_A / 2 + dim_E', 'dim_B / 2 + dim_C / 2 + 3'],
            ['0', 'dim_A / 2 + dim_E', 'dim_B + 10'],
            ['0', 'dim_A / 2', 'dim_B + 10'],
            ['0', 'dim_A / 2', 'dim_B']
        ];

        const planes = [
            {materials: [2], vertices: [0, 1, 5, 4]},
            {materials: [2], vertices: [1, 2, 6, 5]},
            {materials: [2], vertices: [2, 3, 7, 6]},
            {materials: [2], vertices: [3, 0, 4, 7]},

            {materials: [2], vertices: [4, 5, 9, 8]},
            {materials: [2], vertices: [5, 6, 10, 9]},
            {materials: [2], vertices: [6, 7, 11, 10]},
            {materials: [2], vertices: [7, 4, 8, 11]},

            {materials: [0, 1], vertices: [12, 13, 9, 8]},
            {materials: [0, 1], vertices: [13, 14, 10, 9]},
            {materials: [0, 1], vertices: [14, 15, 11, 10]},
            {materials: [0, 1], vertices: [15, 12, 8, 11]}
        ];

        const dimensions = {
            'A': empty_vals['A'] / 10,
            'B': empty_vals['B'] / 10,
            'C': empty_vals['C'] / 10,
            'D': empty_vals['D'] / 10,
            'E': empty_vals['E'] / 10
        };

        const arrows = {
            'A': [16, 17, 18, 19],
            'B': [20, 21, 22, 23],
            'C': [24, 25, 26, 27],
            'D': [28, 29, 30, 31],
            'E': [32, 33, 34, 35]
        };

        const holes = [];

        super(vertices, planes, dimensions, arrows, holes, options);
        this.name = 'Trójnik siodłowy (sztucer)';

        const sectors_count = 200;
        const arc_sectors_count = sectors_count / 4;
        const functions = [
            {name: '$A', params: [], expression: 'Math.asin(dim_D / dim_A)'},
            {name: '$B', params: ['x', 'y'], expression: 'x * Math.sin(y * 2 * $A() / ' + arc_sectors_count + ') / 2'},
            {name: '$C', params: ['x', 'y'], expression: 'x * Math.cos(y * 2 * $A() / ' + arc_sectors_count + ') / 2'},
            {name: '$D', params: ['x', 'y'], expression: 'x * Math.sin(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2'},
            {name: '$E', params: ['x', 'y'], expression: 'x * Math.cos(y * Math.PI / 180 * 360 / ' + sectors_count + ') / 2'},
        ];

        this.addVerticesCircle('dim_A', '0', sectors_count);
        this.addVerticesCircle('dim_A', '3', sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B / 2 - dim_C / 2', sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B / 2 + dim_C / 2', sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B - 3', sectors_count);
        this.addVerticesCircle('dim_A', 'dim_B', sectors_count);

        this.connectCircles(0, 1, [2], sectors_count);
        this.connectCircles(1, 2, [0, 1], sectors_count);
        this.connectCircles(2, 3, [0, 1], sectors_count);
        this.connectCircles(3, 4, [0, 1], sectors_count);
        this.connectCircles(4, 5, [2], sectors_count);


        for(let i = -arc_sectors_count / 2; i <= arc_sectors_count / 2; i++) {
            this.vertices.push(['$B(dim_A, ' + i + ')', '$C(dim_A, ' + i + ')', 'dim_B / 2 - dim_C / 2']);
        }
        for(let i = -arc_sectors_count / 2; i <= arc_sectors_count / 2; i++) {
            this.vertices.push(['$B(dim_A, ' + i + ')', '$C(dim_A, ' + i + ')', 'dim_B / 2 + dim_C / 2']);
        }


        this.vertices.push(['dim_A * Math.sin($A() - ($A() % (2 * Math.PI / ' + sectors_count + '))) / 2', 'dim_A * Math.cos($A() - ($A() % (2 * Math.PI / ' + sectors_count + '))) / 2', 'dim_B / 2 - dim_C / 2']);
        this.vertices.push(['dim_A * Math.sin($A() - ($A() % (2 * Math.PI / ' + sectors_count + '))) / 2', 'dim_A * Math.cos($A() - ($A() % (2 * Math.PI / ' + sectors_count + '))) / 2', 'dim_B / 2 + dim_C / 2']);

        this.vertices.push(['dim_A * Math.sin(($A() % (2 * Math.PI / ' + sectors_count + ')) - $A()) / 2', 'dim_A * Math.cos(($A() % (2 * Math.PI / ' + sectors_count + ')) - $A()) / 2', 'dim_B / 2 - dim_C / 2']);
        this.vertices.push(['dim_A * Math.sin(($A() % (2 * Math.PI / ' + sectors_count + ')) - $A()) / 2', 'dim_A * Math.cos(($A() % (2 * Math.PI / ' + sectors_count + ')) - $A()) / 2', 'dim_B / 2 + dim_C / 2']);



        for(let i = 0; i < sectors_count / 4; i++) {
            holes.push({plane_index: (12 + i + 2 * sectors_count).toString(), vertices: [12, 15, ], visible: '(dim_A * Math.sin(' + (i + 1) + ' * Math.PI / 180 * 360 / ' + sectors_count + ') >= dim_D) || (dim_A * Math.sin(' + i + ' * Math.PI / 180 * 360 / ' + sectors_count + ') <= -dim_D)'});
        }
        for(let i = 3 * sectors_count / 4; i < sectors_count; i++) {
            holes.push({plane_index: (12 + i + 2 * sectors_count).toString(), visible: '(dim_A * Math.sin(' + (i + 1) + ' * Math.PI / 180 * 360 / ' + sectors_count + ') >= dim_D) || (dim_A * Math.sin(' + i + ' * Math.PI / 180 * 360 / ' + sectors_count + ') <= -dim_D)'});
        }

        const arc_1 = [];
        for(let i = 0; i <= arc_sectors_count; i++) {
            arc_1.push(36 + 6 * sectors_count + i);
        }

        const arc_2 = [];
        for(let i = 0; i <= arc_sectors_count; i++) {
            arc_2.push(36 + 6 * sectors_count + arc_sectors_count + 1 + i);
        }

        holes.push({plane_index: '8', vertices: arc_1});
        holes.push({plane_index: '10', vertices: arc_2});
        holes.push({plane_index: '12 + 2 * ' + sectors_count + ' + Math.floor($A() / (2 * Math.PI / ' + sectors_count + '))', vertices: [14, 13, 36 + 6 * sectors_count + 2 * arc_sectors_count + 2, 36 + 6 * sectors_count + 2 * arc_sectors_count + 3]});
        holes.push({plane_index: '12 + 3 * ' + sectors_count + ' - 1 - Math.floor($A() / (2 * Math.PI / ' + sectors_count + '))', vertices: [15, 12, 36 + 6 * sectors_count + 2 * arc_sectors_count + 4, 36 + 6 * sectors_count + 2 * arc_sectors_count + 5]});

        //this.cache = Cache.fromInternalData(this.vertices, this.holes, functions, this.dimensions);
        //this.cache.print();
        this.cache = Cache.fromCacheFile(DATA.vertices, DATA.holes, DATA.functions, this.dimensions);
    }

    addVerticesCircle(diameter, z, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            this.vertices.push(['$D(' + diameter + ', ' + i + ')', '$E(' + diameter + ', ' + i + ')', z]);
        }
    }

    connectCircles(circle_1, circle_2, materials, sectors_count) {
        for(let i = 0; i < sectors_count; i++) {
            // if(this.planes.length == 301) {
            // 	this.planes.push({materials: [5], vertices: [36 + circle_1 * sectors_count + (i % sectors_count), 36 + circle_1 * sectors_count + ((i + 1) % sectors_count), 36 + circle_2 * sectors_count + ((i + 1) % sectors_count), 36 + circle_2 * sectors_count + (i % sectors_count)]});
            // }
            // else
            this.planes.push({materials: materials, vertices: [36 + circle_1 * sectors_count + (i % sectors_count), 36 + circle_1 * sectors_count + ((i + 1) % sectors_count), 36 + circle_2 * sectors_count + ((i + 1) % sectors_count), 36 + circle_2 * sectors_count + (i % sectors_count)]});
        }
    }

    isCorrect() {
        let a = this.dimensions['A'];
        let b = this.dimensions['B'];
        let c = this.dimensions['C'];
        let d = this.dimensions['D'];
        let e = this.dimensions['E'];

        if(d > a) {
            return [false, 1];
        }
        if(c > b - 6) {
            return [false, 2];
        }

        return [true, 0];
    }

    getErrorString(error_code) {
        if(error_code == 1) {
            return "Wymiar D jest większy od średnicy A. Zmniejsz wymiar D, lub powiększ średnicę A.";
        } else if(error_code == 2) {
            return "Wymiar C jest większy od wymiaru B pomniejszonego o wywinięcia. Zmniejsz wymiar C, lub powiększ wymiar B.";
        }
        return "";
    }
}