import { CachedFunction } from "./cached-function";
import { CachedValue} from "./cached-value";

export class Cache {
    functions: any;
    vertices: any;
    holes: any;
    holes_index: any;

    static fromInternalData(vertices, holes, functions, all_dim_values) {
        let new_cache = new Cache();
        new_cache.functions = new Map();
        for(let i = 0; i < functions.length; i++) {
            new_cache.functions.set(functions[i].name, CachedFunction.fromExpression(functions[i].name, functions[i].params, functions[i].expression, new_cache.functions, all_dim_values));
        }
        new_cache.vertices = [];
        for(let i = 0; i < vertices.length; i++) {
            new_cache.vertices.push([CachedValue.fromExpression(vertices[i][0], new_cache.functions, all_dim_values),
                                    CachedValue.fromExpression(vertices[i][1], new_cache.functions, all_dim_values),
                                    CachedValue.fromExpression(vertices[i][2], new_cache.functions, all_dim_values)]);
        }
        new_cache.holes = [];
        new_cache.holes_index = new Map();
        for(let i = 0; i < holes.length; i++) {
            new_cache.holes.push({plane_index: CachedValue.fromExpression(holes[i].plane_index, new_cache.functions, all_dim_values),
                                 visible: CachedValue.fromExpression(holes[i].visible, new_cache.functions, all_dim_values),
                                  vertices: holes[i].vertices});
            if(new_cache.holes[i].plane_index.value !== undefined && (new_cache.holes[i].visible.value !== undefined || (new_cache.holes[i].vertices !== undefined && new_cache.holes[i].vertices.length > 2))) {
                new_cache.holes_index.set(new_cache.holes[i].plane_index.value, i);
            }
        }
        return new_cache;
    }

    static fromCacheFile(vertices, holes, functions, all_dim_values) {
        let new_cache = new Cache();
        new_cache.unserialize(vertices, holes, functions, all_dim_values);
        return new_cache;
    }

    update(changed_dim_names, all_dim_values) {
        for(let func of this.functions.values()) {
            func.update(changed_dim_names, all_dim_values);
        }
        for(let i = 0; i < this.vertices.length; i++) {
            for(let j = 0; j < 3; j++) {
                this.vertices[i][j].update(changed_dim_names, all_dim_values);
            }
        }
        for(let i = 0; i < this.holes.length; i++) {
            let old_plane_index = this.holes[i].plane_index.value;
            this.holes[i].plane_index.update(changed_dim_names, all_dim_values);
            this.holes[i].visible.update(changed_dim_names, all_dim_values);
            let new_plane_index = this.holes[i].plane_index.value;
            if(old_plane_index !== undefined) {
                this.holes_index.delete(old_plane_index);
            }
            if(new_plane_index !== undefined) {
                this.holes_index.set(new_plane_index, i);
            }
        }
    }

    getVertex(index) {
        return [this.vertices[index][0].value, this.vertices[index][1].value, this.vertices[index][2].value];
    }

    getHolePlaneIndex(index) {
        return this.holes[index].plane_index.value;
    }

    getHoleVisibility(index) {
        return this.holes[index].visible.value;
    }

    getHoleVertices(index) {
        return this.holes[index].vertices;
    }

    serialize() {
        // const result = {
        //     "vertices": "",
        //     "holes": "",
        //     "functions": ""
        // }

        // console.log(this.vertices);

        let r_json = {
            "vertices": "",
            "holes": "",
            "functions": ""
        };

        let result = "";//"export const vertices = `";
        for(let i = 0; i < this.vertices.length; i++) {
            for(let j = 0; j < 3; j++) {
                let x = this.vertices[i][j];
                for(const dim of x.dimensions) {
                    result += dim;
                }
                result += '#' + x.expression + '#' + x.value.toString() + '\n';
            }
        }

        r_json.vertices = result;
       
        //result += "`;\n\nexport const holes = `";

        result = "";
        for(let i = 0; i < this.holes.length; i++) {
            let x = this.holes[i].plane_index;
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#' + x.expression + '#' + x.value.toString() + '\n';

            x = this.holes[i].visible;
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#' + x.expression + '#' + x.value.toString() + '\n';
            x = this.holes[i].vertices;
            if(x !== undefined) {
                for(let j = 0; j < x.length - 1; j++) {
                    result += (x[j].toString() + ',');
                }
                if(x.length > 0) {
                    result += x[x.length - 1].toString();
                }
            }
            result += '\n';
        }

        r_json.holes = result;
        


        // result += "`;\n\nexport const functions = `";
        
        result = "";
        for(let x of this.functions.values()) {
            for(const dim of x.dimensions) {
                result += dim;
            }
            result += '#';
            for(let i = 0; i < x.params.length - 1; i++) {
                result += x.params[i] + ',';
            }
            if(x.params.length > 0) {
                result += x.params[x.params.length - 1];
            }
            result += '#' + x.expression + '#' + x.name + '\n';
        }
        
        r_json.functions = result;
        
        
        //result += '`;';

        return r_json;
    }

    unserialize(vertices, holes, functions, all_dim_values) {
        this.functions = new Map();
        let pos = 0;
        while(pos < functions.length) {
            let func = CachedFunction.createEmpty();
            [pos, func.dimensions] = this.unserializeDimensions(functions, pos);
            [pos, func.params] = this.unserializeParams(functions, pos);
            [pos, func.expression] = this.unserializeExpression(functions, pos);
            [pos, func.name] = this.unserializeName(functions, pos);
            this.functions.set(func.name, func);
            CachedFunction.registerFunction(func.name, func.params, func.assignValuesToDims(func.expression, all_dim_values));
        }

        this.vertices = [];
        pos = 0;
        while(pos < vertices.length) {
            let vals = [];
            for(let i = 0; i < 3; i++) {
                vals[i] = CachedValue.createEmpty();
                [pos, vals[i].dimensions] = this.unserializeDimensions(vertices, pos);
                [pos, vals[i].expression] = this.unserializeExpression(vertices, pos);
                [pos, vals[i].value] = this.unserializeValue(vertices, pos);
            }
            this.vertices.push(vals);
        }

        this.holes = [];
        this.holes_index = new Map();

        pos = 0;
        while(pos < holes.length) {
            let plane_index = CachedValue.createEmpty();
            [pos, plane_index.dimensions] = this.unserializeDimensions(holes, pos);
            [pos, plane_index.expression] = this.unserializeExpression(holes, pos);
            [pos, plane_index.value] = this.unserializeValue(holes, pos);
            let visible = CachedValue.createEmpty();
            [pos, visible.dimensions] = this.unserializeDimensions(holes, pos);
            [pos, visible.expression] = this.unserializeExpression(holes, pos);
            [pos, visible.value] = this.unserializeValue(holes, pos);
            let hole_vertices = [];
            while(pos < holes.length && holes[pos] != '\n') {
                let digits = "";
                while(pos < holes.length && holes[pos] != '\n' && holes[pos] != ',') {
                    digits += holes[pos];
                    pos++;
                }
                hole_vertices.push(parseFloat(digits));
                if(pos < holes.length && holes[pos] != '\n') {
                    pos++;
                }
            }
            this.holes.push({plane_index: plane_index, visible: visible, vertices: hole_vertices});
            if(plane_index.value !== undefined && (visible.value !== undefined || (hole_vertices.length > 2))) {
                this.holes_index.set(plane_index.value, this.holes.length - 1);
            }
            pos++
        }
    }

    unserializeName(data, pos) {
        let end = data.indexOf('\n', pos) + 1;
        return [end, data.substr(pos, end - pos - 1)];
    }

    unserializeParams(data, pos) {
        let params = [];
        while(data[pos] != '#') {
            let param = "";
            while(data[pos] != '#' && data[pos] != ',') {
                param += data[pos];
                pos++;
            }
            params.push(param);
            if(data[pos] == ',') {
                pos++;
            }
        }
        pos++;
        return [pos, params];
    }

    unserializeDimensions(data, pos) {
        let dimensions = new Set();
        while(data[pos] != '#') {
            let dimension = data[pos];
            if(pos + 1 < data.length) {
                let digit_candidate = data[pos + 1];
                if(digit_candidate >= '0' && digit_candidate <= '9') {
                    dimension += digit_candidate;
                    pos++;
                }
            }
            dimensions.add(dimension);
            pos++;
        }
        pos++;
        return [pos, dimensions];
    }

    unserializeExpression(data, pos) {
        let end = data.indexOf('#', pos) + 1;
        return [end, data.substr(pos, end - pos - 1)];
    }

    unserializeValue(data, pos) {
        let end = data.indexOf('\n', pos) + 1;
        if(end == 0) {
            return [data.length, parseFloat(data.substr(pos))];
        } else {
            return [end, parseFloat(data.substr(pos, end - pos - 1))];
        }
    }

    print() {
        console.log(JSON.stringify(this.vertices));
        console.log(this.serialize());
    }

    planeIsVisible(plane_index) {
        let hole_id = this.holes_index.get(plane_index);
        return (hole_id === undefined || this.holes[hole_id].visible.value);
    }

    getHolesForPlane(plane_index) {
        let hole_id = this.holes_index.get(plane_index);
        if(hole_id === undefined) {
            return [];
        } else {
            let hole_vertices = this.holes[hole_id].vertices;
            if(hole_vertices === undefined || hole_vertices.length <= 2) {
                return [];
            } else {
                return hole_vertices;
            }
        }
    }
}
