import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { Message } from '../../models/message';
import { MessageType } from '../../models/message-type';
import { ModalComponent } from '../modal/modal.component';


@Component({
    selector: 'app-toast',
    template: `
        <div class="toast top-0 lg:top-2 w-full lg:w-auto m-auto lg:mt-4 z-50 fixed flex flex-col justify-between items-top text-white p-6 rounded {{bgColor}}" style="left: 50%;
        transform: translateX(-50%); z-index: 100; min-width: 400px;">
            <div class="text-xl mr-8">{{message.message}}</div>
            <div class="mt-4" *ngIf="message.errors">
                <ul class="list-disc list-inside">
                    <li class="" *ngFor="let error of getErrorsList(); index as i;">{{error.error}}</li>
                </ul>
            </div>
            <div class="absolute top-4 right-4 flex justify-end items-start"><button (click)="onClose()" class="text-white rounded-full"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg></button>
            </div>
        </div>
    `
})
export class ToastModalComponent extends ModalComponent implements OnInit {
    message: Message;
    
    ngOnInit(): void {
        this.message = this.data.message;
        this.getErrorsList();
    }

    getErrorsList() {
        const errors: { key: string, error: string }[] = [];

        if(this.message.errors) {
            this.message.errors;

            for (const [key, value] of Object.entries(this.message.errors)) {
                errors.push({
                    key: key,
                    error: value.reduce(function(previousValue, currentValue, index, array) {
                        return previousValue + ", " + currentValue;
                    })
                });
            }
        }

        return errors;
    }

    get bgColor(): string {
        let bgClass: string = "";

        switch(this.message.type) {
            case MessageType.error:
                bgClass = "bg-red-600";
                break;
            case MessageType.info:
                bgClass = "bg-yellow-600";
                break;
            case MessageType.success:
                bgClass = "bg-green-600";
                break;
            default:
                bgClass = "bg-yellow-600";
        }

        return bgClass;
    }
}