<div [ngClass]="getClasses()" class="flex flex-wrap mb-4 items-center">
    <div class="param-left-col text-right p-2 justify-end">
        <label>
            Komentarz:
        </label>
    </div>
    <div class="param-right-col">
        <textarea 
            (focus)="onFocus()" 
            class="border border-gray-400 rounded p-2" 
            [formControl]="commentFC"
            [ngClass]="{'border-red-600': showError()}"
            style="width: 16rem; min-height: 8rem;"
        ></textarea>
    </div>
    <div class="param-left-col"></div>
    <div *ngIf="showError()" class="param-right-col mt-1 text-sm text-red-600">
        {{getError()}}
    </div>
</div>