<button 
    (click)="onClick()" 
    class="flex p-3 rounded-lg w-full justify-center focus:outline-none focus:ring focus:border-blue-300" 
    [ngClass]="getClasses()"
    style="min-width: 150px;"
    type="button"
>
    <div class="flex items-center font-medium text-white">
        <ng-content></ng-content>
    </div>
</button>
